import { Component } from "react";
import { NavLink } from "react-router-dom";
import styles from './forms.module.scss';


class ErrorWnd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
    };
  }

  render() {
    const { errorTitle, errorTitle2, resetState, hasLink } = this.props;

    return (
      <>
        <div className="main-form__success active">
          <div className="main-form__title-block">
            <div className="title title_md">{errorTitle}</div>
          </div>
          <div className="main-form__content">
            <div className={`d-flex justify-content-center`}>
              <div className={styles.testBlockWidth700}>
                <p className='title title_md-md'>{errorTitle2}</p>
              </div>
            </div>
            {hasLink && <p className='fs-24-32-b gray t-center'><a href={process.env.PERSONAL_CABINET}>{process.env.PERSONAL_CABINET}</a></p>}
            {resetState === "home" ? (
              <div className='mt-40'>
                <NavLink to="/" className="btn btn_dark-blue">
                  <p>ДОБРЕ</p>
                </NavLink>
              </div>
            ) : (
              <div className='mt-40'>
                <a
                  href="#"
                  className="btn btn_dark-blue"
                  onClick={(e) => {
                    e.preventDefault();
                    resetState();
                  }}
                >
                  <span>ДОБРЕ</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default ErrorWnd;