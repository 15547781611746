
const FaqItem = ({item, onClickHandler, currentItem }) => {
  return (
    <div onClick={() => onClickHandler(item)} className={`faq__item ${currentItem === item ? "selected" : ""}`}>
      <div className="faq__question">{item.question}</div>
      <div className="faq__answer">
        <div className="faq__answer-content" dangerouslySetInnerHTML={{__html:item.answer}} />
      </div>
    </div>
  );
};

export default FaqItem;
