const ST_CLOSED_NOW = 'closed_now';
const ST_USER_DATA = 'user_data';
const ST_ERROR = 'error';
const ST_OFFER_COOPARATION = 'offer_cooperation';
const ST_REJECTION_REASON = 'rejection_reason';
const ST_IDENTIFICATION_REQUIRED = 'identification_required';
const ST_LOAD_PASSPORT_COPY = 'load_passport_copy';
const ST_LOAD_LAND_DOCUMENTS = 'load_land_documents';
const ST_REQUEST_ADD_SUCCESS = 'request_add_success';
const ST_REQUEST_ADD_SUCCESS_RR = 'request_add_success_rr';

export const WND = {
  ST_CLOSED_NOW,
  ST_USER_DATA,
  ST_ERROR,
  ST_OFFER_COOPARATION,
  ST_REJECTION_REASON,
  ST_IDENTIFICATION_REQUIRED,
  ST_LOAD_PASSPORT_COPY,
  ST_LOAD_LAND_DOCUMENTS,
  ST_REQUEST_ADD_SUCCESS,
  ST_REQUEST_ADD_SUCCESS_RR,
};