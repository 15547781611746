
const privacyContent = `<div className="privacy-policy__content">
  <p>
    Політика конфіденційності визначає нашу роботу з вашими даними,
    коли ви реєструєтесь чи відвідуєте наш сайт або пов’язані сайти чи
    сторінки.
  </p>
  <p>
    Політика також включає інформацію щодо осіб, чиї персональні дані
    ми можемо обробляти в процесі відбору кандидатів на вакансії в
    компанії.
  </p>
  <h3>
    <strong>Застосування Політики конфіденційності</strong>
  </h3>
  <p>
    Ця Політика конфіденційності визначає нашу роботу з персональними
    даними, які ми збираємо або отримуємо від третіх осіб, та цілі,
    для яких ми обробляємо ваші персональні дані. Також Політика
    конфіденційності визначає ваші права в процесі обробки нами
    персональних даних.
  </p>
  <p>
    Політика конфіденційності ознайомить вас з персональними даними,
    що збираються нами і можливостями для вас подати нам запит для
    видалення, оновлення, передання та/або надання доступу до цих
    даних.
  </p>
  <p>
    Зверніть увагу, що Політика конфіденційності застосовується лише
    до персональних даних, що збираються на цьому веб-сайті.
  </p>
  <h3>
    <strong>Наші контакти</strong>
  </h3>
  <p>Якщо у Вас виникли питання стосовно Політики конфіденційності</p>
  <p>
    Пошта:
    <a href="mailto:info@land-invest.com.ua">
      info@land-invest.com.ua
    </a>
    <br />
    Телефон: <a href="tel:0800407711">0 800 407711</a>
  </p>
  <hr />
  <h3>
    <strong>Визначення</strong>
  </h3>
  <p>
    Персональні дані – відомості чи сукупність відомостей про фізичну
    особу, яка ідентифікована або може бути конкретно ідентифікована.
  </p>
  <p>
    Файли cookie – невеликі файли даних, які передаються в браузер з
    сайту, який ви відвідали. Файли cookie дозволяють порівнювати і
    розуміти, як користувачі здійснюють навігацію по веб-сайту, і
    завдяки цьому можуть покращувати процес навігації; допомагають
    сайту запам’ятовувати інформацію про вас, наприклад, якою мовою ви
    переглядаєте сайт, щоб при наступному відвідуванні відкрити сайт
    мовою, якою ви обрали першочергово; дозволяють зберігати
    налаштування рекламних переваг та безпечного пошуку; обирати для
    користувачів оголошення, що їм цікаві, та прораховувати кількість
    відвідувань сторінки. Також вони необхідні при реєстрації в
    сервісах Google та забезпечення безпеки особистих даних.
  </p>
  <h3>
    <strong>Мета збору</strong>
  </h3>
  <p>Метою збору персональних даних та файлів cookie є:</p>
  <ul>
    <li>Можливість полегшити навігацію по сайту.</li>
    <li>Покращення роботи сайту.</li>
    <li>
      Забезпечення змоги сайту запам’ятовувати варіанти, які ви
      обираєте (наприклад, ваше ім’я як користувача, мову чи регіон, в
      якому ви перебуваєте), і забезпечити розгорнуті, більш особисті
      чи індивідуальні послуги.
    </li>
    <li>
      Доставка реклами, яка стосується вас і ваших інтересів, що
      визначається відповідно до аналізу ваших переглядів
      веб-сторінок.
    </li>
  </ul>
  <p>
    Переглянути більш детально, які саме файли cookie ми
    використовуємо, їх опис та термін зберігання, ви можете за
    <a
      href="https://www.kernel.ua/wp-content/uploads/2019/07/kernel-ua-ua.pdf"
      target="_blank"
      rel="noreferrer"
    >
      посиланням
    </a>
    .
  </p>
  <h3>
    <strong>Захист персональних даних</strong>
  </h3>
  <p>
    Ми вживаємо всі необхідні заходи для збереження безпеки
    персональних даних, що надаються нам, і ми запровадили відповідні
    політики, правила та технічні заходи з інформаційної безпеки для
    захисту персональних даних від несанкціонованого доступу,
    неправомірного використання чи розкриття, несанкціонованої зміни
    чи незаконного знищення або випадкової втрати.
  </p>
  <hr />
  <h3>
    <strong>Отримання доступу до </strong>
    <strong>вашої інформації</strong>
  </h3>
  <p>
    За запитом через форму зворотного зв’язку, розміщену на сторінці
    «Контакти» ви можете:
  </p>
  <ul>
    <li>Отримати доступ до ваших персональних даних.</li>
    <li>Оновити або видалити ваші персональні дані.</li>
    <li>
      Анулювати згоду на використання ваших персональних даних та
      файлів cookie.
    </li>
  </ul>
  <p>
    Ви також можете відмовитись або обмежити використання файлів
    cookie за допомогою налаштувань в браузері. Але в такому разі
    деякі функціональні можливості сайту можуть стати недоступними для
    використання.
  </p>
  <p>
    За посиланнями нижче ви можете переглянути інструкції щодо
    блокування використання файлів cookie у вашому браузері та/або
    видалення файлів cookie:
  </p>
  <ul>
    <li>
      Chrome:
      <a
        href="https://support.google.com/chrome/answer/95647"
        target="_blank"
        rel="noreferrer"
      >
        https://support.google.com/chrome/answer/95647
      </a>
    </li>
    <li>
      Internet Explorer:
      <a
        href="http://windows.microsoft.com/en-US/windows-vista/Block-or-allow-cookies"
        target="_blank"
        rel="noreferrer"
      >
        http://windows.microsoft.com/en-US/windows-vista/Block-or-allow-cookies
      </a>
    </li>
    <li>
      Mozilla Firefox:
      <a
        href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
        target="_blank"
        rel="noreferrer"
      >
        https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
      </a>
    </li>
    <li>
      Safari:
      <a
        href="https://www.apple.com/legal/privacy/en-ww/cookies/"
        target="_blank"
        rel="noreferrer"
      >
        https://www.apple.com/legal/privacy/en-ww/cookies/
      </a>
    </li>
  </ul>
  <h3>
    <strong>Категорії третіх осіб, яким ми можемо передавати </strong>
    <strong>ваші дані</strong>
  </h3>
  <p>
    Ми можемо ділитися вашими даними з наступними категоріями осіб:
  </p>
  <ul>
    <li>Співробітники компанії Кернел.</li>
    <li>Зовнішні сервіси – розсилки, Google-аналітика.</li>
    <li>
      Треті особи, яким ми надаємо на аутсорсинг виконання певних
      робіт.
    </li>
  </ul>
  <p>
    При запиті та наявності відповідних документів ми зобов’язані
    надати доступ до ваших даних:
  </p>
  <ul>
    <li>Органам державної влади.</li>
    <li>Регуляторам/податковим органам/державним реєстраторам.</li>
  </ul>
  <p>
    Ми проводимо перевірку та укладаємо договори з підрядниками,
    упевнившись, що вони здійснюють обробку персональних даних
    належним чином та у відповідності до наших правових зобов’язань.
  </p>
  <p>
    <strong>
      Інформування користувачів про зміни в частині збору, обробки та
      збереження файлів cookie та персональних даних
    </strong>
  </p>
  <p>
    Ми можемо змінювати Політику конфіденційності в залежності від
    оновлення способу збору, обробки та збереження файлів cookie та
    персональних даних або з метою відповідності до вимог чинного
    законодавства чи регуляторних органів.
  </p>
  <p>
    Для забезпечення вашої обізнаності в разі зміни Політики
    конфіденційності на сайті з’являтиметься інформаційне вікно з
    посиланням на оновлену Політику.
  </p>
  </div>`;

export default privacyContent;