
export const oblastList = [
  { value: "Оберіть область", id: "hidden", sellsCount: 0, coordinate: [0, 0] },
  { value: "Вінницька", id: "ВІННИЦЬКА ОБЛАСТЬ", coordinate: [49.1, 28.3] },
  { value: "Волинська", id: "ВОЛИНСЬКА ОБЛАСТЬ", coordinate: [51.15, 25] },
  { value: "Дніпропетровська", id: "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ", coordinate: [48.4, 34.5] },
  { value: "Донецька", id: "ДОНЕЦЬКА ОБЛАСТЬ", coordinate: [47.95, 37.4] },
  { value: "Житомирська", id: "ЖИТОМИРСЬКА ОБЛАСТЬ", coordinate: [50.65, 28.4] },
  { value: "Закарпатська", id: "ЗАКАРПАТСЬКА ОБЛАСТЬ", coordinate: [48.4, 22.4] },
  { value: "Запорізька", id: "ЗАПОРІЗЬКА ОБЛАСТЬ", coordinate: [47.6, 35.4] },
  { value: "Івано-Франківська", id: "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ", coordinate: [48.8, 24.4] },
  { value: "Київська", id: "КИЇВСЬКА ОБЛАСТЬ", coordinate: [50.2700, 30.3125] },
  { value: "Кіровоградська", id: "КІРОВОГРАДСЬКА ОБЛАСТЬ", coordinate: [48.4, 32.1] },
  { value: "Луганська", id: "ЛУГАНСЬКА ОБЛАСТЬ", coordinate: [48.45, 39.0] },
  { value: "Львівська", id: "ЛЬВІВСЬКА ОБЛАСТЬ", coordinate: [49.8, 23.5] },
  { value: "Миколаївська", id: "МИКОЛАЇВСЬКА ОБЛАСТЬ", coordinate: [47.4, 31.8] },
  { value: "Одеська", id: "ОДЕСЬКА ОБЛАСТЬ", coordinate: [47.0, 30.5] },
  { value: "Полтавська", id: "ПОЛТАВСЬКА ОБЛАСТЬ", coordinate: [49.5, 34.2] },
  { value: "Рівненська", id: "РІВНЕНСЬКА ОБЛАСТЬ", coordinate: [51, 26.3] },
  { value: "Сумська", id: "СУМСЬКА ОБЛАСТЬ", coordinate: [50.9, 34.2] },
  { value: "Тернопільська", id: "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ", coordinate: [49.45, 25.4] },
  { value: "Харківська", id: "ХАРКІВСЬКА ОБЛАСТЬ", coordinate: [49.9, 36.2] },
  { value: "Херсонська", id: "ХЕРСОНСЬКА ОБЛАСТЬ", coordinate: [46.45, 32.6] },
  { value: "Хмельницька", id: "ХМЕЛЬНИЦЬКА ОБЛАСТЬ", coordinate: [49.3, 26.8] },
  { value: "Черкаська", id: "ЧЕРКАСЬКА ОБЛАСТЬ", coordinate: [49.5, 31.3] },
  { value: "Чернівецька", id: "ЧЕРНІВЕЦЬКА ОБЛАСТЬ", coordinate: [48.2, 25.6] },
  { value: "Чернігівська", id: "ЧЕРНІГІВСЬКА ОБЛАСТЬ", coordinate: [51.4, 31.5] },
];


export const regionList = [
  { value: "Регіон", id: "hidden" },
  { value: "Вінницький", id: 1 },
  { value: "Гайсинський", id: 2 },
  { value: "Жмеринський", id: 3 },
  { value: "Могилів-Подільський", id: 4 },
  { value: "Тульчинський", id: 5 },
  { value: "Хмільницький", id: 6 },
];

export const purposeList = [
  { value: "Призначення", id: "hidden" },
  { value: "Для ведення особистого селянського господарства", id: 1 },
  { value: `Для ведення товарного сільськогосподарського\u00A0виробництва`, id: 2 },
  { value: "Для ведення товарного сільськогосподарського виробництва", id: 3 },
  { value: "Для ведення товарного сільськогосподарського виробництва", id: 4 },
  { value: "Для ведення особистого селянського господарства", id: 5 },
];
