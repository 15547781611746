import axios from "axios";


function getRequest({restUrl}) {
  return axios
  .get(
    `${process.env.K2_API_URL}${restUrl}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic dm9yZGVsOnZvcmRlbA==",
      },
    }
  );
}

function postRequest({bodyFormData, restUrl}) {
  return axios
  .post(
    `${process.env.K2_API_URL}${restUrl}`,
    bodyFormData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic dm9yZGVsOnZvcmRlbA==",
      },
    }
  );
}

export {
  getRequest,
  postRequest,
};