import { CheckboxCheckedIcon, CheckboxEmptyIcon } from "@this-assets/icons/ui";


const CheckboxBtn = ({onClickHandler, isSelect=false, title, styles, blankLink=false}) => {

  return (
    <div className='d-flex align-items-center' onClick={onClickHandler}>
      { isSelect ? <CheckboxCheckedIcon width={24} height={24} /> : <CheckboxEmptyIcon width={24} height={24} />}
      { blankLink ? <a href={blankLink} target="_blank" rel="noreferrer">
          <span className={styles}>{title}</span>
        </a> : <span className={styles}>{title}</span> }
    </div>
  );
};

export default CheckboxBtn;