import { Component } from "react";
import { BackArrowIcon } from '@this-assets/icons/ui';
import { priceFormater } from "@this-core/helpers/formaters";

class OfferCooparationWnd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
    };
  }

  render() {
    const {
      totalPrice,
      totalPrice2,
      area,
      nextStep,
      nextStep2,
      resetState,
      cadNumber,
    } = this.props;

    return (
      <>
        <div className="main-form__stage-2 active">
          <div className="main-form__title-block">
            <div onClick={(e) => {
                e.preventDefault();
                resetState();
              }}>
              <BackArrowIcon width={20} height={36} />
            </div>
            <div>
              <p className="fs-32-40-b t-center montserrat">Запит на оцінку земельної ділянки</p>
              <p className='mt-10 fs-24-28 dark-gray t-center montserrat'>{cadNumber}</p>
            </div>
          </div>

          <div className="main-form__content">
            <div className="title title_md-md">
              Ми готові купити Вашу землю та пропонуємо Вам:
            </div>
            <div className="main-form__total-price">
              <div className="title title_md-md">
                <strong>{priceFormater({price: totalPrice, degree: 2, symbol: 'грн'})}</strong>
                {/*<strong>{priceFormater({price: totalPrice2, degree: 2, symbol: 'грн'})}</strong>*/}
                {` ${area ? "за ділянку (на руки)" : "за 1 Га (на руки)"}`}    
                <div className="title_ms">Сума договору з врахуванням податків та витрат на переоформлення буде розрахована на момент складання договору</div>          
              </div>
            </div>
            {/*<div className="main-form__total-price main-form__total-price_2">
              <div className="title title_md-md">
                Сума після виплат податків та витрат за переоформлення:
              </div>
              <div className="title title_md-md">
                <strong>{priceFormater({price: totalPrice, degree: 2, symbol: 'грн'})}</strong>
                {` ${area ? "за ділянку" : "за 1 Га"}`}
              </div>
            </div>*/}
          </div>

          <div className="btn-block btn-block_center">
            <div className="row">
              <div className="col col_6">
                <button
                  className="btn btn_white"
                  onClick={(e) => {
                    e.preventDefault();
                    nextStep();
                  }}
                >
                  <span>НЕ ЗАРАЗ</span>
                </button>
              </div>
              <div className="col col_6">
                <button
                  className="btn btn_dark-blue"
                  onClick={(e) => {
                    e.preventDefault();
                    nextStep2();
                  }}
                >
                  <span>ХОЧУ ПРОДАТИ</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OfferCooparationWnd;