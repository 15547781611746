import { RightArrowIcon } from  '@this-assets/icons/ui';
import styles from './how-it-works-item.module.scss';


const HowItWorksItem = ({icon, title, steps, index, isMobile}) => {
  const isLastItem = (steps.length === index + 1);

  return <>
    <div className={styles.item}>
      <div className={`${styles.iconStyle}`}>
        {icon}
        <div className={`d-flex justify-content-center align-items-center ${styles.numbering}`}>
          <p className='fs-21-24 fw-600 monserat'>{index + 1}</p>
        </div>
      </div>
      <p className={styles.text}>{title}</p>
    </div>
    {!isLastItem && !isMobile && <div className='mt-75 d-flex justify-content-center'>
      <RightArrowIcon width={84} height={20} />
    </div>}
  </>;
};

export default HowItWorksItem;