import UserDataSellWnd from "./user-data-sell-wnd";
import OfferCooparationWnd from "./offer-cooparation-wnd";
import IdentificationRequiredWnd from "./identification-required-wnd";
import LoadPassportCopyWnd from "./load-passport-copy-wnd";
import LoadLandDocumentsWnd from "./load-land-documents-wnd";
import SuccessWnd from "./success-wnd";
import FormStepsWnd from "./form-steps-wnd";
import FormSuccessRRWnd from "./success-rr-wnd";
import ErrorWnd from "./error-wnd";
import RejectionReasonWnd from './rejection-reason-wnd';
import SubmitPreloader from "../../preloaders/submit-preloader";
import {WND} from '@this-core/constants/wnd-const';
import withFormSale from "@this-core/HOC/hoc-form-sale";


const MainFormSale = (props) => {

  const {popupStage, mid, cadNumber, cadNumberVisit, cadNumberError, name, nameVisit, nameError, phone, 
    phoneVisit, phoneError, koatuuVisit, koatuuError, address, submitPreloaderActive, errorTitle, errorTitle2,
    hasLink, area, stepsActive, stepsStep, totalPrice, totalPrice2, formData, formDataName, formData2,
    formDataName2, docType, reason, reason2, onSetState, onSubmitForm, onSubmitForm4, onSubmitFormRejectionReason,
    onHandleChange, onBlurHandler, onUpdateData, onResetState } = props;

  return (
    <>
      <div className={`main-form`}>
        {popupStage === WND.ST_USER_DATA && (
          <form className="form" onSubmit={onSubmitForm(phone, name, cadNumber, address)}> 
            <UserDataSellWnd
              cadNumber={cadNumber}
              cadNumberVisit={cadNumberVisit}
              cadNumberError={cadNumberError}
              name={name}
              nameVisit={nameVisit}
              nameError={nameError}
              phone={phone}
              phoneVisit={phoneVisit}
              phoneError={phoneError}
              koatuuVisit={koatuuVisit}
              koatuuError={koatuuError}
              handleChangeCadNumber={onHandleChange(
                "cadNumberError",
                "Обов’язково до заповнення"
              )}
              handleChangeName={onHandleChange(
                "nameError",
                "Обов’язково до заповнення"
              )}
              handleChangePhone={onHandleChange(
                "phoneError",
                "Обов’язково до заповнення"
              )}
              blurHandler={onBlurHandler}
              updateData={onUpdateData}
            /> 
          </form>
        )}
        
        {popupStage === WND.ST_OFFER_COOPARATION && (
          <form className="form">
            <OfferCooparationWnd
              totalPrice={totalPrice}
              totalPrice2={totalPrice2}
              area={area}
              cadNumber={cadNumber}
              nextStep={() => onSetState({
                    popupStage: WND.ST_REJECTION_REASON,
                    stepsActive: false,
                  })
              }
              nextStep2={() => onSetState({
                    popupStage: WND.ST_IDENTIFICATION_REQUIRED,
                    stepsStep: "",
                  })
              }
              resetState={onResetState}
            />
          </form>) 
        }
        {popupStage === WND.ST_IDENTIFICATION_REQUIRED && (
          <form className="form">
            <IdentificationRequiredWnd
              mid={mid}
              totalPrice={totalPrice}
              prevStep={() => onSetState({
                    popupStage: WND.ST_OFFER_COOPARATION,
                    stepsActive: true,
                    stepsStep: "step-3",
                  })
              }
              nextStep={() => onSetState({
                    popupStage: WND.ST_LOAD_PASSPORT_COPY,
                    stepsActive: false,
                  })
              }
            />
          </form>) 
        }
        <form
          className="form"
          onSubmit={onSubmitForm4(
            formData,
            formDataName,
            formData2,
            formDataName2,
            docType,
            totalPrice,
            mid
          )}
        >
          {popupStage === WND.ST_LOAD_PASSPORT_COPY && <LoadPassportCopyWnd
            updateData={onUpdateData}
            mid={mid}
            totalPrice={totalPrice}
            cadNumber={cadNumber}
            prevStep={() => onSetState({
                  popupStage: WND.ST_IDENTIFICATION_REQUIRED,
                  stepsActive: true,
                })
            }
            nextStep={() => onSetState({
                  popupStage: WND.ST_LOAD_LAND_DOCUMENTS,
                })
            }
          />}
          
          {popupStage === WND.ST_LOAD_LAND_DOCUMENTS && <LoadLandDocumentsWnd
            docType={docType}
            updateData={onUpdateData}
            prevStep={() => onSetState({
                popupStage: WND.ST_LOAD_PASSPORT_COPY,
              })
            }
          />}
        </form>

        {popupStage === WND.ST_REQUEST_ADD_SUCCESS && <SuccessWnd
          mid={mid}
        />}
        
        {popupStage === WND.ST_REQUEST_ADD_SUCCESS_RR && <FormSuccessRRWnd
          mid={mid}
        />}
        
        {popupStage === WND.ST_ERROR && <ErrorWnd
          errorTitle={errorTitle}
          errorTitle2={errorTitle2}
          resetState={onResetState}
          hasLink={hasLink}
        /> }
        
        {popupStage === WND.ST_REJECTION_REASON && (
          <form
            className="form"
            onSubmit={onSubmitFormRejectionReason(
              mid,
              totalPrice,
              reason,
              reason2
            )}
          >
            <RejectionReasonWnd
              updateData={onUpdateData}
              prevStep={() => onSetState({
                  popupStage: WND.ST_OFFER_COOPARATION,
                  stepsActive: true,
                  stepsStep: "step-3",
                })
              }
            />
          </form> )
        }
        <FormStepsWnd
          stepsActive={stepsActive}
          stepsStep={stepsStep}
        />
      </div>
      <SubmitPreloader
        submitPreloaderActive={submitPreloaderActive}
      />
    </>
  );
};

export default withFormSale(MainFormSale);