import { SearchLogoIcon  } from "@this-assets/icons/ui";
import styles from './map-search-block.module.scss';
import withMapSearchBlock from '@this-core/HOC/hoc-map-search-block';


const MapSearchBlock = ({inputValue, suggestions, setInputValue, onGetCoordinate}) => {

  return (
    <div className={`d-flex align-items-center ${styles.inputContainer}`}>
      <div className={`ml-15 mr-8 ${styles.serchIcon}`}>
        <SearchLogoIcon width={16} height={16} />
      </div>
      <input
        type="text"
        placeholder="Пошук по назві"
        name="region"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)} />
      {
        !!suggestions.length && <div className={styles.dropDownList}>
          {
            suggestions.map((item, i) => (
              <div key={item.nameOfTheObject + i} onClick={() => onGetCoordinate(item)}>
                <p className='py-5 fs-16-20'>{item.nameOfTheObject}</p>
              </div>
            ))
          }
        </div>
      }
    </div>
  );
};

export default withMapSearchBlock(MapSearchBlock);