import { Link } from "react-scroll";
import { PlantIcon } from "@this-assets/icons/ui";
import { Suspense, lazy } from 'react';
import { BlueCyrclePreloader } from '@this-assets/preloaders';

const CashIcon = lazy(() => import('@this-assets/icons/ui-lazy/cash.svg'));

const AboutUsBlock = ({ btnShow }) => {
  return (
    <>
      <div className="about-us" id="about-us">
        <div className="title">ПРО НАС</div>
        <div className="about-us__content">
          <p>
            <strong>LandInvest</strong> – онлайн-платформа для проведення
            земельних торгів. Продаж земельної ділянки сьогодні – це ваша
            можливість інвестувати у власне майбутнє. Ми прагнемо вивести
            відносини між власниками ділянок та потенційними інвесторами на
            якісно новий рівень і розвинути прозорий ринок землі в Україні.
            Користуючись нашою платформою, власник земельної ділянки може
            отримати кваліфіковану безкоштовну консультацію щодо актуальних
            законодавчих змін, оформити заявку на експертну оцінку земельної
            ділянки та обрати найоптимальніший варіант для продажу та купівлі.
            Для оформлення запиту достатньо вказати кадастровий номер ділянки,
            персональні дані та контактний номер телефону.
          </p>
          <p>
            <strong>
              LandInvest – гарантія чесного та взаємовигідного партнерства без
              ризиків і втрат!
            </strong>
          </p>
        </div>
        {/*<div className="about-us__items">
          <div className="row">
            <div className="col col_6">
              <div className="about-us__item">
                <div className="about-us__item-icon">
                  <PlantIcon width={104} height={90} />
                </div>
                <div className="about-us__item-content">
                  <div className="title">27,7 млн га</div>
                  <p>Приватні паї</p>
                </div>
              </div>
            </div>
            <div className="col col_6">
              <div className="about-us__item">
                <div className="about-us__item-icon">
                  <Suspense fallback={<BlueCyrclePreloader width={104} height={90} />}>
                    <CashIcon width={104} height={90} />
                  </Suspense>
                </div>
                <div className="about-us__item-content">
                  <div className="title">2 млрд дол</div>
                  <p>Надходження до бюджетів</p>
                </div>
              </div>
            </div>
          </div>
        </div>*/}
        {btnShow && <Link className="btn btn_dark-blue" to="main-top-block"
            smooth={true} duration={500}>
            ОЦІНИТИ МОЮ ДІЛЯНКУ
          </Link>}
      </div>
    </>
  );
};

export default AboutUsBlock;
