import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class RedirectService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
      width: 0,
    };
  }

  checkMobile = () => {
    if (window.innerWidth >= 0 && !!process.env.DESKTOP_VERSION_URL) {
      window.location.href = process.env.DESKTOP_VERSION_URL;
    }
    /*if (window.innerWidth < 480 && !!process.env.MOBILE_VERSION_URL) {
      window.location.href = process.env.MOBILE_VERSION_URL;
    }*/
  };

  componentDidMount() {
    this.checkMobile();
  }

  render() {
    return <></>;
  }

  updateDimensions = () => {
    this.checkMobile();
  };
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.checkMobile();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
}

export default withRouter(RedirectService);
