import React, { useState } from 'react';
import { MapContainer } from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import MapMarkerItem from '../../items/map-marker-item/map-marker-item';
import MapSearchBlock from '../map-search-block/map-search-block';
import MapMarkerGroup from '@this-core/components/items/map-marker-group/map-marker-group';
import 'leaflet/dist/leaflet.css';
import './map.css';

// Geographical center of Ukraine
const center = [48.998955, 30.167278];
const mapZoom = 7;

const MapBlock = ({landInSaleArr, sellsCountByOblast}) => {
  const [map, setMap] = useState(null);

  return <div>
    <MapSearchBlock map={map} />
    <div style={{position: 'relative', top: '-40px'}}>
      <MapContainer
        center={center}
        zoom={mapZoom}
        scrollWheelZoom={false}
        whenCreated={setMap}>
          <ReactLeafletGoogleLayer type='roadmap' apiKey={process.env.GOOGLE_MAP_API_KEY} />
          {
            (landInSaleArr.length != 0) && landInSaleArr.map((poligon) => {
              return <React.Fragment key={poligon.CadastralNumber}>
                <MapMarkerItem poligon={poligon} />
              </React.Fragment>;
            })
          }
          {
            (landInSaleArr.length != 0) && sellsCountByOblast.map((oblast) => {
              return <React.Fragment key={oblast.id}>
                <MapMarkerGroup map={map} sellCount={oblast.sellsCount} coordinate={oblast.coordinate} />
              </React.Fragment>;
            })
          }
      </MapContainer>
    </div>
  </div>;
};

export default MapBlock;