import { priceFormater, areaFormater } from "@this-core/helpers/formaters";
import SimpleBlueBtn from "../../buttons/simple-blue-btn";

const MapPopups = ({cadastrNumber, address, areaGA, offeredCoast, offeredCostEval}) => {

  return <div>
    <p className='pr-30 fs-14-16-b'>{address}</p>
    {/* Пока нет этих данных */}
    {/* <p className='pt-15 pb-5 fs-12-16-b dark-blue'>Прибутковість: 7%</p> */}
    <p className='pt-15 pb-5 fs-12-16'>
      <span className='gray'>Ціна ділянки:</span>
      {` ${priceFormater({price: offeredCostEval, symbol: 'грн'})}*`}
      </p>
    <p className='pb-5 fs-12-16'>
      <span className='gray'>Ціна за 1 га:</span>
      {` ${priceFormater({price: offeredCoast, symbol: 'грн'})}`}
    </p>
    <p className='pb-5 fs-12-16'>
      <span className='gray'>Площа:</span>
      {` ${areaFormater(areaGA)}`}
    </p>
    <p className='pb-5fs-12-16'>
      <span className='gray'>Кадастровий номер:</span>
      {` ${cadastrNumber}`}
    </p>
    <p className='pt-15 pb-5 fs-12-16-b'>* Додаткові платежі:</p>
    <p className='pb-5 fs-12-16'>
      <strong>Нотаріальні послуги: </strong>
      8000-15000 грн
    </p>
    <p className='pb-5 fs-12-16'>
      <strong>Комісія платформи: </strong>
      4000 грн/га
    </p>
    <div className='d-flex justify-content-end'>
      <a href={process.env.PERSONAL_CABINET_REGISTER} rel="noreferrer" target="_blank">
        <SimpleBlueBtn title='КУПИТИ' />
      </a>
    </div>
  </div>;
};

export default MapPopups;
