import ReactDom from "react-dom";

const Popup = ({
  active,
  popupClass,
  popupClassActive,
  setActive,
  children,
}) => {
  // if (!active) return null;
  return ReactDom.createPortal(
    <div className={active ? popupClassActive : popupClass} onClick={setActive}>
      <div className="container">
        <div className="popup__content" onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </div>,
    document.getElementById("portal-root")
  );
};

export default Popup;
