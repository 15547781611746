import { BagWithMoneyIcon, MarketGraphicOnLaptopWhiteIcon, NoteWithPenWhiteIcon } from "@this-assets/icons/ui";
import {COLOR_GRAY, COLOR_WHITE } from "@this-basic-styles/colors-const";


const FormStepsWnd = ({ stepsActive, stepsStep }) => {

  return (
    <div className={stepsActive ? "steps active" : "steps"}>
      <div className="steps__items">
        <div className="row">
          <div className="col">
            <div className="steps__item">
              <div className='z-index-3'>
                <NoteWithPenWhiteIcon width={37} height={41} />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="steps__item">
              <div className="z-index-3">
                <MarketGraphicOnLaptopWhiteIcon width={37} height={41} />
              </div>
            </div>
          </div>
          <div className="col">
            {stepsStep === "step-3" ? <div className="steps__item steps__item_white">
                <div className="z-index-3">
                  <BagWithMoneyIcon width={37} height={41} fill={COLOR_GRAY} />
                </div>
              </div> : <div className="steps__item">
                <div className="z-index-3">
                  <BagWithMoneyIcon width={37} height={41} fill={COLOR_WHITE} />
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormStepsWnd;