
export const priceFormater = ({price = 0, degree = 0, symbol}) => {
  if (price == 0) return '';
  const priceMod = (parseInt(price * 10**degree) / 10**degree).toLocaleString();
  switch(symbol) {
    case 'грн':
      return `${priceMod}\u00A0грн`;
    case 'грн/га':
      return `${priceMod}\u00A0грн/га`;
    case '₴':
      return `₴\u00A0${priceMod}`;
    default:
      return priceMod;
  }
};

export const areaFormater = (area) => `${Math.round(area * 100) / 100}\u00A0га`;

export const dateFormater = (date) => new Date(date).toLocaleDateString();
// const regExp = /\B(?=(\d{3})+(?!\d))/g;
// export const priceInSaleFormater = (price) => Math.floor(price).toString().replace(regExp, `\u00A0`);