import FormStage1 from "./form-stage-first";
import FormSuccess from "./form-success";
import SubmitPreloader from "../../preloaders/submit-preloader";
import withFormBuy from "@this-core/HOC/hoc-form-buy";
import { WND } from "@this-core/constants/wnd-const";

const MainFormBuy = (props) => {
  const {popupStage, submitPreloaderActive, name, nameVisit, nameError, phone,
    phoneVisit, phoneError, area, investments, cost, region, onSubmitForm, onHandleChange, 
    onBlurHandler, onUpdateData, onResetState } = props;

  return (
    <>
      <div className="main-form">
        {popupStage === WND.ST_USER_DATA && (
          <form
            className="form"
            onSubmit={onSubmitForm(
              name,
              phone,
              area,
              investments,
              cost,
              region
            )}
          >
            <FormStage1
              name={name}
              nameVisit={nameVisit}
              nameError={nameError}
              phone={phone}
              phoneVisit={phoneVisit}
              phoneError={phoneError}
              area={area}
              investments={investments}
              cost={cost}
              handleChangeName={onHandleChange(
                "nameError",
                "Обов’язково до заповнення"
              )}
              handleChangePhone={onHandleChange(
                "phoneError",
                "Обов’язково до заповнення"
              )}
              handleChangeArea={onHandleChange()}
              handleChangeInvestments={onHandleChange()}
              handleChangeCost={onHandleChange()}
              blurHandler={onBlurHandler()}
              updateData={onUpdateData}
            />
          </form>
        )}
        
        {popupStage === WND.ST_REQUEST_ADD_SUCCESS && <FormSuccess
            resetState={onResetState}
          />
        }
      </div>
      <SubmitPreloader submitPreloaderActive={submitPreloaderActive} />
    </>
  );
};

export default withFormBuy(MainFormBuy);
