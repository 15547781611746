import { GET_KOATUU, KOATUU_ERROR } from "../types";
import axios from "axios";

export const getKoatuu = () => async (dispatch) => {
  try {
    const res = await axios.get(`./koatuu_new.json`);

    dispatch({
      type: GET_KOATUU,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: KOATUU_ERROR,
      payload: console.log(e),
    });
  }
};
