import { Component } from "react";
import { BackArrowIcon, KeyIcon } from "@this-assets/icons/ui";


class IdentificationRequiredWnd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
    };
  }

  govHref(mid, totalPrice) {
    localStorage.setItem("mid", mid);
    localStorage.setItem("price", totalPrice);
  }

  render() {
    const { nextStep, mid, totalPrice, prevStep } = this.props;
    const govState =
      "state=CfDJ8MxrCNIJ7D1Hm8Q86uOI_kJOqxe-t5CMgDwXzwxBnpZx7QLPpQZFJ94DPaW0PD99PxsKwVrzET4AbL8BvuNdB5F04AXjRTvgtHGNI8bpmKx96lewwXJkYPF39TeFc6j8FpbvJtzjGN8Y7geGpVsnusc8W790ljWvgZkCL6-x8dZ3cUTPc1KoyTlaY1jIVP33je8e6gSXEp5WpaF8kAepZbGottwPEaxpB6wOq9-M5sd3PhF2Fq6YOWIPQqx1Vv9Z6SS-Wv2IXisjPllimvw94v8";
    const getRedirectUri = location.protocol + "//" + location.host;
    const redirect = "redirect_uri=" + getRedirectUri + "/success";
    const type = "response_type=code";

    return (
      <>
        <div className="main-form__stage-3 active">
          <div className="main-form__title-block">
            <div onClick={(e) => {
                e.preventDefault();
                prevStep();
              }}>
              <BackArrowIcon width={20} height={36} />
            </div>
            <div className="title title_md">
              Запит на оцінку земельної ділянки
            </div>
          </div>
          <div className="main-form__content">
            <div className="title title_md-md">
              Для завершення проходження оцінки земельної ділянки, будь ласка,
              пройдіть процедуру ідентифікації через наступні сервіси:
            </div>
          </div>
          <div className="btn-block btn-block_center">
            <div className="row">
              <div className="col col_6">
                <a
                  href={
                    "https://id.gov.ua/?" +
                    type +
                    `&client_id=${process.env.GOV_UA_CLIENT_ID}&scope=&` +
                    type +
                    "&" +
                    redirect +
                    "&" +
                    govState
                  }
                  onClick={this.govHref(mid, totalPrice)}
                  className="btn btn_dark-blue"
                >
                  <KeyIcon width={32} height={32} />
                  <span className='pl-15'>BankID/Mobile ID/КЕП</span>
                </a>
              </div>
              <div className="col col_6">
                <button
                  className="btn btn_white"
                  onClick={(e) => {
                    e.preventDefault();
                    nextStep();
                  }}
                >
                  <span>ВНЕСТИ ДАНІ ВРУЧНУ</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default IdentificationRequiredWnd;