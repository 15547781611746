import { PlusBtnIcon } from "@this-assets/icons/ui";


const UploadImg = ({onClickHandler, title, acceptType}) => {
  return (
    <label>
      <input 
        style={{display: 'none'}} 
        name="pictures" 
        type="file" 
        accept={acceptType} 
        multiple 
        onChange={onClickHandler} 
      />
      <div className='d-flex align-items-center'>
        <PlusBtnIcon width={32} height={32} />
        <span className='pl-10 fs-18-24-b'>{title}</span>
      </div>
    </label>
  );
};

export default UploadImg;