import { NoteWithPenIcon, MarketGraphicOnLaptopIcon, BagWithMoneyIcon } from  '@this-assets/icons/ui';
import { useWindowSize } from "@this-core/hooks/hook-window-size";
import { COLOR_BLUE } from '@this-core/basic-styles/colors-const';
import styles from './how-it-works-sell-block.module.scss';
import HowItWorksItem from '../../items/how-it-works-item/how-it-works-item';

const steps = [
  {
    icon: <NoteWithPenIcon width={98} height={98} />,
    title: 'Залиште запит'
  },
  {
    icon: <MarketGraphicOnLaptopIcon width={98} height={98} />,
    title: 'Ми оцінюємо землю'
  },
  {
    icon: <BagWithMoneyIcon width={98} height={98} fill={COLOR_BLUE} />,
    title: 'Оформлюйте договір'
  },
];

const HowItWorksSellBlock = () => {
  const { isMobile } = useWindowSize();

  return (
    <div className={`mt-20 ${styles.block}`}>
      {
        steps.map(({icon, title}, index, steps) => {
          return <HowItWorksItem key={`${title}_${index}_`} icon={icon} title={title} steps={steps} index={index} isMobile={isMobile} />;
        })
      }
    </div>
  );
};

export default HowItWorksSellBlock;
