import { Component } from "react";

class FormSuccessRRWnd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
    };
  }

  render() {
    const { mid } = this.props;

    return (
      <>
        <div className="main-form__success active">
          <div className="main-form__title-block">
            <div className="title title_md">
              Дякуємо, що скористалися нашим сервісом
            </div>
          </div>
          <div className="main-form__content">
            <div className="title title_md-md">
              Ми завжди будемо раді Вас бачити в особистому кабінеті
            </div>
            <a
              href={process.env.PERSONAL_CABINET_REGISTER + mid}
              className="btn btn_dark-blue"
            >
              <span>ПЕРЕЙТИ ДО ОСОБИСТОГО КАБІНЕТУ</span>
            </a>
          </div>
        </div>
      </>
    );
  }
}

export default FormSuccessRRWnd;
