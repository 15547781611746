import {Component} from 'react';
import { ENDPOINTS } from "@this-core/api/endpoints";
import { getRequest, postRequest } from "@this-core/api";
import { REGEX } from "@this-core/constants/regex";
import findErrorContent from "@this-core/helpers/find-error-content";
import {WND} from '@this-core/constants/wnd-const';

function withFormSale(C) {

  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        popupStage: process.env.SCREEN_RESOLUTION === 'mobile' ? WND.ST_CLOSED_NOW : WND.ST_USER_DATA,
        submitPreloaderActive: false,
        mid: "",
        cadNumber: "",
        cadNumberVisit: false,
        cadNumberError: "Обов’язково до заповнення",
        name: "",
        nameVisit: false,
        nameError: "Обов’язково до заповнення",
        phone: "",
        phoneVisit: false,
        phoneError: "Обов’язково до заповнення",
        koatuuVisit: false,
        koatuuError: "Обов’язково до заповнення",
        address: "",
        errorTitle: "",
        errorTitle2: "",
        hasLink: false,
        area: false,
        stepsActive: false,
        stepsStep: "step-3",
        totalPrice: "",
        totalPrice2: "",
        formData: [],
        formDataName: [],
        formData2: [],
        formDataName2: [],
        docType: false,
        reason: "",
        reason2: "",
      };
      this.baseState = this.state;
    }
  
    resetState = () => {
      setTimeout(() => this.setState(this.baseState), 400);
    };
  
    handleChange = (error, errorText) => (e) => {
      if (e.target.name === "cadNumber" || e.target.name === "phone") {
        e.target.value.indexOf("X") > -1 || e.target.value.length < 1
          ? this.setState({
              [error]: errorText,
              [e.target.name]: e.target.value,
            })
          : this.setState({
              [error]: "",
              [e.target.name]: e.target.value,
            });
      } else {
        e.target.value.length < 3
          ? this.setState({
              [error]: errorText,
              [e.target.name]: e.target.value,
            })
          : this.setState({
              [error]: "",
              [e.target.name]: e.target.value,
            });
      }
    };
  
    blurHandler = () => (e) => {
      this.setState({
        [e.target.name + "Visit"]: true,
      });
  
      if (e.target.name === "name") {
        e.target.value.length < 3
          ? this.setState({
              nameError: "Обов’язково до заповнення",
            })
          : this.setState({
              nameError: "",
            });
      } else if (e.target.name === "email") {
        !(REGEX.EMAIL).test(String(e.target.value).toLowerCase())
          ? this.setState({
              emailError: "Заполните ЕДРПОУ",
            })
          : this.setState({
              emailError: "",
            });
      }
    };
  
    handleChange2 = (input) => (e) => {
      this.setState({
        [input]: e.target.value,
      });
    };
  
    updateData = (stateName, value) => {
      this.setState({ [stateName]: value });
    };
  
    submitForm = (phone, name, cadNumber, address) => (e) => {
      e.preventDefault();
      const nameMod = name.trim();
      // Temporary solution ///////////////
 //     return this.setState({
 //       popupStage: WND.ST_ERROR,
 //       submitPreloaderActive: false,
 //       errorTitle: "Дякуємо, що скористалися нашим сервісом",
 //       errorTitle2:
 //         "На жаль, в даний час вартість земельної ділянки розрахувати неможливо, зверніться до нас пізніше",
 //     });
      //////////
  
      this.setState({
        submitPreloaderActive: true,
      });
  
      if (address) {
        var bodyFormData = JSON.stringify({
          phone: phone,
          name: nameMod,
          channel: "site",
          address: {
            KOATUU: address,
          },
        });
      } else {
        var bodyFormData = JSON.stringify({
          phone: phone,
          name: nameMod,
          channel: "site",
          cn: cadNumber,
        });
      }
  
      postRequest({
        bodyFormData: bodyFormData, 
        restUrl: ENDPOINTS.LAND_PROP_START,
      })
        .then((response) => {
          this.setState({
            mid: response.data.mid,
          });
  
          function sendFile(index) {
            let stop = false;
            if (stop) return;
  
            getRequest({restUrl: `/api/landpropget?mid=${response.data.mid}&channel=site`})
              .then((response) => {
                if (response.data.status === "ok") {
                  stop = true;
  
                  if (response.data.data.price === 0) {
                    this.setState({
                      popupStage: WND.ST_ERROR,
                      submitPreloaderActive: false,
                      errorTitle: "Дякуємо, що скористалися нашим сервісом",
                      errorTitle2:
                        "Ми отримали Ваші дані. Наш менеджер з Вами зв'яжеться.",
                    });
                  } else {
                    this.setState({
                      submitPreloaderActive: false,
                      totalPrice: response.data.data.price,
                      totalPrice2: response.data.data.price2,
                      popupStage: WND.ST_OFFER_COOPARATION,
                      stepsActive: true,
                      stepsStep: "step-3",
                    });
                    if (response.data.isLot) {
                      this.setState({
                        area: true,
                      });
                    }
                  }
                } else if (response.data.status === "error") {
                  stop = true;
                  this.setState({
                    popupStage: WND.ST_ERROR,
                    submitPreloaderActive: false,
                    ...findErrorContent(response.data)
                  });
                } else {
                  // Нужно почистить setTimeout после завершения работы
                  setTimeout(() => sendFile.call(this, index + 1), 2000);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.state.mid) {
            // Нужно почистить setTimeout после завершения работы
            setTimeout(() => sendFile.call(this, 0), 2000);
          } else if (response.data.errorCode === 506) {
            this.setState({
              popupStage: WND.ST_ERROR,
              submitPreloaderActive: false,
              hasLink: true,
              ...findErrorContent(response.data),
            });
          } else {
            this.setState({
              popupStage: WND.ST_ERROR,
              submitPreloaderActive: false,
              ...findErrorContent(response.data),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            popupStage: WND.ST_ERROR,
            submitPreloaderActive: false,
            errorTitle: "Сервер не відповідає",
            errorTitle2: "Але ми вже працюємо над цією проблемою, спробуйте пізніше",
          });
        });
    };
  
    submitForm4 =
      (
        formData,
        formDataName,
        formData2,
        formDataName2,
        docType,
        totalPrice,
        mid
      ) =>
      (e) => {
        e.preventDefault();
  
        this.setState({
          submitPreloaderActive: true,
        });
  
        let data1 = formData.map((el, i) => {
          return {
            ftype: "ІПН",
            fdescription: "лицьова сторона",
            fname: formDataName[i],
            fbase64: formData[i],
          };
        });
  
        let data2 = formData2.map((el, i) => {
          return {
            ftype: "Інший",
            fdescription: docType,
            fname: formDataName2[i],
            fbase64: formData2[i],
          };
        });
  
        const dateResult = data1.concat(data2);
  
        let documents = {
          mid: mid,
          channel: "site",
          data: {
            result: "yes",
            price: totalPrice,
            reason: "",
            documents: dateResult,
          },
        };
  
        var bodyFormData = JSON.stringify(documents);
  
        postRequest({
          bodyFormData: bodyFormData,
          restUrl: ENDPOINTS.LAND_PROP_COMPLETE,
        })
          .then((response) => {
            response.data.status === "complete"
              ? this.setState({
                  submitPreloaderActive: false,
                  popupStage: WND.ST_REQUEST_ADD_SUCCESS,
                })
              : "";
          })
          .catch((error) => {
            console.log(error);
          });
      };
  
    submitFormRejectionReason = (mid, totalPrice, reason, reason2) => (e) => {
      e.preventDefault();
      this.setState({
        submitPreloaderActive: true,
      });
      let data = {
        mid: mid,
        channel: "site",
        data: {
          result: "no",
          price: totalPrice,
          reason: reason + " - " + reason2,
          documents: [],
        },
      };
      var bodyFormData = JSON.stringify(data);
  
      postRequest({
        bodyFormData: bodyFormData,
        restUrl: ENDPOINTS.LAND_PROP_COMPLETE,
      })
        .then((res) => {
          if (res.data.status === "complete") {
            this.setState({
              popupStage: WND.ST_REQUEST_ADD_SUCCESS_RR,
              submitPreloaderActive: false,
            })
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    
    render() {
      return <C {...this.state} 
          reason2={this.state.reason2} 
          onSetState={(obj) => this.setState(obj)}
          onSubmitForm={this.submitForm} 
          onSubmitForm4={this.submitForm4}
          onSubmitFormRejectionReason={this.submitFormRejectionReason}
          onHandleChange={this.handleChange}
          onBlurHandler={() => this.blurHandler()}
          onUpdateData={this.updateData}
          onResetState={this.resetState}
        />;
    }
  };
}

export default withFormSale;
