export const GET_KOATUU = "GET_KOATUU";
export const KOATUU_ERROR = "KOATUU_ERROR";
export const GET_APPLICATIONSONSALE = "GET_APPLICATIONSONSALE";
export const APPLICATIONSONSALE_ERROR = "APPLICATIONSONSALE_ERROR";

export const LAND_IN_SALE_REQUEST = 'LAND_IN_SALE_REQUEST';
export const LAND_IN_SALE_SUCCESS = 'LAND_IN_SALE_SUCCESS';
export const LAND_IN_SALE_ERROR = 'LAND_IN_SALE_ERROR';

export const GEOCODE_COORDINATE_REQUEST = 'GEOCODE_COORDINATE_REQUEST';
export const GEOCODE_COORDINATE_SUCCESS = 'GEOCODE_COORDINATE_SUCCESS';
export const GEOCODE_COORDINATE_ERROR = 'GEOCODE_COORDINATE_ERROR';