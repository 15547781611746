import Header from "../../components/header/header";
import { FarmerLogoIcon } from "@this-core/public/assets/icons/ui";
import { COLOR_DARK_BLUE } from "@this-core/basic-styles/colors-const";
import styles from './for-farmer-page.module.scss';
import backgroundImg from '@this-assets/imgs/background.png';
import { NavLink } from "react-router-dom";
import { HOME_LINK } from '@this-core/constants/page-links';
import { PlantIcon } from  '@this-assets/icons/ui';
import SimpleBigBtn from "../../components/buttons/simple-big-btn";
import ReactPlayer from 'react-player/youtube';
import { useWindowSize } from "@this-core/hooks/hook-window-size";
import Footer from '../../components/footer/footer';
import { Suspense, lazy } from 'react';
import { BlueCyrclePreloader } from '@this-assets/preloaders';

const CashIcon = lazy(() => import('@this-assets/icons/ui-lazy/cash.svg'));

const ForFarmerPage = () => {
  const { size } = useWindowSize();
  let playerHeight = 741;
  if ( size == 'xs' || size == 'sm' ) {
    playerHeight = 500;
  }

  return (
    <>
      <Header header_class="header header_dark" darkMode={true} />
      <div className={styles.backgroundImg}>
        <img src={backgroundImg}></img>
      </div>
      <div className='mt-55 container'>
        <div className={`${styles.titleContainer}`}>
          <div className={`d-flex flex-column align-items-center ${styles.logoBlock}`}>
            <div className='mt-30'>
              <FarmerLogoIcon  width={80} height={80} fill={COLOR_DARK_BLUE} />
            </div>
            <h1 className='mt-25 fs-21-24-b dark-blue t-center montserrat'>ПАЙОВИКАМ</h1>
          </div>
          <p className={`fs-40-48 fw-600`}>Продаж земельної ділянки сьогодні – це ваша можливість інвестувати у власне майбутнє.</p>
        </div>
      </div>
      <div className='mt-55 container'>
        <p className='fs-21-28'><span className='fs-21-24-b dark-blue'>LandInvest</span> онлайн-платформа для проведення земельних торгів. Продаж земельної ділянки сьогодні – це ваша можливість інвестувати у власне майбутнє. Ми прагнемо вивести відносини між власниками ділянок та потенційними інвесторами на якісно новий рівень і розвинути прозорий ринок землі в Україні. </p>
        <div className='mt-55'>
          <ReactPlayer
            height={playerHeight}
            width='100%'
            controls={true}
            url={process.env.VIDEO_LINK} />
        </div>
        <p className='mt-55 fs-21-28'>Користуючись нашою платформою власник земельної ділянки може отримати кваліфіковану безкоштовну консультацію щодо актуальних законодавчих змін, оформити заявку на експертну оцінку земельної ділянки та обрати найоптимальніший варіант для продажу та купівлі. Для оформлення запиту достатньо вказати кадастровий номер ділянки, персональні дані та контактний номер телефону.</p>
        <div className={`mt-55 ${styles.iconsBlock}`}>
          <div className='d-flex'>
            <div className={styles.boxWhithShadow}>
              <Suspense fallback={<BlueCyrclePreloader width={120} height={82} />}>
                <CashIcon width={120} height={82} />
              </Suspense>
            </div>
            <div className='ml-40 d-flex flex-column justify-content-center'>
              <p className='fs-40-48-b dark-gray'>2 МЛРД ДОЛ</p>
              <p className='mt-8 fs-21-24 dark-gray'>Надходження до бюджетів</p>
            </div>
          </div>
          <div className='d-flex'>
            <div className={styles.boxWhithShadow}>
              <PlantIcon width={104} height={90} />
            </div>
            <div className='ml-40 d-flex flex-column justify-content-center'>
              <p className='fs-40-48-b dark-gray'>27,7 МЛН ГА</p>
              <p className='mt-8 fs-21-24 dark-gray'>Приватні паї</p>
            </div>
          </div>
        </div>
        <p className='mt-55 fs-40-56 fw-600'>LandInvest - це гарантія чесного та взаємовигідного партнерства без ризиків і втрат!</p>
      </div>
      <div className='mt-190 py-30 d-flex justify-content-center' style={{background: '#E6F7FF'}} >
        <NavLink to={HOME_LINK}>
          <SimpleBigBtn title='ОЦІНИТИ МОЮ ДІЛЯНКУ' width={350} />
        </NavLink>
      </div>
      <div className='mt-25'>
        <Footer />
      </div>
    </>
  );
};

export default ForFarmerPage;