import axios from 'axios';
import {Component} from 'react';
import { WND } from '../constants/wnd-const';

function withFormBuy(C) {

  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        popupStage: WND.ST_USER_DATA,
        submitPreloaderActive: false,
        name: "",
        nameVisit: false,
        nameError: "Обов’язково до заповнення",
        phone: "",
        phoneVisit: false,
        phoneError: "Обов’язково до заповнення",
        area: "",
        investments: "",
        cost: "",
        region: "",
      };
      this.baseState = this.state;
    }
  
    resetState = () => {
      setTimeout(() => this.setState(this.baseState), 400);
    };
  
    handleChange = (error, errorText) => (e) => {
      if (e.target.name === "phone") {
        e.target.value.indexOf("X") > -1 || e.target.value.length < 1
          ? this.setState({
              [error]: errorText,
              [e.target.name]: e.target.value,
            })
          : this.setState({
              [error]: "",
              [e.target.name]: e.target.value,
            });
      } else if (
        e.target.name === "area" ||
        e.target.name === "investments" ||
        e.target.name === "cost"
      ) {
        let st = new RegExp("^[0-9][0-9]*[.]?[1-9]{0,2}$");
  
        // e.preventDefault();
  
        e.target.value && !st.test(e.target.value)
          ? e.preventDefault()
          : this.setState({
              [e.target.name]: e.target.value,
            });
  
      } else {
        e.target.value.length < 3
          ? this.setState({
              [error]: errorText,
              [e.target.name]: e.target.value,
            })
          : this.setState({
              [error]: "",
              [e.target.name]: e.target.value,
            });
      }
    };
  
    blurHandler = () => (e) => {
      this.setState({
        [e.target.name + "Visit"]: true,
      });
  
      if (e.target.name === "name") {
        e.target.value.length < 3
          ? this.setState({
              nameError: "Обов’язково до заповнення",
            })
          : this.setState({
              nameError: "",
            });
      } else if (e.target.name === "email") {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
        !re.test(String(e.target.value).toLowerCase())
          ? this.setState({
              emailError: "Заполните ЕДРПОУ",
            })
          : this.setState({
              emailError: "",
            });
      }
    };
  
    updateData = (stateName, value) => {
      this.setState({ [stateName]: value });
    };
  
    submitForm = (name, phone, area, investments, cost, region) => (e) => {
      e.preventDefault();
  
      this.setState({
        submitPreloaderActive: true,
      });
  
      let data = {
        subject: "Повідомлення",
        body: `<h1>Вітаємо на порталі!</h1> ${
          name ? "<br>Ім’я та по батькові: " + name : ""
        }
      ${phone ? "Номер телефону: " + phone : ""}
      ${area ? "Бажана площа, га: " + area : ""}
      ${investments ? "Розмір інвестицій, $: " + investments : ""}
      ${cost ? "Вартість за га, $: " + cost : ""}
      ${region ? "Бажаний регіон: " + region : ""}`,
        to: ["info@land-invest.com.ua"],
      };
  
  
      var bodyFormData = JSON.stringify(data);
  
      axios
        .post(`${process.env.K2_API_URL}/api/sendmail`, bodyFormData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic dm9yZGVsOnZvcmRlbA==",
          },
        })
        .then((res) => {
          res.data.status === "ok" && this.setState({
              submitPreloaderActive: false,
              popupStage: WND.ST_REQUEST_ADD_SUCCESS,
            });
        })
        .catch((error) => {
          console.log(error);
        });
    };
    
    render() {
      return <C {...this.state}
          onSubmitForm={this.submitForm}
          onHandleChange={this.handleChange}
          onBlurHandler={this.blurHandler}
          onUpdateData={this.updateData}
          onResetState={this.resetState}
        />;
    }
  };
}

export default withFormBuy;