
const Tooltip = ({
  tooltipActive,
  tooltipClassActive,
  tooltipClass,
  children,
}) => {
  return (
    <div className={tooltipActive ? tooltipClassActive : tooltipClass}>
      {children}
    </div>
  );
};

export default Tooltip;