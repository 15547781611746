import { BackArrowIcon } from "@this-assets/icons/ui";
import CheckboxBtn from "../../buttons/checkbox";
import Tooltip from "../../tooltip";
import withRejectionReasonWnd from "@this-core/HOC/hoc-rejection-reason-wnd";
import { BlueCyrclePreloader } from '@this-assets/preloaders';
import { Suspense, lazy } from 'react';

const RejectionReasonIcon = lazy(() => import('@this-assets/icons/ui-lazy/rejection-reason.svg'));

const RejectionReasonWnd = (props) => {
  const { reason, reason2, consent, prevStep, onSetState, onTooltipChecked,
    onTooltipItem, handleChange } = props;

  return (
    <>
      <div className="main-form__rejection-reason active">
        <div className="main-form__title-block">
          <div onClick={(e) => {
              e.preventDefault();
              prevStep();
            }}>
            <BackArrowIcon width={20} height={36} />
          </div>
          <div className="title title_md">
            Вкажіть, будь ласка, причину відмови
          </div>
        </div>

        <div className="row">
          <div className="col col_3">
            <div className="main-form__img">
              <Suspense fallback={<BlueCyrclePreloader width={256} height={240} />}>
                <RejectionReasonIcon width={256} height={240} />
              </Suspense>
            </div>
          </div>
          <div className="col col_9">
            <div className="main-form__content">
              <div className="rejection-reason-radio">
                <label>
                  <input
                    type="radio"
                    name="rejection_reason"
                    value="Недостатня сума"
                    onChange={onTooltipChecked(0)}
                  ></input>
                  <span>Недостатня сума</span>

                  <Tooltip
                    tooltipActive={onTooltipItem(0)}
                    tooltipClassActive="tooltip active"
                    tooltipClass="tooltip"
                  >
                    <span>Запропонуйте власну</span>
                    <input
                      type="text"
                      value={reason2}
                      onChange={handleChange}
                      placeholder="За яку суму ви згодні продати?"
                    ></input>
                  </Tooltip>
                </label>
                <label>
                  <input
                    type="radio"
                    name="rejection_reason"
                    value="На даний момент не готовий продавати"
                    onChange={onTooltipChecked(1)}
                  ></input>
                  <span>На даний момент не готовий продавати</span>

                  <Tooltip
                    tooltipActive={onTooltipItem(1)}
                    tooltipClassActive="tooltip active"
                    tooltipClass="tooltip"
                  >
                    <span>Коли нагадати</span>
                    <input
                      type="date"
                      value={reason2}
                      onChange={handleChange}
                    ></input>
                  </Tooltip>
                </label>
                <label>
                  <input
                    type="radio"
                    name="rejection_reason"
                    value="Вкажіть Ваш варіант"
                    onChange={onTooltipChecked(2)}
                  ></input>
                  <span>Інше</span>
                  <Tooltip
                    tooltipActive={onTooltipItem(2)}
                    tooltipClassActive="tooltip tooltip_relative active"
                    tooltipClass="tooltip tooltip_relative"
                  >
                    <textarea
                      value={reason2}
                      onChange={handleChange}
                      placeholder="Вкажіть Ваш варіант, це допоможе нам робити кращі пропозиції"
                    ></textarea>
                  </Tooltip>
                </label>
              </div>
              <div className="btn-block btn-block_center">
                <div className="row">
                  <div className="col">
                    <button
                      type="submit"
                      className={`btn btn_dark-blue ${reason && reason2 && consent ? '' : 'btn_dark-blue_disable'}`}
                      disabled={!reason && !reason2 && !consent}
                    >
                      <span>ВІДПРАВИТИ</span>
                    </button>
                    <div className='mt-15'>
                      <CheckboxBtn
                        onClickHandler={() => onSetState({consent: !consent})} 
                        isSelect={consent}
                        styles='pl-8 fs-14-19 gray'
                        title='Даю згоду на отримання пропозицій від інших інвесторів' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRejectionReasonWnd(RejectionReasonWnd);
