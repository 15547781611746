import { Marker, useMapEvents } from 'react-leaflet';
import L from "leaflet";
import ReactDOMServer from 'react-dom/server';
import styles from './map-marker-group.module.scss';
import { useState } from 'react';

const maxZoom = 8;
const defaultZoom = 7;
const MapMarkerGroup = ({map, sellCount, coordinate}) => {
  const [zoom, setZoom] = useState(map?._zoom || defaultZoom);

  const mapEvents = useMapEvents({
    zoomend: () => setZoom(mapEvents?.getZoom()),
  });

  const __eventHandlers = {
    click: () => map.setView(coordinate, zoom + 1)
  };

  if (sellCount == 0 || zoom > maxZoom) return <div></div>;

  const PriceMarker = () => {
    return <div className={`${styles.circleMarker} d-flex justify-content-center align-items-center`}>
      <p className='fs-12-16-b white'>{sellCount}</p>
    </div>;
  };

  const markerDiv = L.divIcon({
    iconSize: [10, 10],
    popupAnchor: [-5, -15],
    className: 'markerPosition',
    html: ReactDOMServer.renderToString(<PriceMarker />),
  });

  return <Marker eventHandlers={__eventHandlers} icon={markerDiv} position={coordinate} />;
};

export default MapMarkerGroup;