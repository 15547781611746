import { GEOCODE_COORDINATE_ERROR, GEOCODE_COORDINATE_SUCCESS } from '../types';


export const getGeocodeCoordinate = (place = 'Київ') => async (dispatch) => {

  fetch(`${process.env.LAND_INVEST_API_URL}/geocode?address=${place}`)
  .then(res => res.json())
    .then(data => {
      // Adding place fild for cash & find a second time;
      const placeFullData = { place, results: data.results };
      dispatch({
        type: GEOCODE_COORDINATE_SUCCESS,
        payload: placeFullData,
      });
    })
    .catch(err => {
      console.log(err);
      dispatch({
        type: GEOCODE_COORDINATE_ERROR,
        payload: err,
      });
    });
};