import { Polygon, Popup, Marker, useMapEvents } from 'react-leaflet';
import L from "leaflet";
import MapPopups from '../../popups/map-popup/map-popup';
import ReactDOMServer from 'react-dom/server';
import styles from './map-marker-item.module.scss';
import { findAverageCoordinates } from '@this-core/helpers/coordinats-helpers';
import { priceFormater } from '@this-core/helpers/formaters';
import { useState } from 'react';

const minZoom = 9;
const defaultZoom = 7;
const MapMarkerItem = ({poligon}) => {
  const [zoom, setZoom] = useState(defaultZoom);
  const { CadastralNumber, Address, AreaGA, OfferedCoast, OfferedCostEval, GeoJson} = poligon;
  const { geometry: {coordinates} } = GeoJson;

  const mapEvents = useMapEvents({
    zoomend: () => setZoom(mapEvents?.getZoom()),
  });

  if (coordinates.length === 0 || minZoom > zoom) return <div></div>;

  const PriceMarker = () => {
    return <div className={styles.priceMarker}>
      <p className='fs-12-16-b'>{priceFormater({price: OfferedCostEval, symbol: '₴'})}</p>
    </div>;
  };

  const markerDiv = L.divIcon({
    iconSize: [10, 10],
    popupAnchor: [-5, -15],
    className: 'markerPosition',
    html: ReactDOMServer.renderToString(<PriceMarker />),
  });

  return <div>
    <Marker icon={markerDiv} position={findAverageCoordinates(coordinates[0])}>
      <Popup>
        <MapPopups cadastrNumber={CadastralNumber} address={Address} areaGA={AreaGA} offeredCoast={OfferedCoast} 
          offeredCostEval={OfferedCostEval} />
      </Popup>
    </Marker>
    <Polygon pathOptions={{color: '#3867b1'}} positions={coordinates} />
  </div>;
};

export default MapMarkerItem;
