import { NavLink } from "react-router-dom";
import ImageLazy from "@this-core/components/image/image-lazy";
import styles from './news-item-mini.module.scss';


const NewsItemMini = ({url, imgUrl, title}) => {

  return (
    <div style={{width: '256px'}}>
      <NavLink to={url}>
        <ImageLazy url={imgUrl} width={256} height={136} className={`top-news__img`} />
        <p className={`pb-25 t-center ${styles.text}`}>{title}</p>
      </NavLink>
    </div>
  );
};

export default NewsItemMini;