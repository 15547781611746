import { Helmet } from "react-helmet";
import Header from "../../components/header/header";
import Tabs from "../../components/tabs/tabs";
import backgroundImg from '@this-assets/imgs/main-top-block-desktop-bg.png';
import AboutUsBlock from "../../components/blocks/about-us-block/about-us-block";
import FaqsBlock from '../../components/blocks/faqs-block/faqs-block';
import NewsBlock from "../../components/blocks/news-block/news-block";
import ExpertsBlock from "../../components/blocks/experts-block/experts-block";
import MainFormSale from "../../components/forms/main-form-sale/main-form-sale";
import MainFormBuy from "../../components/forms/main-form-buy/main-form-buy";
import InSaleSliderBlock from "../../components/blocks/in-sale-slider-block/in-sale-slider-block";
import FavorBlock from "../../components/blocks/favor-block/favor-block";
import { homePageQuestions } from "@this-core/api-data/faqs-data";
import HowItWorksSellBlock from "../../components/blocks/how-it-works-sell/how-it-works-sell-block";
import Footer from '../../components/footer/footer';


const HomePage = (props) => {
  
  return (
    <>
      <Helmet>
        <title>
          ПЕРША ОНЛАЙН-ПЛАТФОРМА ОФІЦІЙНОГО ПРОДАЖУ ТА КУПІВЛІ ЗЕМЛІ
        </title>
        <meta
          name="description"
          content="LandInvest – онлайн-платформа для проведення земельних торгів, Купівлі та продажу земельних ділянок.
Продаж землі сьогодні – це ваша можливість інвестувати у власне майбутнє."
        ></meta>
      </Helmet>
      <Header header_class="header" />
      <div className="home">
        <div className="main-top-block" id="main-top-block">
          <div className="main-top-block__content container">
            <div className="title">
              Платформа офіційного продажу та купівлі землі
            </div>
              <Tabs>
              <div label="Продати" classSale={props.classSale}>
                {props.formSale &&
                  <MainFormSale/>
                }
              </div>                      
              <div label="Купити" classBuy={props.classBuy}>
                {props.formBuy &&
                  <MainFormBuy/>
                }
              </div>
              </Tabs>
          </div>
          <div className="main-top-block__bg">
            <img src={backgroundImg}></img>
          </div>
        </div>
        <div className="container">
          <HowItWorksSellBlock />
          <InSaleSliderBlock />
          <FavorBlock />
          <AboutUsBlock btnShow={true} />
          <ExpertsBlock />
          <FaqsBlock listQuestions={homePageQuestions} styles='title' />
          <NewsBlock />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomePage;