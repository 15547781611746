
const findErrorContent = (data) => {
  const { errorCode, errorMsg } = data;
  
  switch(errorCode) {
    case 400:
      return {
        errorTitle: "Помилка у параметрі запиту",
        errorTitle2: "Будь ласка, перевірте дані та спробуйте ще раз",
      };
    case 404:
      return {
        errorTitle: "Заявку не знайдено",
        errorTitle2: "Будь ласка, спробуйте ще раз",
      };
    case 500:
      return {
        errorTitle: "Дякуємо, що скористалися нашим сервісом",
        errorTitle2: "Ми отримали Ваші дані. Наш менеджер з Вами зв'яжеться.",
      };
    case 503:
      return {
        errorTitle: "Перевищено ліміт запитів на день",
        errorTitle2: "Ви перевищіли ліміт запитів на один день, будь ласка спробуйте завтра",
      };
    case 505:
      return {
        errorTitle: "Відсутні дані в реєстрі НАІС",
        errorTitle2: "Будь ласка, перевірте дані та спробуйте ще раз",
      };
    case 506:
      return {
        errorTitle: `За Вашим запитом вже подано заявку №${errorMsg}`,
        errorTitle2: "Відстежити роботу над Вашим запитом Ви можете в особистому кабінеті пайовика за посиланням",
      };
    case 507:
      return {
        errorTitle: "Дана ділянка вже продана.",
        errorTitle2: "Перевірте кадастровий номер та спробуйте ще раз або зв'яжіться з власником ділянки",
      };
    default:
      return {
        errorTitle: "Внутрішня помилка сервера",
        errorTitle2: "Щось пішло не так, будь ласка спробуйте ще раз",
      };
  }
};

export default findErrorContent;