import Header from "../../components/header/header";
import { InvestorLogoIcon } from "@this-core/public/assets/icons/ui";
import { COLOR_BLUE } from "@this-core/basic-styles/colors-const";
import styles from './for-investor-page.module.scss';
import backgroundImg from '@this-assets/imgs/main-top-block-desktop-bg.png';
import FaqsBlock from "../../components/blocks/faqs-block/faqs-block";
import { investorPageQuestions } from "@this-core/api-data/faqs-data";
import { NavLink } from "react-router-dom";
import { BUY_LINK } from '@this-core/constants/page-links';
import SimpleLargeBtn from "../../components/buttons/simple-large-btn";
import { GrowthLogoIcon, DollarLogoIcon, CrownLogoIcon } from "@this-core/public/assets/icons/ui";
import HowItWorksBuyBlock from "../../components/blocks/how-it-works-buy/how-it-works-buy-block";
import Footer from '../../components/footer/footer';


const ForInvestorPage = () => {
  return (
    <>
      <Header header_class="header header_dark" darkMode={true} />
      <div className={styles.backgroundImg}>
        <img src={backgroundImg}></img>
      </div>
      <div className='container'>
        <div className={`my-45 ${styles.titleContainer}`}>
          <div className={`d-flex flex-column align-items-center ${styles.logoBlock}`}>
            <div className='mt-30'>
              <InvestorLogoIcon  width={80} height={80} fill={COLOR_BLUE} />
            </div>
            <h1 className='mt-25 fs-21-24-b blue t-center montserrat'>ІНВЕСТОРАМ</h1>
          </div>
          <div className={`d-flex align-items-center`}>
            <div className={styles.brightness}></div>
            <p className={`fs-32-42-b dark-blue ${styles.posRelative}`}>Інвестуйте у землю – ставайте власником сільськогосподарської землі, оформлюйте рентні відносини і отримуйте стабільний дохід</p>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className='container'>
          <h2 className='pt-40 fs-24-32 fw-600'>Хочете інвестувати у власне майбутнє, але не знаєте, з чого розпочати? Купуйте землю! Українські чорноземи – один із найвигідніших активів для інвестування!</h2>
          <div>
            <div className='mt-40 d-flex'>
              <div className='mr-20 mt-5'>
                <GrowthLogoIcon width={28} height={28} />
              </div>
              <p className='fs-24-32 dark-gray'>Ви убезпечуєте себе від ризиків фінансових втрат, адже вартість землі буде тільки зростати.</p>
            </div>
            <div className='mt-40 d-flex'>
              <div className='mr-20 mt-5'>
                <DollarLogoIcon width={28} height={28} />
              </div>
              <p className='fs-24-32 dark-gray'>Площі чорноземів уже не змінити, а ріст попиту на землю – не зупинити</p>
            </div>
            <div className='mt-40 d-flex'>
              <div className='mr-20 mt-5'>
                <CrownLogoIcon width={26} height={24} />
              </div>
              <p className='fs-24-32 dark-gray'>Самостійно обробляти землю чи здавати її в оренду, отримати разовий дохід або регулярний додатковий заробіток – вирішувати вам</p>
            </div>
          </div>
          <h2 className='mt-40 fs-24-32 fw-600'>Платформа LandInvest надає інвесторам доступ до унікального каталогу земельних ділянок України. У нашому реєстрі ви знайдете пропозиції, які точно влаштують вас за вартістю та регіональним розташуванням. Як скористатися?</h2>
          <h2 className='mt-40 fs-24-32 fw-600'>Зареєструйтеся на сайті, створивши особистий кабінет. Таким чином ви отримаєте доступ до інструментів для підготовки, реалізації та контролю за угодами. LandInvest гарантує прозорість проведення угоди, пропонує повний юридично-консультаційний супровід оформлення, забезпечує можливість контролю та відстеження етапів процесу.</h2>
        </div>
      </div>
      <div className='container'>
        <HowItWorksBuyBlock />
        <FaqsBlock listQuestions={investorPageQuestions} styles='mb-40 fs-32-40-b t-center dark-blue' />
        <p className='mt-80 fs-32-40-b t-center dark-blue'>LandInvest – це чесне та взаємовигідне партнерство без ризиків і втрат!</p>
        <div className='mt-50 mb-90 d-flex justify-content-center'>
          <NavLink to={BUY_LINK}>
            <SimpleLargeBtn title='ПІДІБРАТИ ДІЛЯНКУ' width={400} backgroundColor={COLOR_BLUE} />
          </NavLink>
        </div>
      </div>
      <div className='mt-100'>
        <Footer />
      </div>
    </>
  );
};

export default ForInvestorPage;