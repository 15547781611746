import React, { Component } from "react";

class CustomSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
      items: this.props.items || [],
      dropdownActive: false,
      selectedItem: this.props.items && this.props.items[0],
    };
  }

  dropDown = () => {
    this.setState({
      dropdownActive: !this.state.dropdownActive,
    });
  };

  selectItem = (item, val, name) => {
    this.setState({
      selectedItem: item,
      dropdownActive: false,
    });
    item.id === "hidden"
      ? this.props.updateData(name, "")
      : this.props.updateData(name, val);
  };

  render() {
    const { dropdownActive } = this.state;
    return (
      <div className={dropdownActive ? "select active" : "select"}>
        <div className={"select__is-active " + this.state.selectedItem.id} onClick={this.dropDown}>
          {this.state.selectedItem.value}
        </div>
        <div className="select__dropdown">
          {
            this.state.items.map((item) => (
              <div
                key={item.id}
                onClick={() => this.selectItem(item, item.value, this.state.name)}
                className={this.state.selectedItem === item ? "selected" : ""}
              >
                {item.value}
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

export default CustomSelect;
