import Header from "../../components/header/header";
import privacyContent from '@this-core/api-data/privacy-policy';
import styles from './privacy-policy-page.module.scss';
import Footer from '../../components/footer/footer';


const PrivacyPolicy = () => {
  return (
    <>
      <Header header_class="header header_dark" darkMode={true} />
      <div className="privacy-policy">
        <div className="container">
          <h1 className="mt-20 title">Політика конфіденційності</h1>
          <div className={styles.contentDefault} dangerouslySetInnerHTML={{ __html: privacyContent }} />
        </div>
      </div>
      <div className='mt-80'>
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;