import InputMask from "react-input-mask";
import CheckboxBtn from "../../buttons/checkbox";
import BtnWithPopupSecurity from "../../popups/btn-with-popup-security";
import FormSubmitBtnSell from "../../buttons/form-submit-btn-sell";
import withUserDataSellWnd from '@this-core/HOC/hoc-user-data-sell-wnd';


const UserDataSellWnd = (props) => {

  const {
    name,
    nameVisit,
    nameError,
    handleChangeName,
    cadNumber,
    cadNumberVisit,
    cadNumberError,
    handleChangeCadNumber,
    phone,
    phoneError,
    phoneVisit,
    handleChangePhone,
    koatuuError,
    koatuuVisit,
    blurHandler,
    consent,
    capchaResponse,
    formValid,

    koatuu,
    koatuuName,
    koatuuLavel1,
    koatuuLavel2,
    koatuuLavel3,
    koatuuLavel4,
    text, 
    autocompliteShow,
    onClickHandler,
    onTextChangedHandler,
    onSetState,
    onAutocompliteChange,
    onRenderSuggestions,
  } = props;

  return (
    <>
      <div className={"main-form__stage-1 main-form__stage-1_sale active"}>
        <div className="row">
          <div className="col col_4">
            <label className={!autocompliteShow && cadNumberError && cadNumberVisit ? "require" : ""}>
              <span>Кадастровий номер</span>
              <InputMask
                type="text"
                mask="9999999999:99:999:9999"
                maskChar="X"
                placeholder="XXXXXXXXXX:XX:XXX:XXXX"
                value={cadNumber}
                name="cadNumber"
                onChange={!autocompliteShow ? handleChangeCadNumber : null}
                onBlur={!autocompliteShow ? blurHandler : ""}
                disabled={autocompliteShow ? true : false}
              />
              <span
                className={
                  !autocompliteShow && cadNumberError && cadNumberVisit
                    ? "require__error error"
                    : "require__error"
                }
              >
                {cadNumberError}
              </span>
            </label>
          </div>

          <div className="col col_4">
            <label className={nameError && nameVisit ? "require" : ""}>
              <span>
                Ім’я та по батькові<em>*</em>
              </span>
              <input
                type="text"
                placeholder="Введіть Ваше ім’я"
                value={name}
                name="name"
                onChange={handleChangeName}
                onBlur={blurHandler}
              ></input>
              <span className={`require__error ${nameError && nameVisit ? 'error' : ''}`}>
                {nameError}
              </span>
            </label>
          </div>

          <div className="col col_4">
            <label className={phoneError && phoneVisit ? "require" : ""}>
              <span>
                Номер телефону<em>*</em>
              </span>
              <InputMask
                type="text"
                mask="+380999999999"
                maskChar="X"
                placeholder="+380XXXXXXXXX"
                value={phone}
                name="phone"
                onChange={handleChangePhone}
                onBlur={blurHandler}
              />
              <span className={`require__error ${phoneError && phoneVisit ? 'error' : ''}`}>
                {phoneError}
              </span>
            </label>
          </div>

          <div className="col col_4 desktop-hide"></div>

          <div className="col col_4">
            <label className={`autocomplite-input ${autocompliteShow ? "active" : ""} ${koatuuError && koatuuVisit ? "require" : ""}`}>
              <span>
                Введіть місцезнаходження ділянки<em>*</em>
              </span>
              <input
                type="text"
                placeholder="Населений пункт"
                name="koatuu"
                autoComplete="off"
                value={text}
                onChange={onTextChangedHandler(koatuuName)}
                onBlur={blurHandler}
              ></input>
              {onRenderSuggestions(
                koatuu,
                koatuuLavel1,
                koatuuLavel2,
                koatuuLavel3,
                koatuuLavel4
              )}
              <span className={`require__error ${koatuuError && koatuuVisit ? 'error' : ''}`}>
                {koatuuError}
              </span>
            </label>
          </div>
          <div className="col col_4">
            {(capchaResponse && formValid) ? <FormSubmitBtnSell title='ОЦІНИТИ ДІЛЯНКУ' styles='btn btn_dark-blue' /> :
              <FormSubmitBtnSell title='ОЦІНИТИ ДІЛЯНКУ' styles='btn btn_dark-blue btn_dark-blue_disable' onClickHandler={(e) => onClickHandler(e)} />}
          </div>
          <div className="col col_4">
            <CheckboxBtn 
              onClickHandler={onAutocompliteChange} 
              isSelect={autocompliteShow} 
              styles='pl-8 fs-21-28-b dark-gray' 
              title='Немає кадастрового номеру' />
          </div>
          <div className="col col_4">
            <BtnWithPopupSecurity
              btn_class="btn btn_security"
              btn_name="Безпека персональних даних"
            />
          </div>
          <div className="col col_4">
            <CheckboxBtn 
              onClickHandler={() => onSetState({consent: !consent})} 
              isSelect={consent}
              styles='pl-8 fs-14-19 gray'
              title='Даю згоду на обробку персональних даних'
              blankLink='./agreement_290621.pdf' />
          </div>
        </div>
      </div>
    </>
  );
};

export default withUserDataSellWnd(UserDataSellWnd);
