import React, { Component, createRef, Suspense, lazy } from "react";
import { BackArrowIcon } from "@this-assets/icons/ui";
import CheckboxBtn from "../../buttons/checkbox";
import CustomSelect from "../../buttons/custom-select";
import UploadImg from "../../buttons/upload-img";
import { BlueCyrclePreloader } from '@this-assets/preloaders';

const ActIcon = lazy(() => import('@this-assets/icons/docs/acts'));

class LoadLandDocumentsWnd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
      formData2: [],
      formDataName2: [],
      formValid: false,
      fileState: false,
      fileStateError: "",
      selectVisit: false,
      selectError: "Виберіть тип документа",
      consent: true,
    };

    // this.extractFormData = this.extractFormData.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.imgPreviewImag = createRef();
  }

  // extractFormData = function (form) {
  //   const formData = new FormData(document.querySelector(form));
  //   let values = {};

  //   for (var pair of formData.entries()) {
  //     if (values[pair[0]]) {
  //       if (!(values[pair[0]] instanceof Array)) {
  //         values[pair[0]] = new Array(values[pair[0]]);
  //       }
  //       values[pair[0]].push(pair[1]);
  //     } else {
  //       values[pair[0]] = pair[1];
  //     }
  //   }
  //   return values;
  // };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.docType !== prevProps.docType ||
      this.state.fileState !== prevState.fileState ||
      this.state.consent !== prevState.consent
    ) {
      // const { formData2 } = this.state;

      if (
        this.state.fileState === true &&
        this.props.docType !== "Оберіть тип документа" &&
        this.state.consent
      ) {
        this.setState({
          formValid: true,
        });
      } else {
        this.setState({
          formValid: false,
        });
      }

      if (this.props.docType !== "Оберіть тип документа") {
        this.setState({
          selectError: "",
        });
      } else {
        this.setState({
          selectError: "Виберіть тип документа",
        });
      }

      if (this.state.fileState === true) {
        this.setState({
          fileStateError: "",
        });
      } else if (this.state.fileState === "error") {
        this.setState({
          fileStateError:
            "Будь ласка, додайте не менше двох фото Ваших документів (як вказано на прикладі)",
        });
      } else {
        this.setState({
          fileStateError:
            "Невірний формат. Будь ласка, завантажте файл у форматі *.jpg, *.png, *.zip, *.rar, *.7zip, *.doc",
        });
      }
    }
  }

  selectVisit = (value) => {
    this.setState({ selectVisit: value });
  };

  validCheck() {
    if (this.state.formValid) {
      return (
        <button type="submit" className="btn btn_dark-blue">
          <span>ПРОДОВЖИТИ</span>
        </button>
      );
    } else {
      return (
        <button
          onClick={(e) => {
            e.preventDefault();

            if (this.props.docType === "Оберіть тип документа") {
              this.setState({
                selectError: "Виберіть тип документа",
              });
            }
            if (!this.state.fileState) {
              this.setState({
                fileStateError:
                  "Невірний формат. Будь ласка, завантажте файл у форматі *.jpg, *.png, *.zip, *.rar, *.7zip, *.doc",
                selectVisit: true,
              });
            } else if (this.state.fileState === "error") {
              this.setState({
                fileStateError:
                  "Будь ласка, додайте не менше двох фото Ваших документів (як вказано на прикладі)",
                selectVisit: true,
              });
            }
          }}
          className="btn btn_dark-blue btn_dark-blue_disable"
        >
          <span>ПРОДОВЖИТИ</span>
        </button>
      );
    }
  }

  generatePreviewData = (file) => {
    const fr = new FileReader();

    return new Promise((resolve, reject) => {
      fr.addEventListener("load", (e) => {
        const div = document.createElement("div");
        const item = document.createElement("div");
        const p = document.createElement("p");
        const del = document.createElement("i");
        p.innerHTML = file.name;
        div.setAttribute("class", "col");
        item.setAttribute("class", "img-upload__item img-upload__item_file");
        del.setAttribute("class", "img-upload__del");
        div.appendChild(item);
        item.appendChild(p);
        item.appendChild(del);
        resolve(div);
      });
      fr.addEventListener("error", (e) => {
        reject();
      });
      fr.readAsDataURL(file);
    });
  };

  removeAllChildren = (el) => {
    while (el.childElementCount) {
      el.removeChild(el.children[0]);
    }
  };

  renderCollection = (collection, container) => {
    this.removeAllChildren(container);
    let readerCollection = [];
    let fileNamesCollection = [];

    Promise.all(collection.map(this.generatePreviewData)).then((items) => {

      items.map((item, i) => {
        item.setAttribute("index", i);
        item
          .querySelector(".img-upload__del")
          .addEventListener("click", (e) => {
            collection.splice(i, 1);
            this.renderCollection(collection, container);
          });
        container.appendChild(item);
        const reader = new FileReader();
        reader.readAsDataURL(collection[i]);

        reader.addEventListener("load", (e) => {
          readerCollection.push(reader.result.split(",")[1]);
          fileNamesCollection.push(collection[i].name);
        });
      });

      if (items.length > 1) {
        this.setState({
          fileState: true,
        });
      } else if (items.length === 1 && items.length) {
        this.removeAllChildren(container);
        this.setState({
          fileState: "error",
        });
      } else {
        this.setState({
          fileState: false,
        });
      }

      this.setState({
        formData2: readerCollection,
        formDataName2: fileNamesCollection,
      });

      this.props.updateData("formData2", this.state.formData2);
      this.props.updateData("formDataName2", this.state.formDataName2);
    });
  };

  onChangeFile(e) {
    let fileCollection = [];

    while (fileCollection.length) {
      fileCollection.pop();
    }

    Array.from(e.target.files).map((f) => fileCollection.push(f));
    this.renderCollection(fileCollection, this.imgPreviewImag.current);
  }

  render() {
    const { selectError, selectVisit, fileStateError } = this.state;
    const { stage5Active, docType, prevStep } = this.props;

    const docIcon = (() => {
      switch(docType) {
        case "Сертифікат":
          return <Suspense fallback={<BlueCyrclePreloader width={196} height={140} />}>
            <ActIcon name='certificate' width={196} height={140} />
          </Suspense>;
        case "Червоний акт":
          return <Suspense fallback={<BlueCyrclePreloader width={196} height={140} />}>
            <ActIcon name='red-act' width={196} height={140} />
          </Suspense>;
        case "Синій акт":
          return <Suspense fallback={<BlueCyrclePreloader width={196} height={140} />}>
            <ActIcon name='blue-act' width={196} height={140} />
          </Suspense>;
        case "Зелений акт":
          return <Suspense fallback={<BlueCyrclePreloader width={196} height={140} />}>
            <ActIcon name='green-act' width={196} height={140} />
          </Suspense>;
        case "Інший документ":
          return <Suspense fallback={<BlueCyrclePreloader width={196} height={140} />}>
            <ActIcon name='other-docs' width={196} height={140} />
          </Suspense>;
        default:
          return <Suspense fallback={<BlueCyrclePreloader width={196} height={140} />}>
            <ActIcon name='green-act' width={196} height={140} />
          </Suspense>;
      }
    })();

    return (
      <>
        <div className={"main-form__stage-5 active"}>
          <div className="main-form__title-block">
            {prevStep ? (
              <div onClick={(e) => {
                  e.preventDefault();
                  prevStep();
                }}>
                <BackArrowIcon width={20} height={36} />
              </div>
            ) : (
              ""
            )}

            <div className="title title_md">
              Запит на оцінку земельної ділянки
            </div>
          </div>

          <div className="main-form__content">
            <div className="title title_md-md">
              Вітаємо, це вже останній крок :)
              <br />
              Будь ласка, надайте документ, який підтверджує право власності
            </div>
          </div>

          <div className="img-upload">
            <div className="row">
              <div className="col col_6">
                <label className={selectError && selectVisit ? "require" : ""}>
                  <span>
                    Тип документа<em>*</em>
                  </span>
                  <CustomSelect
                    name="docType"
                    docType={this.props.docType}
                    updateData={this.state.updateData}
                    selectVisit={this.selectVisit}
                    items={[
                      { value: "Оберіть тип документа", id: "hidden" },
                      { value: "Сертифікат", id: 1 },
                      { value: "Червоний акт", id: 2 },
                      { value: "Синій акт", id: 3 },
                      { value: "Зелений акт", id: 4 },
                      { value: "Інший документ", id: 5 },
                    ]}
                  />
                  <span
                    className={
                      selectError && selectVisit
                        ? "require__error error"
                        : "require__error"
                    }
                  >
                    {selectError}
                  </span>
                </label>
                <div
                  className={
                    fileStateError
                      ? "img-upload__triger-wrapper require"
                      : "img-upload__triger-wrapper"
                  }
                >
                  <UploadImg 
                    onClickHandler={this.onChangeFile} 
                    acceptType='.zip,.rar,.7zip,.doc,.pdf,image/*' 
                    title='Завантажити свій документ' 
                  />
                  <span
                    className={
                      fileStateError ? "require__error error" : "require__error"
                    }
                  >
                    {fileStateError}
                  </span>
                </div>

                <div className="img-upload__items">
                  <div ref={this.imgPreviewImag} className="row" />
                </div>
              </div>
              <div className="col col_6">
                <div className="img-upload__info-items img-upload__info-items_small">
                  <div className='d-flex justify-content-end'>
                    <div className="img-upload__info-item">
                      <div className="img-upload__info-item-img">
                        {docIcon}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn-block">
                  <div className="row">
                    <div className="col">
                      {this.validCheck()}
                      <CheckboxBtn 
                        onClickHandler={() => this.setState({consent: !this.state.consent})} 
                        isSelect={this.state.consent}
                        styles='pl-8 fs-14-19 gray'
                        title='Даю згоду на обробку персональних даних'
                        blankLink='./agreement_290621.pdf' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LoadLandDocumentsWnd;