import { useState, useEffect, useMemo } from "react";
import { debounce } from 'debounce';

const XS = "xs";
const SM = "sm";
const MD = "md";
const LG = "lg";
const XL = "xl";

const XS_SIZE = 0;
const SM_SIZE = 560;
const MD_SIZE = 768;
const LG_SIZE = 1200;
const XL_SIZE = 1400;

const MOBILE_SIZE = 560;

const MIN_WIDTH = 375;

const GRID = [
  { v: XL_SIZE, s: XL },
  { v: LG_SIZE, s: LG },
  { v: MD_SIZE, s: MD },
  { v: SM_SIZE, s: SM },
  { v: XS_SIZE, s: XS }
];

const win = typeof window === 'undefined' ? null : window;

const getSize = () => {
  const width = win ? Math.min(win.outerWidth, win.innerWidth) : 0;
  const height = win ? Math.min(win.outerHeight, win.innerHeight) : 0;

  let size = GRID[GRID.length - 1].s;
  for (let i = 0; i < GRID.length; i++) {
    let grid = GRID[i];
    if (width >= grid.v) {
      size = grid.s;
      break;
    }
  }

  return { 
    width, 
    height, 
    size, 
    isMobile: (width <= MOBILE_SIZE || height <= MOBILE_SIZE), 
    scale: width < MIN_WIDTH ? width / MIN_WIDTH : 1 
  };
};

export function useWindowSize() {
  const [screenState, setScreenState] = useState(getSize());

  const resizeHandler = useMemo(() => {
    return debounce(() => {
      setScreenState(getSize());
    }, 200);
  }, []);

  useEffect(() => {
    win.addEventListener('resize', resizeHandler);
    return () => {
      resizeHandler.clear();
      win.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return screenState;
}