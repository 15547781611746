import styles from './btn-style.module.scss';

const SimpleBlueBtn = ({title, onClickHandler}) => {

  return (
    <button className={`mt-20 ${styles.simpleBlueBtn}`} onClick={onClickHandler}>
      <span className='fs-18-24 fw-600 white'>{title}</span>
    </button>
  );
};

export default SimpleBlueBtn;