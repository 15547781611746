
export const homePageQuestions = [
  {
    question: "Про ринок землі",
    answer:
      `Ринок землі в Україні відкрився 01 липня 2021 року після багаторічного мораторію. Це був перший етап реформ, згідно з яким фізичні особи отримали змогу купувати до 100 га «в одні руки». Другий етап реформи дозволив з 01 січня 2024 року купувати землю в розмірі до 10 000 га юридичним особам."
      </br></br>Весь цей час, за винятком двох місяців після повномасштабного вторгнення росії в Україну, ринок землі розвивається.
      </br></br>Поки що кількість угод не зрівнялася з довоєнним періодом, але попри ситуацію в країні на ринку землі присутня стабільна активність.
      </br></br>Ціна земельної ділянки розміром 1 га по Україні на зараз становить 60-80 тис. грн. І ця цифра постійно зростає.`,
  },
  {
    question: "Вартість землі",
    answer:
      "Ціна земельної ділянки розміром 1 га по Україні на зараз становить 60-80 тис. грн. І ця цифра постійно зростає.",
  },
  {
    question: "Чи можна продати пай, якщо він перебуває в оренді?",
    answer:
      "Так, можна. При цьому, згідно зі ст. 9 Закону «Про оренду землі», орендар має переважне право на придбання земельної ділянки. Якщо орендар відмовився від переважного права на придбання земельної ділянки, така ділянка може бути продана будь-якій третій особі.",
  },
  {
    question:
      "Які документи необхідно надати для оформлення продажу землі?",
    answer:
      "Договір купівлі-продажу землі, який підлягає нотаріальному посвідченню. Для здійснення угоди власник земельної ділянки - фізична особа, повинен надати нотаріусу правовстановлюючий документ на землю, паспорт, довідку про присвоєння ідентифікаційного коду та згоду чоловіка/дружини на продаж ділянки, якщо власник перебуває у шлюбі.",
  },
  {
    question:
      "Яким буде розмір орендної плати, коли відкриється ринок землі?",
    answer: "Не нижчий за існуючий.",
  },
  {
    question: "Чи можна продати землю не орендарю?",
    answer: "Так, можна.",
  },
  {
    question:
      "Чи припиниться дія договору оренди після продажу земельної ділянки?",
    answer:
      "Якщо земельна ділянка буде продана не орендарю, з яким підписано договір оренди, новий власник земельної ділянки отримає як права на земельну ділянку, так і обов'язки за договором.",
  },
  {
    question:
      "Чи обов’язково мені продавати пай, якщо всі сусідні паї продано?",
    answer: "Ні, не обов'язково.",
  },
  {
    question: "Чи зможу я продати частину земельного паю?",
    answer:
      "Так, але попередньо необхідно буде провести розділення ділянок (через технічну документацію або проект землевідведення з присвоєння таких ділянок нових кадастрових номерів).",
  },
  {
    question: "Хто буде оплачувати податки у разі продажу землі?",
    answer: "За домовленістю.",
  },
];

export const investorPageQuestions = [
  {
    question: 'Оплата буде проходити в готівковій або в безготівковійні формі? Чи потрібно буде підтвердження джерела походження коштів?',
    answer:
      "Оплата за договором купівлі земельної ділянки. Комісії будуть відбуватися в безготівковій формі на банківський рахунок. Оплата послуг нотаріуса здійснюється в готівковій формі. В рамках покупки землі потрібно надати підтвердження засобів (фінмоніторинг).",
  },
  {
    question: 'У чому перевага роботи з LandInvest?',
    answer: 'Платформа LandInvest повністю супроводжує процес покупки земельної ділянки від імені інвестора, та надає доступ тільки до перевірених паїв',
  },
  {
    question: 'Хто не може купити землю?',
    answer: 'Українську землю не можуть купити іноземці або іноземні компанії. Також не можна продавати землю українським компаніям якими володіють іноземці. Громадяни «країни-агресора» також не можуть купити землю в Україні',
  },
  {
    question: 'Які податки сплачує покупець сільгоспземлі?',
    answer: 'Супутні витрати при оформленні угоди купівлі-продажу - банківська комісія за безготівковий розрахунок за придбаний надів, оплата нотаріальних та реєстраційних послуг, експертна оцінка земельної ділянки, податки та комісію платформи.',
  },
  {
    question: 'Як проходить оплата за землю?',
    answer: 'Згідно норм законодавства, оплата за землю відбуваєтсья лише у безготівковій формі шляхом переказу на банківський рахунок продавця земельної ділянки. LandInvest не отримує гроші за землю, інвестор сплачує напряму продавцю.',
  },
];