import newsPageImg0 from '@this-assets/imgs/news/news-page-img-0.jpg';
import newsPageImg1 from '@this-assets/imgs/news/news-page-img-1.jpg';
import newsPageImg1_2 from '@this-assets/imgs/news/news-page-img-1-2.jpg';
import newsPageImg2 from '@this-assets/imgs/news/news-page-img-2.jpg';
import newsPageImg2_2 from '@this-assets/imgs/news/news-page-img-2-2.jpg';
import newsPageImg3 from '@this-assets/imgs/news/news-page-img-3.jpg';
import newsPageImg3_2 from '@this-assets/imgs/news/news-page-img-3-2.jpg';
import newsPageImg3_3 from '@this-assets/imgs/news/news-page-img-3-3.jpg';
import newsPageImg3_4 from '@this-assets/imgs/news/news-page-img-3-4.jpg';
import newsPageImg4 from '@this-assets/imgs/news/news-page-img-4.jpg';
import newsPageImg4_1 from '@this-assets/imgs/news/news-page-img-4-1.jpg';
import newsPageImg4_2 from '@this-assets/imgs/news/news-page-img-4-2.jpg';
import newsPageImg4_3 from '@this-assets/imgs/news/news-page-img-4-3.jpg';
import newsPageImg5 from '@this-assets/imgs/news/news-page-img-5.jpg';
import newsPageImg6 from '@this-assets/imgs/news/news-page-img-6.jpg';
import newsPageImg7 from '@this-assets/imgs/news/news-page-img-7.jpg';
import newsPageImg8 from '@this-assets/imgs/news/news-page-img-8.jpg';
import newsPageImg9 from '@this-assets/imgs/news/news-page-img-9.jpg';
import newsPageImg10 from '@this-assets/imgs/news/news-page-img-10.jpeg';
import newsPageImg11 from '@this-assets/imgs/news/news-page-img-11.jpg';
import newsPageImg11_2 from '@this-assets/imgs/news/news-page-img-11-2.jpg';
import newsPageImg12 from '@this-assets/imgs/news/news-page-img-12.jpg';
import newsPageImg13 from '@this-assets/imgs/news/news-page-img-13.jpg';
import newsPageImg14 from '@this-assets/imgs/news/news-page-img-14.jpg';
import newsPageImg14_1 from '@this-assets/imgs/news/news-page-img-14-1.jpg';
import newsPageImg14_2 from '@this-assets/imgs/news/news-page-img-14-2.jpg';

export const newsData = [
  {
    id: '14',
    url: '/news-page-14',
    title: 'В Україні відкрили ринок землі для юросіб. Чи варто чекати різкого зростання активності?',
    content: `<div className="title title_small-md">
      <a target="_blank" style="color: #0000ff; font-size: 16px;" href="https://biz.nv.ua/ukr/experts/rinok-zemli-dlya-yuridichnih-osib-shcho-zminilos-skilki-koshtuye-zemlya-novini-ukrajini-50387406.html?code=cd6af98e-607d-44ce-805d-1727429c9855">Джерело</a>
      <p><strong>Перші відчутні результати появи на земельному ринку юридичних осіб побачимо навесні. Чого ще чекати у 2024 році?</strong></p>
      <p>З 1 липня 2021-го, після майже 30-річного мораторію, почав працювати ринок землі в Україні. Це був перший етап реформи, згідно з яким фізичним особам-резидентам дозволялося мати не більше 100 га землі «в одні руки». З 1 січня 2024 року стартував другий етап реформи — ринок землі сільськогосподарського призначення відкрився для юридичних осіб-резидентів України. Маємо факт, що навіть попри воєнний стан ринок землі спрацював доволі позитивно та запланований другий етап реформи відбувся.</p>
      </br>
      </br>
      <p>
          <img style="width:70%; display: block; margin-left: auto; margin-right: auto;" src=${newsPageImg14_1} />
      </p>
      <p><strong>Земля в умовах війни</strong></p>
      <p>Війна завдала ринку землі значної шкоди — сам ринок землі призупинявся з початку війни на два місяці, багато землі наразі під окупацією, багато залишається замінованою, на приведення її у придатний стан підуть роки. Проте ринок землі продовжує існувати та навіть розвиватися. Обумовлено це тим, що земля як інвестиція є доволі стійким об'єктом до руйнувань та швидко відновлювана. Це робить її доволі конкурентною з урахуванням поточної ситуації в країні у порівнянні із, скажімо, інвестиціями в нерухомість. Кількість угод з продажу сільськогосподарської землі збільшується по всіх областях. Як приклад наведу Полтавський регіон. У 2022 році тут в середньому проводилось близько 250 угод на місяць, зараз — близько 450. Змінилися за цей час і підходи до оцінки привабливості землі. До повномасштабного російського вторгнення покупці більше зважали на дохідність земельних ділянок, зараз — на стійкість орендарів, які цю землю обробляють.</p>
      <p><strong>Хто купує землю та за скільки</strong></p>
      <p>На сьогодні значна частка інвесторів представлена працівниками IT-сфери, військовими, лікарями, банкірами. В загальному обсязі такі угоди складають 20−25%. Решта угод — 75% - це викуп землі орендарями на своїх фізичних осіб.</p>
      <p>Збільшення попиту спричинило зростання вартості земельних ділянок. Найбільше здорожчали угіддя на заході країни у Тернопільський та Хмельницький областях — аж на 30% за рік. Ми навіть зафіксували ціновий рекорд: у Тернопільській області сільськогосподарські землі були продані за 129 тис. грн ($3,440) за га. В Сумській вони здорожчали приблизно на 10% - до 55−60 тис. грн ($1,470−1,600) за га.</p>
      <p>Інвесторів найбільше цікавлять угіддя в Полтавській, Тернопільській, Івано-Франківській, Львівській та на півдні Київської області. Вінниця також залишається привабливим для інвесторів регіоном. Деякі з них вже зараз готові купляти землю по $2,5 тис. за га в окремих районах Черкаської області, бо там за їхньою оцінкою найкращий чорнозем. Якість землі в Тернопільській та Сумській областях майже не відрізняється, а от ціни різняться суттєво — впливає фактор війни. Якби не було повномасштабного російського вторгнення, я впевнений, ціни на угіддя були б значно більшими.</p>
      </br>
      <p>
          <img style="width:100%; display: block; margin-left: auto; margin-right: auto;" src=${newsPageImg14_2} />
      </p>
      <p>Оренда землі в хорошій зоні - від Полтави до Тернополя — коштує приблизно $100 за гектар на рік. Це невеликі гроші для орендаря, але досить значні для фермера, земельний банк якого може нараховувати до 100 тисяч га. Щобільше — конкуренція стає жорсткішою, тому ставки постійно зростають, особливо під час укладання нових договорів з пайовиками. Якщо з’являються охочі продати землю в районі, де працює фермер, то він її викупить. Бо краще сьогодні заплатити $2 000 за га, ніж переплачувати завтра. Середня ціна земельної ділянки розміром 1 га по Україні зараз становить 75 тис. грн.</p>
      <p>Ще один цікавий показник як приклад пожвавлення ситуації на ринку земельних угод: на момент запуску ринку землі в Україні працювало три спеціалізовані онлайн платформи, де проводяться земельні торги. Зараз тільки великих платформ вже шість. Платформа LandInvest була серед перших.</p>
      <p>Окрім ринкового ціноутворення, яке формує реальні ціни на землю, існує державна нормативно-грошова оцінка (НГО). Це досить умовний орієнтир, але він відображає поріг, нижче якого земля коштувати не може. Зазвичай земельні паї продаються за цінами значно вищими ніж НГО. Ця оцінка є також індикатором якості ґрунтів, на який може орієнтуватися інвестор при виборі паю — чим кращі ґрунти, тим вища НГО.
      </p>
      <p><strong>Що змінюється у 2024 році</strong></p>
      <p>Головна новація цього року — купувати землю тепер можуть і юридичні особи. «Привласнення» землі завжди мало на меті фіксацію контролю над землею, а також масштабування бізнесу коштом розширення земельного банку. Зняття мораторію цей процес активізувало. Наразі стрімкого зростання активності юридичних осіб чекати не варто, бо питання отримання контролю над «своїми» землями вони й так вирішували через оформлення земель на лояльних фізичних осіб. Але деяке пожвавлення ринку після приходу на нього юридичних осіб все ж таки очікується.</p>
      <p>Результати дії реформи ринок відчує не одразу. У січні-лютому бізнес тестуватиме нові процедури та правила, компанії матимуть справи із фінансовим моніторингом, налагоджуватимуть багато технічних процесів та процедур. Тому прогнозовано перші відчутні результати появи на земельному ринку юридичних осіб побачимо десь навесні.</p>
      <p>Угіддя в першу чергу купуватимуть великі інвестори, які мають серед своїх бенефіціарів українців. Земельні ділянки за прогнозами спеціалістів LandInvest впродовж року зростуть в ціні в середньому на 10−20% в залежності від регіону. А в деяких районах — особливо там, де конкуренція серед агрохолдингів досить велика — ще більше. Як приклад — у Волочиському районі Хмельницької області працюють одразу чотири великі компанії та конкурують між собою за розширення та контроль над земельними ділянками.</p>
      <p><strong>Виклики та прогнози</strong></p>
      <p>Окрім негативного впливу війни, іншим викликом для ринку землі, на мою думку, є дефіцит пропозицій. За два з половиною роки було продано лише 1% від загальної площі сільськогосподарських земель в Україні. Також, досить поганим фактором для інвесторів стає розгалуженість паїв. Інвестор прагне купувати земельні масиви єдиною ділянкою в одній локації. Але це майже неможливо. Бувають виключення як, наприклад, один з районів Чернігівської області в 70 кілометрах від Києва, де за один квартал ми придбали для наших клієнтів в сільській раді рівно 100 гектарів, а до кінця року закрили ще плюс 50. Але це поодинока історія.</p>
      <p>Як ринок землі розвиватиметься у найближчі роки? Залежить від строків завершення війни та кон’юнктури самого агробізнесу — чи зможе він генерувати такі прибутки, як раніше. Від цього залежатиме і розмір орендної плати, яка при позитивному сценарії буде зростати. Звичайно, земля матиме більшу ціну, аніж зараз, і це приваблює інвесторів. Адже знищити цей об'єкт неможливо — угіддя будуть засіватися та приноситимуть прибуток аграрію у будь-якому випадку.</p>
      <p><strong>Висновки</strong></p>
      <p>З одного боку ринок землі знаходиться під великим впливом зовнішніх факторів, найвпливовішим із яких є бойові дії. З іншого — досвід розвитку цього напряму навіть за несприятливих умов та потенційні зовнішні чинники, як то вступ до ЄС та відкриття землі для нерезидентів України, дають привід вже сьогодні вкладати гроші у цей вигідний сектор інвестицій з прогнозами на зростання та прибутковість.</p>
      </div>`,
    imgUrl: newsPageImg14,
    publishedAt: '30.01.2024',
  },
  {
    id: '13',
    url: '/news-page-13',
    title: 'Ринок землі – що змінилося після другого етапу реформи',
    content: `<div className="title title_small-md">
      <p>2024 рік приніс зміни на ринку земельних угод України – з 01 січня можливість придбати паї отримали юридичні особи, які тепер можуть концентрувати до 10 000 га «в одні руки». В очікуванні цієї події будувалися різні прогнози – від незначного пожвавлення ринку до стрімкого зростання кількості угод. Розберемося як ринок дійсно відреагував на цю подію. </p>
      <p>Наразі минуло трохи більше двох тижнів нового року і за цей період відбулося 57 угод юридичних осіб. При цьому самих юросіб було 7. Найбільша кількість угод мала місце в Полтавській області – 51 угода на загальну площу землі 183 га, середня ціна за 1 га при цьому склала приблизно 59 000 грн. Угоди також відбулися у Волинській, Дніпропетровській, Кіровоградській, Тернопільській та Черкаській областях.  Земельні ділянки викупалися орендарями, які ці землі вже обробляють. </p>
      </br>
      <p>
          <img style="width:70%; display: block; margin-left: auto; margin-right: auto;" src=${newsPageImg13} />
      </p>
      </div>`,
    imgUrl: newsPageImg13,
    publishedAt: '18.01.2024',
  },
  {
    id: '12',
    url: '/news-page-12',
    title: 'Говард Баффет: сільське господарство - найшвидший шлях відновлення ВВП України',
    content: `<div className="title title_small-md">
      <p>Говард Баффет – людина, яка з власного досвіду знається на проблемах України. Від початку війни його фонд вже інвестував в Україну понад 500 мільйонів доларів США. Велика частка з цієї суми припала на інвестиції в агросектор: Баффет передавав техніку, сприяв процесу розмінування, відновлював іригаційні системи та ін.  В той час, коли іноземні інвестори тільки обмірковують варіанти та доцільність вкладати кошти в Україну, Говард Баффет вже приймає активну участь в підтримці нашої країни та має практичний досвід інвестування в неї.</p>
      <p>Баффет сам володіє фермерським господарством в США та має багаторічний досвід фермерської роботи:</br>
      «Одна з причин, чому ми зосередились на агрогалузі, полягає в тому, що я займався цим останні 40 років. Це те, в чому я розуміюсь. До того ж я вважаю, що сільське господарство — найшвидший шлях відновлення ВВП України. Це критично важлива галузь.»
      </p>
      <p>
          <img style="width:70%; display: block; margin-left: auto; margin-right: auto;" src=${newsPageImg12} />
      </p>
      <p>Україна вже сьогодні має одного з найвпливовіших інвесторів світу, який свідчить про великий потенціал нашого агросектору. Це є позитивним знаком також для ринку землі – і зараз і в перспективі:</br>
      «Що нам потрібно зробити, так це подивитися на Україну і подумати, куди ми можемо інвестувати прямо зараз? Які напрямки достатньо безпечні? Коли буде повернення? І як нам це зробити? Отже, сільське господарство має певні можливості в цьому напрямку.»
      </p>
      <p>Руслан Яковенко, керівник онлайн-платформи LandInvest, вважає: «Особисто для мене, як для людини, яка зайнята у сфері продажу землі сільськогосподарського призначення, ставлення Баффета до українського агросектору та українського чорнозему є дуже обнадійливим. За його словами, якості нашої землі позаздрили б багато країн світу. Отже, інвестувати в землю України варто, вигідно та надійно. Особливо зараз, коли вартість землі досить невисока та поріг входу досить низький. Земля буде дорожчати, ринок землі буде розвиватися, іноземні інвестори будуть купувати тут землю (після відкриття ринку для них), земля буде приносити прибутки, сільське господарство буде наріжним каменем Української економіки.» </p>
      </div>`,
    imgUrl: newsPageImg12,
    publishedAt: '15.01.2024',
  },
  {
    id: '11',
    url: '/news-page-11',
    title: 'Заземлення. Вхід на ринок землі як ефективний спосіб зняти інвестиційну напругу',
    content: `<div className="title title_small-md">
      <p><strong> Нерухомість? Крипта? Акції іноземних компаній? А&nbsp;як&nbsp;щодо іншої надійної ідеї для інвестування? Земля&nbsp;— один з&nbsp;найстійкіших активів у&nbsp;наші турбулентні часи. </strong></p>
      <p> Нащо конвертувати гривні в&nbsp;гектари? Щоб зберегти свої заощадження. Провідні економіки світу вже кілька років ведуть боротьбу з інфляцією. Україну ця&nbsp;хвиля накрила після початку повномасштабного вторгнення. За&nbsp;останній рік ціни виросли на&nbsp;26,6% — це&nbsp;офіційна статистика <a target="_blank" style="color: #0000ff" href="https://www.ukrstat.gov.ua/Noviny/new2023/zmist/mekp/01/sc_12_u.png">Держстату</a>. Зберігати валюту у&nbsp;готівці чи інвестувати в&nbsp;активи, які у&nbsp;нашому мінливому середовищі залишаються нестабільними,&nbsp;— на&nbsp;сьогодні це&nbsp;не&nbsp;раціонально. Єдина можливість залишитися<span style="margin-right:0.44em;"> </span><span style="margin-left:-0.44em;">«</span>в&nbsp;плюсі»&nbsp;— знайти альтернативні напрямки для вкладання грошей, які&nbsp;б диверсифікували подібні ризики.</p>
      <p>
          <img style="width:70%; display: block; margin-left: auto; margin-right: auto;" src=${newsPageImg11_2} />
      </p>
      <p><a target="_blank" style="color: #0000ff" href="https://www.epravda.com.ua/columns/2022/05/18/687187/" rel="nofollow noopener">Опитування</a>, проведені «УкрІнвестКлубом» навесні 2022 року показали, що&nbsp;56% українців з&nbsp;реальним досвідом інвестування вбачають найбільші перспективи у&nbsp;агросфері. Дійсно, попри війну агросектор залишається надійною опорою економіки України. Інвестуючи в&nbsp;землю ви&nbsp;не тільки отримуєте дохідність, а&nbsp;й&nbsp;робите внесок у&nbsp;зміцнення тилу і наближення перемоги.</p>
      <p><a target="_blank" style="color: #0000ff" href="https://minagro.gov.ua/zemelna-reforma/interaktivnij-zvit-po-rinku-zemli">За&nbsp;даними Мінагрополітики</a> станом на&nbsp;05.04.2023, від запуску ринку землі в&nbsp;Україні було зареєстровано більше 157 тис. правочинів, якими охоплено 352,5 тис. га. Понад 52 тис. земельних угод загальною площею 99,5 тис. га&nbsp;укладено протягом воєнного року. Це&nbsp;й&nbsp;не дивно! Земля&nbsp;— швидковідновлюваний актив. Більше частину ділянок, де&nbsp;ще&nbsp;торік велися активні бойові дії, цьогоріч планують засіяти. Чого не&nbsp;скажеш про зруйновану нерухомість, наприклад.</p>
      <blockquote class="code" style="border: 3px solid #fff100; border-bottom-left-radius: 12px; border-right: none; border-top-left-radius: 12px; color: #666; display: block; float: left; font-family: var(--font-sans); font-size: 20px; font-style: italic; line-height: 1.1; margin: 0 30px 20px 0; padding: 25px 0 25px 25px; position: relative; width: 40%">Спеціалісти онлайн-платформи LandInvest допоможуть обрати перспективну земельну ділянку за&nbsp;кращою ціною та&nbsp;подбати про ваш інвестиційний спокій.</blockquote>
      <p>Чому не&nbsp;варто відкладати інвестиції у&nbsp;землю на&nbsp;«після перемоги»? 2023 рік&nbsp;— найкращий час, щоб інвестувати в&nbsp;землю. По-перше, низький поріг входу на&nbsp;ринок. Станом на&nbsp;початок березня цього року середня ціна 1 га&nbsp;землі в&nbsp;Україні становила 39 тис. грн. Це&nbsp;середній розмір земельної ділянки, тобто повноцінна інвест-одиниця. Для порівняння: середня вартість метру квадратного в&nbsp;новобудовах Києва оцінюється в&nbsp;61,2 тис. грн. По-друге, висока дохідність. Із того&nbsp;ж 1 га&nbsp;— від 6% до&nbsp;11% річних. Усе залежить від нормативно грошової оцінки земельних ділянок, втім за&nbsp;останні 15 років орендна плата за&nbsp;землю виросла з&nbsp;1,5% до&nbsp;12−15% від НГО. По-третє, низька конкуренція на&nbsp;ринку землі. Поки&nbsp;що. Адже з&nbsp;2024 року, коли землю зможуть купувати юридичні особи, попит на&nbsp;неї зросте в&nbsp;рази. А&nbsp;з&nbsp;ним і вартість активів.</p>
      <p>Досвід європейських країн, зокрема і сусідньої Польщі,&nbsp;— зростання стартової ціни <a target="_blank" style="color: #0000ff" href="https://biz.nv.ua/ukr/experts/vidkrittya-rinku-zemli-v-polshchi-visnovki-dlya-ukrajinciv-ostanni-novini-50115118.html">у&nbsp;6 разів</a> ще&nbsp;на&nbsp;етапі кандидатства, а&nbsp;за&nbsp;перші 10 років після відкриття ринку землі - ще більше. Прогнозовано, що&nbsp;наданий Україні статус кандидата в ЄС та&nbsp;подальші кроки до євроінтеграції прискорять ріст цін на&nbsp;земельні активи й&nbsp;у&nbsp;нас. Вигідно перепродати землю та&nbsp;примножити заощадження можна буде в&nbsp;будь-який момент. Ось чому варто інвестувати в&nbsp;земельні ділянки вже зараз.</p>
      <p>А&nbsp;як&nbsp;щодо гарантій? Земля офіційно працюватиме на&nbsp;вас і приноситиме стабільний пасивний дохід, якщо здати її в&nbsp;оренду надійній агрокомпанії. Такі орендарі продовжують виконувати свої зобов’язання навіть в&nbsp;жорстких умовах воєнного стану. Залишається знайти команду, яка зможе оформити вам землю у&nbsp;власність<span style="margin-right:0.44em;"> </span><span style="margin-left:-0.44em;">«</span>під ключ». Спеціалісти онлайн-платформи <a style="color: #0000ff" href="https://landinvest.ua/" rel="nofollow noopener" target="_blank" pubtag="0">LandInvest</a> допоможуть обрати перспективну земельну ділянку за&nbsp;кращою ціною та&nbsp;подбати про ваш інвестиційний спокій.</p>
      <p>
        <a style="color: #0000ff" href="https://biz.nv.ua/ukr/markets/koli-yak-i-de-investuvati-v-zemlyu-v-ukrajini-ekspertni-poradi-50318348.html" target="_blank">https://biz.nv.ua/ukr/markets/koli-yak-i-de-investuvati-v-zemlyu-v-ukrajini-ekspertni-poradi-50318348.html</a>
      </p>
      </div>`,
    imgUrl: newsPageImg11,
    publishedAt: '22.04.2023',
  },
  {
    id: '0',
    url: '/news-page-0',
    title: 'Інвестиції в землю – найвигідніші з доступних на сьогодні українцям капіталовкладень',
    content: `<div className="title title_small-md">
          Вітаю! На зв’язку Руслан Яковенко, керівник проєкту LandInvest. Це – онлайн-платформа проведення чесних земельних торгів, швидкої купівлі та прозорого продажу земельних ділянок. Ми розпочали свою роботу одночасно зі зняттям мораторію на землю. Пройшло пів року, і сьогодні я хочу поділитися спостереженнями за функціонуванням ринку землі з погляду інвестора, та показати вам, що інвестиції в землю – найвигідніші з доступних на сьогодні українцям капіталовкладень.
        </div>
        </br>
        <p>
          Міфи, якими ринок землі обріс ще до свого відкриття, поступово розвінчуються. Ніхто не відбирає українські чорноземи силою і не вивозить їх за кордон. Все склалося навпаки. 1 липня 2021 року власники паїв нарешті отримали право розпоряджатися ділянками на свій розсуд, агровиробники – шанс вийти з тіні, щоб працювати офіційно й абсолютно законно, а решта громадян – можливість інвестувати кошти в новий ліквідний актив.
        </p>
        <p>
          <img style="width:70%; display: block; margin-left: auto; margin-right: auto;" src=${newsPageImg0} />
        </p>
        <p>
          Прогнози щодо масового продажу також не справдилися. Влітку в Україні було офіційно оформлено 32,1 млн га землі, 27,6 млн га – паї. Станом на кінець року з них продано лише 182 тис. га. Це свідчить про те, що люди усвідомлюють цінність наявних у них активів і очікують найбільш сприятливих умов, найвигідніших цінових пропозицій. Хоча попит на землю невпинно зростає. Якщо за перший місяць було укладено 1219 договорів купівлі-продажу ділянок площею від 1 га, то за пів року кількість угод зросла до 28 348.
        </p>        
        <p><br>Ключові цифри ринку землі 2021 по Україні:<br>
          • Середня ціна за 1 га – 38 478 грн<br>
          • Максимальна ціна операції – 5 035 000 грн
        </p>
        <p>
          Оскільки ціни регулюються ринковими механізми, за прогнозами експертів вартість землі, як і попит, динамічно зростатиме. В цілому вартість землі залежить від регіону, кліматичних особливостей, характеристики ґрунтів. Платформа LandInvest надає інвесторам доступ до унікального каталогу земельних ділянок України. В нашому реєстрі можна знайти пропозиції, які точно підійдуть за вартістю та регіональним розташуванням.
        </p>
        <p>
          Змоделюємо ситуацію. Ви – інвестор, який придбав землю. Що далі? Ви можете самостійно обробляти ділянку, здавати її в оренду або почекати і перепродати, тобто отримати разовий дохід або регулярний додатковий заробіток. Отже, купуючи землю ви, в будь-якому випадку, убезпечуєте себе від інвестиційних ризиків, зокрема, фінансових втрат.
        </p>
        <p><br>ТОП областей за кількістю проданих ділянок:<br>
          • Харківська – 26 573 га<br>
          • Кіровоградська – 18 301 га<br>
          • Дніпропетровська – 16 346 га<br>
        </p>
        <p>ТОП інвестиційно привабливих областей за показником вартості землі та орендної платою:<br>
          • Полтавська<br>
          • Черкаська<br>
          • Київська<br>
        </p>
        <p>
          У LandInvest – своя аналітика. За цей час до нас звернулися 17,5 тис. пайовиків, які розпоряджаються 44 тис. га. Через платформу найчастіше продають та купують у Чернігівській та Полтавській областях, а найбільші за площею паї – у Харківській.
        </p>
        <p><br>
          <strong>Як ми працюємо?</strong><br>
          Користувачі залишають заявку на сайті, ми перевіряємо та оцінюємо ділянку, потім допомагаємо оформити договір. Ключовими учасниками земельних відносин виступають продавець, покупець та нотаріус. Ми просто допомагаємо сторонам швидше знайти спільну мову. В середньому процес триває 10 днів.<br>Що стимулюватиме розвиток ринку землі в Україні? Вигідні програми кредитування під заставу землі. Сподіваюся, питання вирішиться до 1 січня 2024 року, коли можливість набувати права власності на землю отримають юридичні особи.<br>А поки великі гравці не вступили у гру, раджу фізичним особам скористатися своїми правами на придбання 100 га. Інвестуючи у землю сьогодні ви точно зможете почуватися впевненими у завтрашньому дні!
        </p>
        <p>
          <a style="color: #0000ff" href="https://openagribusiness.kernel.ua/kernel-news/investytsiyi-v-zemlyu-najvygidnishi-z-dostupnyh-na-sogodni-ukrayintsyam-kapitalovkladen/" target="_blank">https://openagribusiness.kernel.ua/kernel-news/investytsiyi-v-zemlyu-najvygidnishi-z-dostupnyh-na-sogodni-ukrayintsyam-kapitalovkladen/</a>
        </p>
      </div>`,
    imgUrl: newsPageImg0,
    publishedAt: '24.01.2022',
  },
  {
    id: '10',
    url: '/news-page-10',
    title: 'Інвестиції у землю «під ключ»: купівля/продаж угідь через оновлену платформу LandInvest',
    content: `<div className="title title_small-md">
      <p>Серед проблем інвестування в земельні активи в Україні учасники ринку називають відсутність зручних сервісів. Комплексний супровід угод — від пошуку та оцінки земельних ділянок до підписання контрактів — пропонує онлайн-платформа LandInvest.</p>
      <p>Сервіс уже здобув визнання в експертному середовищі як один із найкращих проєктів із продажу землі. Щодня на <span style="color: #0000ff;"><a style="color: #0000ff;" href="https://my.land-invest.com.ua/register?utm_source=smi" target="_blank">Land-Invest.com.ua</a> </span>надходять сотні нових заявок.</p>
      <p>Платформу створено у форматі зручного майданчика для комунікацій між продавцями та покупцями. Землевласники отримують безкоштовну допомогу з підготовки ділянок для подальшого продажу, а покупці — пакет послуг для безпечного інвестування.</p>
      <p>Враховуючи потреби користувачів, команда<span style="color: #0000ff;"> <a style="color: #0000ff;" href="https://my.land-invest.com.ua/register?utm_source=smi" target="_blank">LandInvest</a> </span>на чолі з Русланом Яковенком розширила функціонал онлайн-платформи, яка з інформаційної системи перетворилася на повноцінний маркетплейс. Оновлений проєкт пропонує персоніфікований супровід угод із купівлі земельних угідь «під ключ».</p>
      <p>
        <img style="width:70%; display: block; margin-left: auto; margin-right: auto;" src=${newsPageImg10} />
      </p>
      <p>Щоб скористатися функціоналом платформи, яку вже інтегровано в державні реєстри та сервіси ідентифікації, необхідно пройти реєстрацію. Через особистий кабінет інвестор отримує доступ до інструментів для підготовки, реалізації та контролю угод.</p>
      <p><strong>N.B.</strong></p>
      <p>До послуг покупців — постійно оновлюваний земельний каталог із перевіреними ділянками: тими, що продаються, і тими, що готуються для передачі на торги. Сортувати лоти за ключовими параметрами можна за допомогою системи фільтрів. Кожен інвестор сам вибирає зручний формат роботи із системою: можна самостійно керувати процесом та налаштуваннями чи обрати комплексний автоматизований супровід, який передбачає підбір індивідуальних варіантів інвестування та ведення процесу реалізації угоди «під ключ».</p>
      <p>
        <a style="color: #0000ff" href="https://agroportal.ua/news/ukraina/investitsii-v-zemlyu-pod-klyuch-pokupka-prodazha-ugodii-cherez-obnovlennuyu-platformu-landinvest" target="_blank">https://agroportal.ua/news/ukraina/investitsii-v-zemlyu-pod-klyuch-pokupka-prodazha-ugodii-cherez-obnovlennuyu-platformu-landinvest</a>
      </p>
      </div>`,
    imgUrl: newsPageImg10,
    publishedAt: '08.09.2021',
  },
  {
    id: '9',
    url: '/news-page-9',
    title: 'Продати земельну ділянку можна легко та безпечно з онлайн-платформою LandInvest (PR)',
    content: `<div className="title title_small-md">
      <p>Команда онлайн-платформи <a style="color: #0000ff" href="https://land-invest.com.ua/?utm_source=news" target="_blank">LandInvest</a> створила можливості для продажу земельних ділянок. Тепер землевласники можуть перевірити свою ділянку, одразу подати заявку на її продаж за ринковою ціною й отримати гроші протягом 5 днів, а інвестори – знайти для себе найбільш вигідні пропозиції.</p>
      <p>За допомогою платформи LandInvest та <a style="color: #0000ff" href="https://chats.viber.com/landinvest" target="_blank">однойменного Viber-боту</a> землевласники можуть швидко та безкоштовно перевірити, чи зареєстрована їхня ділянка в державних реєстрах. Витяги з реєстрів необхідні, по-перше, для захисту своєї власності від шахраїв, по-друге, для здійснення будь-яких юридичних операцій із землею.</p>
      <p>Платформу і Viber-бот LandInvest було розроблено, щоб допомогти власникам землі розібратися у правилах, які набули чинності 1 липня після відкриття ринку землі. Адже чимало інформації є суперечливою та навіть маніпулятивною. Тож автори зібрали аналітику, перевірені новини, відповіді на найпоширеніші питання на одній платформі. "За час роботи LandInvest партнерами платформи стали аграрні компанії, зокрема Kernel. Крім того, ми отримали тисячі позитивних відгуків від землевласників. Більшість цікавилася, як продати ділянку, куди звернутися, як не натрапити на шахраїв та чи не плануємо створити майданчик для продажу ділянок? Враховуючи запит землевласників, команда проєкту вирішила трансформувати платформу в маркетплейс, тому відтепер за допомогою LandInvest можна і продати, і купити землю", — розповідає Руслан Яковенко, керівник проєкту LandInvest.</p>
      <p>
        <img style="width:70%; display: block; margin-left: auto; margin-right: auto;" src=${newsPageImg9} />
      </p>
      <p>Сьогодні власники земельних ділянок разом з LandInvest можуть безкоштовно, швидко та зручно перевірити ділянку в державних реєстрах, наявність обтяжень, отримати справедливу оцінку вартості земельної ділянки на основі даних нормативної грошової оцінки та коефіцієнтів родючості землі в режимі онлайн. Землевласникам більше не доведеться вишиковуватися в чергу до різних державних інстанцій, днями очікувати необхідні документи та платити за них кошти. Всім, хто хоче продати свою ділянку, LandInvest гарантує прозорість проведення угоди відповідно до законодавства України, повний супровід угоди за рахунок платформи, можливість контролювати та відстежувати процес проходження угоди через особистий кабінет на сайті.</p>
      <p>Водночас покупці земельних ділянок (інвестори) за допомогою LandInvest отримають доступ до каталогу перевірених земельних ділянок по всій території України, можливість отримання персонального супроводу угоди та підбір індивідуальних варіантів інвестування. Крім того, вони матимуть інструменти для перевірки та ідентифікації власників і продавців земельних ділянок. Сам процес проходження угоди вони також зможуть контролювати та відстежувати в особистому кабінеті.</p>
      <p>Онлайн-платформа LandInvest інтегрована з сервісами ідентифікації (BankID, ДіяПідпис), всіма державними реєстрами та базами даних для перевірки земельних ділянок. Вона гарантує користувачам автоматизований процес оцінки земельної ділянки, швидкий та високопрофесійний супровід угоди купівлі-продажу.</p>
      <p>
        <a style="color: #0000ff" href="https://gazeta.ua/articles/economics/_prodati-zemelnu-dilyanku-mozhna-legko-ta-bezpechno-z-onlajnplatformoyu-landinvest-pr/1044052" target="_blank">https://gazeta.ua/articles/economics/_prodati-zemelnu-dilyanku-mozhna-legko-ta-bezpechno-z-onlajnplatformoyu-landinvest-pr/1044052</a>
      </p>
      </div>`,
    imgUrl: newsPageImg9,
    publishedAt: '23.07.2021',
  },
  {
    id: '1',
    url: '/news-page-1',
    title: 'Ринок землі: чому він вигідний пайовикам, фермерам і державі?',
    content: `<div className="title title_small-md">
          З 1 липня 2021 року запрацює ринок землі. Українці зможуть
          купувати та продавати земельні паї. Це важливий крок для
          розвитку економіки та відновлення соціальної справедливості:
          конституційне право громадян України вільно розпоряджатися
          власним майном порушувалося протягом десятиліть.
        </div>

        <p>
          <strong>Як працюватиме ринок землі?</strong>
        </p>
        <p>
          Ринок поширюватиметься виключно на землі
          сільськогосподарського призначення. На першому етапі, з 1
          липня 2021 року, купувати і продавати землю зможуть тільки
          громадяни України. Важлива умова: площа ділянки не має
          перевищувати 100 га. На другому етапі, з 1 січня 2024 року,
          доступ до ринку отримають українські компанії. Одна юридична
          особа зможе мати у власності не більше 10 тис. га. Іноземні
          особи та підприємства не зможуть купувати землю, допоки
          українці не проголосують за це на референдумі.
        </p>
        <p>
          <span>
            Закон забороняє купувати землю громадянам країни-агресора і
            їхнім компаніям, а також купувати землю ближче ніж 50 км до
            державних кордонів. На ці дві норми результати референдуму
            не поширюватимуться.
          </span>
        </p>
        <p>
          <img src=${newsPageImg1_2} />
        </p>
        <p>
          <strong>Які переваги отримають пайовики?</strong>
        </p>
        <p>
          Якщо раніше працював сірий ринок землі і пайовики продавали
          свої ділянки за безцінь, то тепер вони матимуть чітку
          процедуру продажу, захищену законом ціну і реальне зарахування
          коштів на рахунок. Загалом земельними паями в Україні
          володіють близько 7 мільйонів українців. Переважна більшість з
          них – люди похилого віку, які не мають ані здоров’я, ані
          грошей обробляти свою ділянку самостійно. Єдиний спосіб
          отримати з неї хоч якусь користь – здавати в оренду. А що
          робити одиноким бабусям і дідусям, у яких немає спадкоємців?
          Вже майже 1,5 млн пайовиків померли, не отримавши за них
          жодної винагороди. Натомість продаж – це можливість отримати
          реальні гроші на лікування, покращення житлових умов, гідну
          старість.
        </p>
        <p>
          <span>
            Не варто також забувати, що не всі землевласники мешкають в
            селі. Чимало молодих людей вже отримали паї у спадок і зараз
            можуть продати їх та купити житло в місті, автомобіль чи
            оплатити навчання у вищому навчальному закладі. <br />
            Серйозні переваги отримають і ті 6% пайовиків, які самі
            працюють на своїй землі. Тепер вони зможуть використати
            ділянку як заставу і отримати кредит на розвиток бізнесу:
            купити сучасну техніку, якісне насіння та засоби захисту
            рослин.
          </span>
        </p>
        <p>
          <strong>Значення ринку для держави</strong>
        </p>
        <p>
          Україна останньою в Європі відкриває ринок землі. Інші країни
          давно пройшли цей шлях і отримали з того прибутки. В
          середньому в країнах з ліберальним ринком землі ВВП на душу
          населення на 143% вищий, ніж в країнах, де він працює з
          обмеженнями чи не працює взагалі. У Болгарії, наприклад, у
          власності держави лише 2% сільськогосподарських земель, у
          Румунії – 6%, у Нідерландах – 10%, а у Швейцарії вони на 100%
          приватні. Громадяни всіх цих країн заможніші, ніж українці.
        </p>
        <p>
          <span>
            Київська школа економіки
            <a
              href="https://kse.ua/ua/community/stories/yak-pratsyuvatime-ukrayinskiy-rinok-zemli/"
              target="_blank"
            >
              проаналізувала
            </a>
            міжнародний досвід та більше ніж 35 можливих сценаріїв ринку
            землі в Україні. Її висновок – обмеження та втручання
            держави нічого, крім корупції, в економіці не створюють.
            Дійсно ліберальний ринок землі здатен забезпечити Україні
            зростання ВВП більше ніж на 3%.
          </span>
        </p>
        <p>
          Тож насправді ринок землі потрібен не великим агровиробникам,
          а малим і середнім, щоб розвивати сільське господарство,
          інвестувати у розвиток інфраструктури й бути впевненими: це
          твоя земля і завтра її ніхто не відбере. Він також необхідний
          пайовикам. Адже бути господарем на своїй землі означає мати
          право розпоряджатися нею на власний розсуд: обробляти її
          самотужки, залишити дітям, продати, обміняти чи здавати в
          оренду за справедливою ринковою ціною.
        </p>
      </div>`,
    imgUrl: newsPageImg1,
    publishedAt: '01.11.2021',
  },
  {
    id: '2',
    url: '/news-page-2',
    title: 'Ринок землі для пайовиків. Три обов’язкових кроки для продажу ділянки',
    content: `<div className="title title_small-md">
          З 1 липня власники паїв можуть вільно розпоряджатися своїми
          земельними ділянками. Продовжити надавати в оренду, продавати
          її або брати під них банківські кредити і розпочинати власну
          справу. Наразі близько 5-7% землевласників роздумують над
          продажем. Тож варто дати відповіді на ключові питання: чи
          варто продавати, коли саме продавати і як до цього
          підготуватися?
        </div>

        <p>
          Продавати чи не продавати ділянку — вирішувати лише власнику.
          Але не може не тішити, що такий вибір в українців нарешті
          з’явився. На думку експертів, першими паї продаватимуть
          мешканці міст. Як правило, їхні ділянки отримані у спадок,
          вони або простоюють, або перебувають в оренді. Тож якщо
          власник не планує переїжджати в село і займатися
          сільгоспвиробництвом, продаж є логічним варіантом. Скільки ж
          можна буде виручити і як підготуватися до продажу ділянки?
        </p>
        <p>
          <strong>
            Крок перший. Перевірка Реєстру речових прав і нормативна
            грошова оцінка
          </strong>
        </p>
        <p>
          <a
            href="https://kap.minjust.gov.ua/services?product_id=1"
            target="_blank"
          >
            Реєстр речових прав на нерухоме майно
          </a>
          має містити відомості про вас як про власника земельної
          ділянки. Ці дані надає Міністерство юстиції. Якщо вас у
          Реєстрі немає, юристи по земельному праву радять звернутися у
          ЦНАП і подати до державного реєстратора правовстановлюючі
          документи на ділянку для реєстрації в цьому реєстрі.
          Підтвердженням вашої реєстрації має стати виданий на руки
          витяг з Реєстру речових прав на нерухоме майно. Водночас якщо
          ви маєте правовстановлюючий документ, в якому відсутній
          кадастровий номер (сертифікат, червоний державний акт), то вам
          потрібно виготовляти документацію із землеустрою для
          присвоєння цій земельній ділянці кадастрового номеру та
          подальшої реєстрації в Реєстрі.
        </p>
        <p>
          <span>
            Перед продажем обов’язково дізнайтеся нормативну грошову
            оцінку вашої ділянки. Дані про це є на
            <a href="https://ngo.land.gov.ua/" target="_blank">
              Порталі нормативної грошової оцінки по всіх областях
              України
            </a>
            . Продавати землю дешевше цієї вартості заборонено законом.
          </span>
        </p>
        <p>
          <img src=${newsPageImg2_2} />
        </p>
        <p>
          <strong>Крок другий. Для орендодавців</strong>
        </p>
        <p>
          Якщо ви вирішили продати ділянку, яка перебуває в оренді,
          перевірте реєстрацію договору оренди в Державному реєстрі
          речових прав. Якщо договір не зареєстрований, можуть виникнути
          судові спори.
        </p>
        <p>
          <span>
            *Важлива інформація для орендарів. У разі зміни власника
            ділянки, права орендаря за законом захищені і зберігаються в
            повному обсязі. Новий власник бере на себе всі договірні
            зобов'язання продавця і орендаря — це обов’язково має бути
            прописано в договорі купівлі-продажу.
          </span>
        </p>
        <p>
          <strong>Крок третій. Готовність до Угоди</strong>
        </p>
        <p>
          Фахівці одностайно радять власнику земельної ділянки
          звернутися до перевіреного нотаріуса для отримання переліку
          необхідних документів та правильної підготовки до укладення
          угоди.
        </p>
      </div>`,
    imgUrl: newsPageImg2,
    publishedAt: '01.11.2021',
  },
  {
    id: '3',
    url: '/news-page-3',
    title: 'Ринок землі: чому він вигідний пайовикам, фермерам і державі?',
    content: `<div className="title title_small-md">
          Важко знайти явище більш політизоване та демонізоване, ніж
          ринок землі. Воно і не дивно, адже довгі роки цю тему
          використовували для спекуляцій і залякування. Україна — перш
          за все аграрна країна. Для нас земля має чи не сакральне
          значення, а її втрата в національній свідомості дорівнює
          втраті дому і державності.
        </div>
        <p>
          Політики не могли цим не скористатися. Вони перетворили
          національну гордість в елемент політичної боротьби, інструмент
          шантажу і, звісно, джерело власного заробітку. Однією рукою
          вони подовжували славнозвісний мораторій, а іншою — множили
          корупцію, крали в держави землі та за безцінь продавали їх на
          тіньовому ринку. Тож скасування мораторію та запровадження
          офіційного обігу сільськогосподарською землі для них — втрата
          грошей і впливу. Цей ризик перетворив вчорашніх політичних
          опонентів на союзників і міфотворців. Вони поширюють фейки,
          щоби налякати українців і їхніми руками зірвати реформу.
        </p>
        <p>
          <strong>Міф 1. Обезземелення селян</strong>
        </p>
        <p>
          Найпоширеніша страшилка, що не має жодного логічного
          обґрунтування. Тільки власник ділянки має право нею
          розпоряджатися: обробляти самотужки, продовжити здавати в
          оренду, залишити у спадок дітям, продати чи використати як
          заставу під банківський кредит. Ніхто не зможе змусити людину
          продати пай проти її волі. Завдання реформи — дати
          землевласникам право вільно розпоряджатися своєю землею. Крім
          того, варто пам’ятати: ринок стосується тільки
          сільськогосподарської землі. Державну і комунальну землю
          продавати заборонено.
        </p>
        <p>
          <img src=${newsPageImg3_2} />
        </p>
        <p>
          <strong>Міф 2. Землю скуплять іноземці</strong>
        </p>
        <p>
          Громадянам інших країн заборонено купувати землю, поки
          українці не дадуть на це згоду під час всеукраїнського
          референдуму. Це позиція Президента і Уряду України. Ринок
          землі запроваджуватиметься в два етапи. На першому, з 1 липня
          2021 року, купувати і продавати землю зможуть виключно фізичні
          особи – громадяни України з обмеженням у 100 га «в одні руки»
          На другому, з 1 січня 2024 року, доступ до ринку отримають
          українські юридичні особи.
        </p>
        <p>
          <strong>Міф 3. Землю скуплять за копійки</strong>
        </p>
        <p>
          На ліберальному ринку вартість будь-якого товару формує попит.
          Чим більше охочих придбати ділянку, тим більше грошей зможе
          виручити продавець. Чим краще розташування, кліматичні умови,
          показники родючості, тим вище ціна. Законом гарантовано, що
          мінімальна ціна при продажу землі не повинна бути нижче її
          нормативної грошової оцінки. Водночас держава забезпечує
          прозорий моніторинг у реєстрах ціни продажу земель. Також
          розрахунок при проведенні продажу земель здійснюватиметься
          виключно в безготівковій формі, що гарантує продавцям
          отримання ринкової ціни за землю.
        </p>
        <p>
          <strong>Міф 4. Нові власники спустошать землі</strong>
        </p>
        <p>
          Зазвичай, ми дбаємо про своє майно ретельніше. Це правило
          поширюється і на землю. Власник як ніхто зацікавлений в тому,
          щоб його земля була родючою. В іншому випадку він просто не
          зможе зібрати хороший врожай або вигідно продати ділянку.
          Окрім цього, не можна придбати сільськогосподарську землю і
          побудувати на ній готель. Земля може використовуватися
          виключно за цільовим призначенням, тобто для потреб сільського
          господарства. Ці позиції закріплені на рівні Земельного
          кодексу України та забезпечуються дотриманням цих норм
          відповідними контролюючими органами.
        </p>
        <p>
          <img src=${newsPageImg3_3} />
        </p>
        <p>
          <strong>
            Міф 5. Ринок відкривають в інтересах агрохолдингів
          </strong>
        </p>
        <p>
          Вигодонабувачем ринку землі є перш за все пайовики. Для них
          він відкриває можливість за бажанням продати свій пай за
          ринковою ціною, незалежно від того, перебуває ділянка в оренді
          чи ні.
        </p>
        <p>
          <strong>Міф 6. Ринок землі знищить робочі місця</strong>
        </p>
        <p>
          За оцінкою Українського інституту майбутнього, запуск ринку
          землі залучить $3 млрд інвестицій та створить близько 350 тис.
          робочих місць. Можливість стати власником землі — важлива
          умова для іноземних інвесторів. Ринок сприятиме створенню
          нових підприємств і фермерських господарств. Це стане стимулом
          для сільської молоді та заробітчан залишатися в Україні та
          працювати на рідній землі.
        </p>
        <p>
          <strong>
            Міф 7. Дрібні фермери не зможуть придбати землю
          </strong>
        </p>
        <p>
          Зможуть. Фізичні особи з 1 липня 2021 року, юридичні — з 1
          січня 2024 року. Крім того, орендарю належатиме пріоритетне
          право викупити ділянку. Тобто якщо сільгоспвиробник має
          офіційний договір, обробляє землю і сплачує оренду, саме він
          буде перший у черзі в разі продажу.
        </p>
        <p>
          <img src=${newsPageImg3_4} />
        </p>
        <p>
          <strong>Міф 8. Україна залишиться без хлібу</strong>
        </p>
        <p>
          Противники ринку землі вважають, що нові землевласники почнуть
          вивозити врожаї за кордон і в Україні настане продовольча
          криза. Це неправда. По-перше, право власності на землю немає
          жодного відношення до обсягів експорту. Це питання державного
          регулювання і митниці. По-друге, протягом останніх 10 років
          наша країна і без ринку експортує агропродукції в 4 рази
          більше, ніж імпортує. Нестачі продовольства ми при цьому не
          відчуваємо. Якщо Україна вироблятиме більше сільгосппродукції,
          зможе більше експортувати. Однак це не означає, що українці
          залишаться без хлібу. Це означає тільки одне — більше валютної
          виручки та більше грошей в держбюджеті.
        </p>
        <p>
          <strong>
            Міф 9. Ринок небезпечний для незалежності і територіальної
            цілісності
          </strong>
        </p>
        <p>
          Офіційний обіг землі не становить загрози національній
          безпеці. Нагадаємо, що іноземці не матимуть доступу до ринку.
          Окрема заборона стосується громадян РФ та їхніх юридичних
          осіб. Крім того, закон прямо забороняє продаж будь-яких
          ділянок у 50-кілометровій прикордонній зоні. Тому заяви про
          те, що іноземці скуплять землі біля кордонів і проведуть серед
          місцевих мешканців «референдуми про відокремлення» від
          України, є маніпуляцією.
        </p>
        <p>
          <span>
            Необхідно зважати, що у медіа про ринок землі домислів і
            страшилок більше, аніж фактів. Насправді, ринок – це не про
            розпродаж, а про право кожного громадянина вільно
            розпоряджатися своїм майном. Так, як ми розпоряджаємося
            автівками чи квартирами. Ніхто не змушує землевласників
            продавати ділянки, їм лише надають таку опцію. Тому не існує
            француза, німця чи американця, який завтра привласнить пай
            українця. Не вірте гучним заголовкам, думайте, кому вигідна
            паніка, і, приймаючи будь-яке рішення щодо своєї ділянки,
            проконсультуйтеся з фаховим юристом.
          </span>
        </p>
      </div>`,
    imgUrl: newsPageImg3,
    publishedAt: '01.11.2021',
  },
  {
    id: '4',
    url: '/news-page-4',
    title: 'Кейси для України. Як ринок землі працює у світі',
    content: `<p>
          Мораторій на продаж сільськогосподарської землі продовжували 7
          разів. Замість того, щоб переймати досвід кращих і багатіти,
          Україна — країна в самісінькому центрі Європи — до останнього
          часу залишилася в одній компанії з такими «прогресивними»
          державами як Конго, Північна Корея, Таджикистан, Куба,
          Венесуела. Поки українська влада поколіннями укорінювала
          радянські наративи про «землю колгоспам», втрачаючи час і
          можливості, інші економіки стрімко капіталізувалися та
          залучали інвестиції.
        </p>
        <p>
          Однак 1 липня Україна таки відкриє свій ринок землі і зробить
          ще один крок назустріч цивілізованому світові. Досвід тих, хто
          вже пройшов цей шлях, стане у нагоді. У кожній країні Америки
          та Європи, у наших найближчих сусідів своя модель ринку.
          Універсального рецепту не існує, але є загальний висновок —
          чим більше свобод, тим більше країна отримує грошей.
        </p>
        <p>
          <strong>США і Канада</strong>
        </p>
        <p>
          У США ринок землі існує півтора століття. Найбільші
          землевласники в цій країні — фермери. Їм належить 89%
          сільгоспземель. Ціну на землю регулює сам ринок. Станом на
          2019 рік, середня вартість оренди складала $345/га, продажу —
          $10 тис./га. Обмежень щодо обсягів в одні руки та іноземців
          немає. Єдине виключення становлять громадяни недружніх країн:
          їм доступ на ринок закритий. Податки та спосіб передачі прав
          власності (рішення місцевої влади, аукціон тощо)
          встановлюється окремо в кожному штаті. Сільське господарство
          приносить цій країні щороку до $1 млрд доходів.
        </p>
        <p>
          У Канаді діють схожі правила, от тільки площі сільгоспземель
          суттєво скромніші — тільки 6% від загальної території. Близько
          65% угідь перебуває у приватній власності, зокрема й у
          громадян інших країн.
        </p>
        <p>
          <img src=${newsPageImg4_3} />
        </p>
        <p>
          <strong>Аргентина</strong>
        </p>
        <p>
          В Аргентині сільське господарство забезпечує 8% ВВП. І це не
          дивно, враховуючи площі сільгоспземель — 148,7 млн га або
          втричі більше, ніж в Україні. Середня ціна — $14 тис./га,
          земельний податок, залежно від провінції, складає 1-9%. У
          приватних руках перебуває 98%, у власності іноземців в окремих
          регіонах – до 10%. Загалом ринок для іноземних громадян
          відкритий, але містить певні обмеження: наприклад, до 1 тис.
          га в одні руки. Свого часу надмірні заборони спровокували
          падіння попиту і вартості землі, тому з 2016 року триває курс
          на лібералізацію. Аргентина, як і більшість країн Південної
          Америки, забороняє іноземним громадянам купувати землю на
          прикордонних територіях.
        </p>
        <p>
          <strong>Великобританія та Ірландія</strong>
        </p>
        <p>
          Яку б країну Західної Європи ми не аналізували, правила всюди
          майже ідентичні: мінімум обмежень і земля за ціною золота.
          Ринок землі у Великобританії — найстарший, йому вже 200 років.
          В агровиробництві задіяно 71% загального земельного фонду. У
          приватній власності 90% при цьому в сфері працюють лише 1,1%
          населення країни. Власником землі може стати будь-хто, середня
          вартість гектару — $31,4 тис./га. В Ірландії земля ще дорожча
          — $39 тис./га. Тут також жодних обмежень іноземцям за винятком
          окремого дозволу земельної комісії. Від угоди держава отримує
          мито в розмірі 1-8% від вартості ділянки.
        </p>
        <p>
          <img src=${newsPageImg4_1} />
        </p>
        <p>
          <strong>Німеччина і Нідерланди</strong>
        </p>
        <p>
          У Німеччині ринок землі відкрився в 1990-х у три етапи. На
          першому землю передавали в оренду на 12 років, на другому
          дозволили продаж тим, хто працює в агробізнесі або володів
          ділянкою у минулому, на третьому — зняли всі обмеження.
          Максимальний розмір податку при продажу — 3,5% (сплачує
          покупець). Наразі близько 60% сільгоспземель в Німеччині
          перебувають в оренді. Як кажуть експерти, це обумовлено
          високою вартістю покупки — $32 тис./га.
        </p>
        <p>
          Беззаперечним ціновим лідером є Нідерланди: 1 гектар в
          середньому коштує $63 тис./га. Однак і продуктивність
          сільського господарства в цій країні в декілька разів вища,
          ніж в цілому в ЄС. Майже 90% земель перебувають у приватній
          власності. Купувати і продавати може будь-хто, податок — 6%
          від вартості угоди. Встановлені мінімальні строки оренди для
          фермерських господарств 12 років, для всіх інших — 6 років. За
          орендарем закріплено пріоритетне право на покупку
        </p>
        <p>
          <strong>Польща і Румунія</strong>
        </p>
        <p>
          У Польщі ринок землі діє з обмеженнями. Наприклад, фізичній
          особі дозволяється володіти не більше ніж 500 га землі. В
          Польщі найдовше серед країн-учасниць ЄС діяв перехідний
          період, в який іноземцям заборонявся доступ на ринок. Це
          обмеження зняли в 2016 році, і це позитивно вплинуло на суми
          інвестицій і вартість ділянок, наразі ціна складає $10,3
          тис./га. Щоб придбати ділянку, громадянам інших країн
          необхідно дотримуватися певних умов: бути в шлюбі з
          громадянином/громадянкою Польщі і жити в цій країні протягом
          останніх 2-х років (або 5 років після отримання статусу
          постійного резидента). І хоча ці вимоги доволі лояльні,
          іноземці не поспішають скуповувати землю. Найбільшим
          землевласником країни залишаються сімейні ферми — їм належить
          близько 80% сільгоспземель. Єдиний земельний податок — податок
          на продаж на рівні 2-5% від вартості.
        </p>
        <p>
          <img src=${newsPageImg4_2} />
        </p>
        <p>
          Цікавий досвід Румунії. Громадяни країн ЄС можуть придбати
          землю за умови цільового використання. Обмеження для фізичних
          і юридичних осіб — досвід роботи в сільському господарстві або
          профільна вища освіта. Приватна особа може сконцентрувати не
          більше 100 га. Земельний податок — 2%, податок на продаж —
          2-3%. Пріоритетне право покупки закріплене за орендарями.
          Ринок у цій країні сформувався відносно нещодавно, земля
          потроху дорожчає і виросла до $1145/га. Цікаво, що Румунія має
          найбільшу в Європі кількість іноземців, що володіють землею.
          Їм належить приблизно 900 тис. га або 11%.
        </p>
        <p>
          Замість висновків. Уніфікованої моделі, яка б однаково успішно
          працювала в кожній країні, не існує. Україні варто
          проаналізувати досвід інших та впровадити кращі рішення.
          Багато з них апробовані. Наприклад, ціни вищі там, де їх
          регулює ринок, а не держава. Іноземці, що приходять і
          скуповують чужі країни, — міф. Чимало з них, маючи гроші і
          опцію викупу, продовжують орендувати землю у селян або купують
          невеликі в масштабах країни ділянки. Чим вища продуктивність
          сільського господарства, тим цінніша земля. Чим більше держава
          дає свобод, тим більше грошей залучає в економіку.
        </p>`,
    imgUrl: newsPageImg4,
    publishedAt: '01.11.2021',
  },
  {
    id: '5',
    url: '/news-page-5',
    title: `Продати землю і захиститися від шахраїв: безкоштовний
    Viber-бот LandInvest дозволяє перевірити пайові ділянки у
    держреєстрах`,
    content: `<p>
          <strong>
            За допомогою
            <a href="https://land-invest.com.ua/?utm_source=news">
              онлайн-платформи LandInvest
            </a>
            та
            <a href="https://chats.viber.com/landinvest">
              однойменного Viber-боту
            </a>
            землевласники можуть оперативно та безкоштовно перевірити,
            чи зареєстрований їхній пай у держреєстрах.
          </strong>
        </p>
        <p>
          Відповідно до законодавства, земельна ділянка повинна бути
          зареєстрована в Державному земельному кадастрі та внесена до
          Реєстру речових прав на нерухоме майно.
          <a href="https://minjust.gov.ua/news/ministry/olga-onischuk-vlasnikam-zemli-varto-poturbuvatisya-pro-nalejne-oformlennya-dokumentiv-schob-zahistitisya-vid-reestratsiynogo-reyderstva">
            За словами
          </a>
          заступника міністра юстиції Ольги Онищук, присвоєння
          кадастрового номера та внесення даних до реєстру – головне
          підтвердження права власності та умова для будь-яких юридичних
          операцій із землею. Без них власники паїв уразливі перед
          рейдерами та шахраями, а також не зможуть виставити ділянку на
          продаж.
        </p>
        <p>
          Щоб особисто отримати виписки, доведеться пройти тривалу
          процедуру, включно з отриманням електронного цифрового
          підпису, а також заплатити держмито та комісію банку.
          Viber-бот LandInvest дає можливість зробити це безкоштовно та
          за кілька кліків. Бот перевірить реєстрацію паю в реєстрах і
          підкаже алгоритм дій, якщо ділянки в них не виявиться.
          Скористатися Viber-ботом можна за посиланням:
          <a href="https://chats.viber.com/landinvest">
            https://chats.viber.com/landinvest
          </a>
        </p>
        <p>
          У Міністерстві юстиції запевняють: вносячи дані про ділянку до
          держреєстрів, землевласники можуть бути впевнені в захисті
          інформації. Відомство повністю убезпечило бази даних від
          кібератак. «Протягом 5 місяців 2021 року ми не зафіксували
          жодного повідомлення про несанкціоноване втручання в
          держреєстри. Це серйозний і важливий крок. Торішня статистика
          зазначає: орієнтовно 40% скарг, що надходили до Міністерства
          юстиції на незаконні реєстраційні дії, були пов'язані з
          несанкціонованим доступом до держреєстрів», –
          <a href="https://www.youtube.com/watch?v=EQO12jMIpBw">
            повідомила
          </a>
          заступник міністра юстиції Ольга Онищук на форумі «Україна 30.
          Земля».
        </p>
        <p>
          Вона також нагадала, що Мін'юст провів «генеральне прибирання»
          в лавах держреєстраторів і нотаріусів.
        </p>`,
    imgUrl: newsPageImg5,
    publishedAt: '01.11.2021',
  },
  {
    id: '6',
    url: '/news-page-6',
    title: `У Мінюсті роз'яснили правила нотаріального оформлення договорів про відчуження землі з 1 липня`,
    content: `<p>
          Заступниця міністра юстиції з питань державної реєстрації
          Ольга Оніщук в
          <a href="http://www.golos.com.ua/news/138155?fbclid=IwAR0ZGi6DbzQ9RkJmlBT3knpbeS6w23-YlU76MwfyjJ--INNQMkLYzYUxNyY">
            інтерв'ю «Голосу України»
          </a>
          відповіла на актуальні питання щодо нотаріального оформлення
          відчуження земельних ділянок з 1 липня.
        </p>
        <p>
          Вона нагадала всім землевласникам: головною умовою для
          підтвердження їхнього статусу є реєстрація ділянки у
          Державному земельному кадастрі. Ділянці обов'язково потрібно
          присвоїти кадастровий номер – без нього неможливо провести
          жодних юридичних операцій. «Належне оформлення документів, які
          підтверджують право власності або інше речове право на
          земельну ділянку, та внесення відповідних відомостей до
          державного реєстру допоможе убезпечити власність від посягань
          шахраїв», – наголосила Ольга Оніщук.
        </p>
        <p>
          Якщо земля відповідним чином оформлена і власник має намір
          продати її іншому громадянину України, потрібно зібрати і
          надати нотаріусу низку документів. Окрім паспорту громадянина,
          це державний акт на право власності на землю, договір,
          свідоцтво про право на спадщину або інший документ, що
          підтверджує право власності, та документи про грошову оцінку
          відчужуваної земельної ділянки.
        </p>
        <p>
          Оскільки законом передбачено обмеження площі ділянки в одні
          руки, нотаріусу необхідно буде надати документи про
          перебування набувача у шлюбі, у тому числі зареєстрованому за
          кордоном, та про набуття землі сільськогосподарського
          призначення на праві спільної сумісної власності подружжя.
          Нотаріус має право затребувати у потенційного покупця
          документи, що підтверджують джерела походження коштів для
          придбання землі.
        </p>
        <p>
          Вартість земельної ділянки не може бути нижчою нормативної
          грошової оцінки. При укладанні договору необхідно буде
          сплатити податки: податок на доходи фізичних осіб за ставкою
          5% від ціни, зазначеної в договорі купівлі-продажу, та
          військовий збір у розмірі 1,5%.
        </p>
        <p>
          За словами Ольги Оніщук, від оподаткування звільняються
          власники земельних ділянок сільськогосподарського призначення
          та паїв, які були отримані у порядку безоплатної передачі
          згідно зі статтею 121 ЗК України. Пільгами за певних умов і
          тільки раз на рік можуть скористатися також особи, які
          отримали такі ділянки у процесі приватизації.
        </p>`,
    imgUrl: newsPageImg6,
    publishedAt: '01.11.2021',
  },
  {
    id: '7',
    url: '/news-page-7',
    title: `Продати земельну ділянку можна легко та безпечно з
    онлайн-платформою LandInvest`,
    content: `<p>
          Команда онлайн-платформи
          <a href="https://land-invest.com.ua/?utm_source=news">
            LandInvest
          </a>
          створила можливості для продажу земельних ділянок. Тепер
          землевласники можуть перевірити свою ділянку, одразу подати
          заявку на її продаж за ринковою ціною й отримати гроші
          протягом 5 днів, а інвестори – знайти для себе найбільш
          вигідні пропозиції.
        </p>
        <p>
          За допомогою платформи LandInvest та
          <a href="https://chats.viber.com/landinvest">
            однойменного Viber-боту
          </a>
          землевласники можуть швидко та безкоштовно перевірити, чи
          зареєстрована їхня ділянка в державних реєстрах. Витяги з
          реєстрів необхідні, по-перше, для захисту своєї власності від
          шахраїв, по-друге, для здійснення будь-яких юридичних операцій
          із землею.
        </p>
        <p>
          Платформу і Viber-бот LandInvest було розроблено, щоб
          допомогти власникам землі розібратися у правилах, які набули
          чинності 1 липня після відкриття ринку землі. Адже чимало
          інформації є суперечливою та навіть маніпулятивною. Тож автори
          зібрали аналітику, перевірені новини, відповіді на
          найпоширеніші питання на одній платформі. «За час роботи
          LandInvest партнерами платформи стали аграрні компанії,
          зокрема Kernel. Крім того, ми отримали тисячі позитивних
          відгуків від землевласників. Більшість цікавилася, як продати
          ділянку, куди звернутися, як не натрапити на шахраїв та чи не
          плануємо створити майданчик для продажу ділянок? Враховуючи
          запит землевласників, команда проєкту вирішила трансформувати
          платформу в маркетплейс, тому відтепер за допомогою LandInvest
          можна і продати, і купити землю», — розповідає Руслан
          Яковенко, Керівник проєкту LandInvest.
        </p>
        <p>
          Сьогодні власники земельних ділянок разом з LandInvest можуть
          безкоштовно, швидко та зручно перевірити ділянку в державних
          реєстрах, наявність обтяжень, отримати справедливу оцінку
          вартості земельної ділянки на основі даних нормативної
          грошової оцінки та коефіцієнтів родючості землі в режимі
          онлайн. Землевласникам більше не доведеться вишукуватися у
          черги до різних держаних інстанцій, днями очікувати необхідні
          документи та платити за них кошти. Всім, хто хоче продати свою
          ділянку, LandInvest гарантує прозорість проведення угоди
          відповідно до законодавства України, повний супровід угоди за
          рахунок платформи, можливість контролювати та відстежувати
          процес проходження угоди через особистий кабінет на сайті.
        </p>
        <p>
          Водночас покупці земельних ділянок (інвестори) за допомогою
          LandInvest отримають доступ до каталогу перевірених земельних
          ділянок по всій території України, можливість отримання
          персонального супроводу угоди та підбір індивідуальних
          варіантів інвестування. Крім того, вони матимуть інструменти
          для перевірки та ідентифікації власників і продавців земельних
          ділянок. Сам процес проходження угоди вони також зможуть
          контролювати та відстежувати в особистому кабінеті.
        </p>
        <p>
          Онлайн-платформа LandInvest інтегрована з сервісами
          ідентифікації (BankID, ДіяПідпис), всіма державними реєстрами
          та базами даних для перевірки земельних ділянок. Вона гарантує
          користувачам автоматизований процес оцінки земельної ділянки,
          швидкий та високопрофесійний супровід угоди купівлі-продажу.
        </p>`,
    imgUrl: newsPageImg7,
    publishedAt: '01.11.2021',
  },
  {
    id: '8',
    url: '/news-page-8',
    title: `Інвестиції у землю — безпечно, швидко та вигідно: оновлений
        маркетплейс LandInvest пропонує покупцям землі послуги «під
        ключ»`,
        content: `<div className="title title_small-md">
        Інвестування у земельні активи в Україні — задача непроста.
        Складнощі пов’язані як з юридичними тонкощами, так і з
        відсутністю повноцінних баз даних та зручних сервісів.
        LandInvest вивчив потреби інвесторів та готовий запропонувати
        повний супровід угод: оновлення онлайн платформи забезпечить
        швидкий та надійний процес пошуку й купівлі земельних ділянок.
      </div>

      <p>
        <strong>Про перспективи</strong>
      </p>

      <p>
        Ринок землі, який діє в Україні з липня 2021 року, відкриває
        перед землевласниками та інвесторами нові перспективи. Є всі
        підстави вважати, що в найближчому майбутньому одним із
        найбільш надійних, дохідних та ліквідних активів буде земля, —
        така тенденція простежується у всьому світі.
      </p>

      <p>
        <strong>Проблеми</strong>
      </p>

      <p>
        Ринок землі в Україні перебуває в зародковому стані. Однак
        інтерес до земельних активів величезний. Проблема в тому, що
        наразі ні власники земельних угідь, ні потенційні покупці
        часто не знають, як провести об’єктивну і справедливу оцінку
        активів та організувати купівлю/продаж. Продавці відчувають
        труднощі з пошуком покупців, а покупці — із пошуком цікавих
        об’єктів. Платформа LandInvest створена, щоб надати практичну
        експертну допомогу всім учасникам земельного ринку. Сервіс
        організований у форматі зручного майданчика для комунікацій
        між потенційними продавцями і покупцями.
      </p>

      <p>
        <strong>Завдання платформи</strong>
      </p>

      <p>
        З моменту запуску Land-Invest.com.ua кількість звернень за
        експертною підтримкою та консультаціями постійно збільшується.
        Це найкраще підтвердження необхідності та актуальності
        сервісу, а також бурхливого зростання інтересу до земельних
        активів.
      </p>

      <p>
        Ринок землі для нашої країни — явище нове, воно викликає
        багато побоювань і питань з боку інвесторів. Дати правильні
        відповіді на них та допомогти з безпосереднім проведенням угод
        із продажу можуть фахівці з відповідною підготовкою.
      </p>

      <p>
        LandInvest — це платформа, яка допомагає розібратися з
        правовими нюансами і процесом купівлі-продажу угідь.
        Скориставшись послугами платформи, кожен охочий зможе отримати
        необхідну інформацію і укласти безпечну та взаємовигідну угоду
        із земельними активами.
      </p>

      <p>
        Орієнтуючись на запити користувачів та помітне зростання
        попиту на земельні ділянки, команда LandInvest на чолі з
        керівником Русланом Яковенком планує найближчим часом
        відновити й істотно розширити функціонал онлайн-платформи, яка
        вже давно перестала бути просто зручною інформаційною
        системою.
      </p>

      <p>
        Основна мета сервісу — забезпечити комплексний, компетентний,
        повноцінний супровід кожному учаснику земельних торгів,
        зокрема тим, хто готовий інвестувати в українські чорноземи.
        Сервіс готовий запропонувати найзручніший формат — оформлення
        угод під ключ, щоб в одному місці продавці й покупці могли
        отримати вичерпні консультації та практичну допомогу на
        кожному етапі проведення операції. Такий підхід передбачає
        пошук придатних ділянок, оцінку угідь, оформлення документації
        та інші супутні послуги. Скориставшись сервісом, продавці та
        інвестори заощаджують свій час на пошуки кращих пропозицій і,
        що не менш важливо, — отримують гарантії безпеки.
      </p>

      <p>
        <strong>Переваги платформи для продавців</strong>
      </p>

      <p>
        Нагадаємо, що маркетплейс LandInvest здійснює перевірку
        документів і швидку ринкову оцінку земельних активів — для
        продавців це є абсолютно безкоштовним. Землевласник отримує
        кваліфіковану консультацію з оформлення документації та
        пропозиції від покупців — це реальна можливість продати
        земельну ділянку за справедливою, актуальною ціною, до того ж
        у найкоротші терміни.
      </p>

      <p>
        <strong>Нові можливості для інвесторів</strong>
      </p>

      <p>
        Через обліковий запис сервіс надасть доступ до унікального
        каталогу ділянок — й тих, що вже продаються, й тих, які ще
        перебувають у процесі підготовки для передачі на торги.
      </p>
      <p>
        <span>
          <ul>
            <li>
              Платформа представляє лише ті активи, які пройшли
              експертну перевірку та оцінку. Працюючи через
              LandInvest, інвестори отримають реальну можливість
              мінімізувати ризики та здобути перевірену інформацію і
              пропозиції з перших рук у рамках особистих запитів.
            </li>
          </ul>
        </span>
      </p>
      <p>
        Щоб задовольнити запити інвесторів, передбачена система
        фільтрів, що дає можливість сортувати лоти за важливими для
        покупця параметрами. Користувачі LandInvest можуть самостійно
        керувати процесом та налаштуваннями в системі, або обрати
        комплексний автоматизований супровід, який передбачає підбір
        індивідуальних варіантів інвестування та ведення процесу
        реалізації угоди під ключ.
      </p>
      <p>Landinvest гарантує кожному інвестору:</p>
      <p>
        <span>
          <ul>
            <li>експертну оцінку земельних ділянок</li>
            <li>
              простий і зручний вебінтерфейс сайту з детальною
              інформацією за кожною угодою
            </li>
            <li>
              оформлення угод під ключ: від оцінки до укладення
              договору
            </li>
          </ul>
        </span>
      </p>
      <p>
        Слідкуйте за новинами сервісу, щоб першими скористатися усіма
        можливостями.
      </p>`,
    imgUrl: newsPageImg8,
    publishedAt: '01.11.2021',
  },
];