import {Component} from 'react';
import FaqItem from "../../items/faq-item/faq-item";


class FaqsBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
      selectedItem: this.props.listQuestions[0],
    };
  }

  onSelectItem = (item) => {
    this.setState({
      selectedItem: item,
    });
  };

  render() {
    return (
      <div className="faq">
        <div className={this.props.styles}>Часті питання (FAQ)</div>
        <div className="faq__items">
          {
            this.props.listQuestions.map((item, index) => (
              <FaqItem 
                item={item} 
                key={index} 
                onClickHandler={this.onSelectItem}
                currentItem={this.state.selectedItem} 
              />))
          }
        </div>
      </div>
    );
  }
}

export default FaqsBlock;
