import { Component, Suspense, lazy } from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import { HashLink } from "react-router-hash-link";
import { ViberIcon } from "@this-assets/icons/social";
import { COLOR_PURPLE, COLOR_WHITE } from "@this-basic-styles/colors-const";
import BurgerBtn from "../buttons/burger-btn";
import { LogoIcon, LogoWhiteIcon, MobBackArrowIcon } from "@this-assets/icons/ui";
import { BUY_LINK, NEWS_LINK, HOME_LINK_INVESTMENT } from "@this-core/constants/page-links";

// Christmas logo & preloader for holidays!
// import { BlueCyrclePreloader } from '@this-assets/preloaders';
// const LogoChristmasIcon = lazy(() => import('@this-assets/icons/ui-lazy/logo-christmas.svg'));
// <Suspense fallback={<BlueCyrclePreloader width={112} height={39} />}>
//   <LogoChristmasIcon width={112} height={39} />
// </Suspense>

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { ...this.props, headerScrollClass: "", mobAddClass: false };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenScrollEvent);
  }

  listenScrollEvent = () => {
    if (window.scrollY > 0) {
      this.setState({ headerScrollClass: "header_scroll" });
    } else {
      this.setState({ headerScrollClass: "" });
    }
  };

  scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  mobMenutoggle = (e) => {
    e.preventDefault();

    this.setState({
      mobAddClass: !this.state.mobAddClass,
    });
  };

  render() {
    const { darkMode, header_class } = this.props;

    return (
      <header
        className={
          header_class === "header header_dark"
            ? header_class
            : header_class + " " + this.state.headerScrollClass
        }
      >
        <div className={`py-12 container`}>
          <NavLink to="/" className="logo" exact activeClassName="active">
            { darkMode ? <LogoWhiteIcon width={196} height={39} /> : <LogoIcon width={196} height={39} /> }
          </NavLink>

          <div className="header__wrapper">
            <nav className="main-nav">
              <ul>
                <li>
                  {header_class === "header header_dark" ? (
                    <HashLink
                      smooth
                      to={"/#about-us"}
                      scroll={(el) => this.scrollWithOffset(el)}
                    >
                      <span className='white'>Про нас</span>
                    </HashLink>
                  ) : (
                    <Link
                      to="about-us"
                      smooth={true}
                      offset={-80}
                      duration={500}
                    >
                      Про нас
                    </Link>
                  )}
                </li>
                <li>
                  <NavLink to={HOME_LINK_INVESTMENT}>
                    <p className={`fs-16-20 ${darkMode ? 'white' : 'dark-gray'}`}>Інвестування</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={NEWS_LINK}>
                    <p className={`fs-16-20 ${darkMode ? 'white' : 'dark-gray'}`}>Новини</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={BUY_LINK}>
                    <p className={`fs-16-20 ${darkMode ? 'white' : 'dark-gray'}`}>Купити</p>
                  </NavLink>
                </li>
              </ul>
            </nav>
            <div className='d-flex align-items-center'>
              <div>
                <a href="https://chats.viber.com/landinvest/uk" target="_blank">
                  <span className={`fs-16-20 ${darkMode ? 'white' : 'dark-gray'}`}>Ми у</span>
                </a>
              </div>
              <div className='ml-8 mr-30 d-flex align-items-center'>
                <ViberIcon width={32} height={32} fill={darkMode ? COLOR_WHITE : COLOR_PURPLE} />
              </div>
            </div>
            <a
              href={process.env.PERSONAL_CABINET}
              className="btn btn_dark-blue"
            >
              <span>Увійти</span>
            </a>
          </div>
          <div className='header__burger'>
            <BurgerBtn onClickHandler={this.mobMenutoggle} darkMode={darkMode} />
          </div>  
          <div
            className={`mob-menu ${this.state.mobAddClass ? "active" : ""}`}
            onClick={this.mobMenutoggle}
          >
            <div
              className="mob-menu__content"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="mob-menu__head">
                <NavLink to="/" className="logo" exact activeClassName="active">
                  <LogoIcon width={196} height={39} />
                </NavLink>
                <a href="#" onClick={this.mobMenutoggle}>
                  <MobBackArrowIcon width={16} height={28} />
                </a>
              </div>
              <nav className="main-nav">
                <ul>
                  <li>
                    {header_class === "header header_dark" ? (
                      <Link
                        to="about-us"
                        smooth={true}
                        offset={-8}
                        duration={500}
                        onClick={this.mobMenutoggle.bind(this)}
                      >
                        <p className='fs-16-20 dark-gray'>Про нас</p>
                      </Link>
                    ) : (
                      <HashLink
                        smooth
                        to={"/#about-us"}
                        scroll={(el) => this.scrollWithOffset(el)}
                      >
                        <p className='fs-16-20 dark-gray'>Про нас</p>
                      </HashLink>
                    )}
                  </li>
                  <li>
                    <NavLink to={HOME_LINK_INVESTMENT}>
                      <p className='fs-16-20 dark-gray'>Інвестування</p>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={NEWS_LINK}>
                      <p className='fs-16-20 dark-gray'>Новини</p>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={BUY_LINK}>
                      <p className='fs-16-20 dark-gray'>Купити</p>
                    </NavLink>
                  </li>
                  <li>
                    <div className='d-flex align-items-center'>
                      <div>
                        <a href="https://chats.viber.com/landinvest/uk" target="_blank">
                          <span className='fs-16-20 dark-gray'>Ми у</span>
                        </a>
                      </div>
                      <div className='ml-8 mr-30 d-flex align-items-center'>
                        <ViberIcon width={32} height={32} fill={COLOR_PURPLE} />
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href={process.env.PERSONAL_CABINET}>
                      Особистий кабінет
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;