import { InvestorLogoIcon, FarmerLogoIcon } from "@this-core/public/assets/icons/ui";
import { NavLink } from "react-router-dom";
import { FOR_FARMER_LINK, FOR_IVESTOR_LINK } from "@this-core/constants/page-links";
import SimpleBigBtn from "../../buttons/simple-big-btn";
import styles from './favor-block.module.scss';
import { COLOR_DARK_BLUE } from "@this-core/basic-styles/colors-const";


const FavorBlock = () => {

  return (
    <div className='mt-80'>
      <p className='fs-40-50-b t-center montserrat'>ПОСЛУГИ</p>
      <div className={`mt-50 ${styles.container}`}>
        <div className={`${styles.item}`}>
          <div className='d-flex flex-column align-items-center'>
            <div className='mt-50'>
              <InvestorLogoIcon  width={120} height={120} fill={COLOR_DARK_BLUE} />
            </div>
            <p className='mt-15 fs-32-40-b dark-blue t-center montserrat'>ІНВЕСТОРАМ</p>
            <div className='mt-40 fs-21-28 fw-600 dark-blue'>
              <p>Ставайте власником паю</p>
              <p className='mt-15'>Заробляйте на рентному доході</p>
              <p className='mt-15'>Про всі формальності подбає LandInvest</p>
            </div>
            <div className='mt-50 mb-55'>
              <NavLink to={FOR_IVESTOR_LINK}>
                <SimpleBigBtn title='ДЕТАЛЬНІШЕ' width={278} />
              </NavLink>
            </div>
          </div>
        </div>
        <div className={`${styles.item}`}>
          <div className='d-flex flex-column align-items-center'>
            <div className='mt-50'>
              <FarmerLogoIcon  width={120} height={120} />
            </div>
            <p className='mt-15 fs-32-40-b dark-blue t-center montserrat'>ПАЙОВИКАМ</p>
            <div className='mt-40 fs-21-28 fw-600 dark-blue'>
              <p>Безкоштовна оцінка паю</p>
              <p className='mt-15'>Підбір найкращіх умов продажу</p>
              <p className='mt-15'>Про всі формальності подбає LandInvest</p>
            </div>
            <div className='mt-50 mb-55'>
              <NavLink to={FOR_FARMER_LINK}>
                <SimpleBigBtn title='ДЕТАЛЬНІШЕ' width={278} />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavorBlock;