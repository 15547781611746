import { BlueCyrclePreloader } from "@this-assets/preloaders";
import InSaleBigItem from "../../items/in-sale-big-item/in-sale-big-item";


const InSaleListBlock = ({landInSaleArr, isLoading, error}) => {
  
  return (
    <div className='mt-15 mb-30 container'>
      {!!landInSaleArr.length && <div className='d-flex justify-content-around flex-wrap'>
        { landInSaleArr.map((item) => {
            return <div className='mb-15' key={item.ID}>
                <InSaleBigItem  item={item}  />
              </div>;
            })
          }
        </div>
      }

      {!landInSaleArr.length && <div style={{height: '300px'}}>
          <p className='mt-30 fs-24-28'>По даному фільтру актуальних пропозицій не знайдено</p>
        </div>}

      {isLoading && <div className='d-flex justify-content-center'>
        <BlueCyrclePreloader width={308} height={264} fill='blue' />
      </div>}
      
      {!!error && <div>
        <p className='my-20 fs-32-36 t-center montserrat'>Помилка загрузки даних</p>
      </div>}
    </div>
  );
};

export default InSaleListBlock;