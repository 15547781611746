import { combineReducers } from "redux";
import koatuuReducer from "@this-core/store/reducers/koatuu-reducer";
import applicationsOnSaleReducer from "./applications-on-sale-reducer";
import landInSaleReduser from './land-in-sale-reducer';
import placeGeocodeReducer from './place-geocode-reducer';


export default combineReducers({
  koatuu: koatuuReducer,
  // applicationsOnSale need to delate later
  applicationsOnSale: applicationsOnSaleReducer,  
  landInSale: landInSaleReduser,
  placeGeocodeData: placeGeocodeReducer,
});
