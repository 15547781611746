import {Component} from 'react';
import {withGoogleReCaptcha} from 'react-google-recaptcha-v3';
import { connect } from 'react-redux';
import { getKoatuu } from "@this-core/store/actions/koatuu-action";


function withUserDataSellWnd(C) {

  class Wraper extends Component {
    constructor(props) {
      super(props);
      this.state = {
        ...this.props,
        autocompliteShow: false,
        suggestions: [],
        text: "",
        capchaResponse: false,
        address: "",
        formValid: false,
        consent: true,
      };
    }
  
    componentDidMount() {
      this.props.getKoatuu();
    }

    componentDidUpdate(prevProps, prevState) {
      if (
        this.props.cadNumberError !== prevProps.cadNumberError ||
        this.props.nameError !== prevProps.nameError ||
        this.props.phoneError !== prevProps.phoneError ||
        this.state.consent !== prevState.consent ||
        this.state.autocompliteShow !== prevState.autocompliteShow ||
        this.state.address !== prevState.address
      ) {
        if (this.state.autocompliteShow) {
          this.props.updateData("cadNumber", "");
  
          if (
            this.props.nameError ||
            this.props.phoneError ||
            this.state.consent === false ||
            !this.state.address
          ) {
            this.setState({ formValid: false });
          } else {
            this.setState({ formValid: true });
          }
        } else {
          this.props.updateData("address", "");
          this.props.updateData("koatuuError", "Обов’язково до заповнення");
          this.setState({ address: "", text: "" });
  
          if (this.props.cadNumberError || this.props.nameError || this.props.phoneError || this.state.consent === false) {
            this.setState({ formValid: false });
          } else {
            this.setState({ formValid: true });
          }
        }
      }
  
      if (this.state.formValid !== prevState.formValid && this.state.formValid) {
        if (process.env.CAPTCHA) {
          this.handleVerifyRecaptcha();
        } else {
          this.setState({capchaResponse: true})
        }
      };
    }
  
    handleVerifyRecaptcha = async () => {
      const { executeRecaptcha } = this.props.googleReCaptchaProps;
      if (!executeRecaptcha) {
        console.log('Recaptcha has not been loaded');
        return;
      }
      const token = await executeRecaptcha('homepage');
      if (token) {
        this.setState({capchaResponse: true});
      } else {
        this.setState({capchaResponse: false});
      }
    };
  
    autocompliteChange = () => {
      this.setState({
        autocompliteShow: !this.state.autocompliteShow,
      });
    };
  
    onTextChanged = (koatuu) => (e) => {
      const value = e.target.value;
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`^${value}`, "i");
        suggestions = koatuu
          .sort()
          .filter((v) => regex.test(v))
          .slice(0, 10);
      }
      this.setState(() => ({ suggestions, text: value }));
    };
  
    suggestionSelected(value, koatuuLavel) {
      this.setState(() => ({
        text: value,
        suggestions: [],
        address: koatuuLavel,
      }));
  
      this.props.updateData("address", koatuuLavel);
      this.props.updateData("koatuuError", "");
    }
  
    renderSuggestions = (
      koatuu,
      koatuuLavel1,
      koatuuLavel2,
      koatuuLavel3,
      koatuuLavel4
    ) => {
      const { suggestions } = this.state;

      if (suggestions.length === 0) {
        return null;
      }
      return (
        <div className="autocomplite-input__dropdown">
          <ul>
            {suggestions.map((item, i) => (
              <li
                key={i}
                onClick={() => {
                  var index = koatuu.koatuu.map((e) => e.nameOfTheObject).indexOf(item);
  
                  if (koatuuLavel4[index]) {
                    this.suggestionSelected(item, koatuuLavel4[index]);
                  } else if (koatuuLavel3[index] && !koatuuLavel4[index]) {
                    this.suggestionSelected(item, koatuuLavel3[index]);
                  } else if (
                    koatuuLavel2[index] &&
                    !koatuuLavel4[index] &&
                    !koatuuLavel3[index]
                  ) {
                    this.suggestionSelected(item, koatuuLavel2[index]);
                  } else {
                    this.suggestionSelected(item, koatuuLavel1[index]);
                  }
                }}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      );
    }
  
    __onClickHandler(e) {
      e.preventDefault();
      if (this.props.cadNumberError && !this.state.autocompliteShow) {
        this.props.updateData("cadNumberVisit", true);
        this.props.updateData(
          "cadNumberError",
          "Обов’язково до заповнення"
        );
      }
      if (this.props.nameError) {
        this.props.updateData("nameVisit", true);
        this.props.updateData("nameError", "Обов’язково до заповнення");
      }
      if (this.props.phoneError) {
        this.props.updateData("phoneVisit", true);
        this.props.updateData("phoneError", "Обов’язково до заповнення");
      }
      if (this.props.koatuuError) {
        this.props.updateData("koatuuVisit", true);
        this.props.updateData("koatuuError", "Обов’язково до заповнення");
      }
    }

    render() {
      const { koatuu } = this.props.koatuu;
      const { text } = this.state;
      const koatuuName = koatuu.map((e) => e.nameOfTheObject);
      const koatuuLavel1 = koatuu.map((e) => e.firstLevel);
      const koatuuLavel2 = koatuu.map((e) => e.secondLevel);
      const koatuuLavel3 = koatuu.map((e) => e.thirdLevel);
      const koatuuLavel4 = koatuu.map((e) => e.fourthLevel);

      return <C {...this.state} {...this.props}
          text={text}
          koatuuName={koatuuName}
          koatuuLavel1={koatuuLavel1}
          koatuuLavel2={koatuuLavel2}
          koatuuLavel3={koatuuLavel3}
          koatuuLavel4={koatuuLavel4}
          onTextChangedHandler={this.onTextChanged}
          onSetState={(argm) => this.setState(argm)}
          onAutocompliteChange={this.autocompliteChange}
          onRenderSuggestions={this.renderSuggestions}
          onClickHandler={this.__onClickHandler}
        />;
    }
  };

  const mapStateToProps = (state) => ({ koatuu: state.koatuu });

  return withGoogleReCaptcha(connect(mapStateToProps, { getKoatuu })(Wraper));
}

export default withUserDataSellWnd;