import { useSelector } from 'react-redux';
import { getKoatuu } from "@this-core/store/actions/koatuu-action";
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

export function useKoatuuFindLocation(valueStr) {
  const dispatch = useDispatch();
  const koatuu = useSelector((state) => state.koatuu.koatuu);
  const [suggestions, setSuggestion] = useState([]);

  useEffect(() => {
    if (!koatuu.length) {
      dispatch(getKoatuu());
    }
  }, []);
  
  useEffect(() => {
    if (koatuu.length) {
      onTextChanged();
    }
  }, [valueStr, koatuu]);

  function onTextChanged() {
    let suggestions__ = [];
    if (valueStr.length > 0) {
      const regex = new RegExp(`^${valueStr}`, "i");
      suggestions__ = koatuu
        .sort()
        .filter(({nameOfTheObject}) => regex.test(nameOfTheObject))
        .slice(0, 10);
    }
    setSuggestion(suggestions__);
  }

  return { suggestions: suggestions};
}