import peopleImg1 from '@this-assets/imgs/people/people-about-us-img-1.jpg';
import peopleImg2 from '@this-assets/imgs/people/people-about-us-img-2.jpg';
import peopleImg3 from '@this-assets/imgs/people/people-about-us-img-3.jpg';

export const expertData = [
  {
    id: "1",
    imgUrl: peopleImg1,
    name: "Роман",
    surname: "Лещенко",
    position: "Міністр аграрної політики та продовольства",
    comment: `“Ми закликаємо людей виважено і правильно підходити до питання
      своєї власності, її реєстрації, перевірки кадастрових номерів,
      відкритих даних, щоб люди зрозуміли: вони є повноцінними
      розпорядниками своєї власності. Ми просто говоримо про
      відновлення історичної справедливості. Для цього ми скасували
      таке ганебне явище, як мораторій, яке 20 років існувало в
      Україні.”`
  },
  {
    id: "2",
    imgUrl: peopleImg2,
    name: "Ольга",
    surname: "Онищук",
    position: "Заступник міністра юстиції",
    comment: `“Присвоєння кадастрового номера та внесення даних до реєстру -
      головне підтвердження права власності та умова для будь-яких
      юридичних операцій з землею. Без них власники паїв уразливі
      перед рейдерами і шахраями, а також не зможуть виставити
      ділянку на продаж.”`
  },
  {
    id: "3",
    imgUrl: peopleImg3,
    name: "Дмитро",
    surname: "Ливч",
    position: "Голова Асоціації вільних землевласників",
    comment: `“Ми зможемо забезпечити у наступні 10 років після відкриття
      ринку землі сукупний економічний ефект в розмірі 85 млрд
      доларів, якщо це буде ліберальна модель. Якщо це буде менш
      ліберальна модель, то це буде близько 50 млрд доларів”`
  },
];