import React, { Component } from "react";
import Popup from "./popup";
import { NavLink } from "react-router-dom";
import { SecurityIcon } from "@this-assets/icons/ui";


class PopupCookie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupActive: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("coockie") !== "ok") {
      setTimeout(() => {
        this.setState({
          popupActive: true,
        });
      }, 2000);
    }
  }

  coockieConfirm = (e) => {
    e.preventDefault();
    this.setState({ popupActive: false });
    localStorage.setItem("coockie", "ok");
  };

  render() {

    return (
      <>
        <Popup
          popupClass="popup popup_security"
          popupClassActive="popup popup_security active"
          active={this.state.popupActive}
          setActive={this.coockieConfirm}
        >
          <div className="popup__title-block">
            <SecurityIcon width={32} height={32} />
            <div className="title title_md-md">
              Згода на збір, обробку та зберігання ваших персональних даних та
              файлів cookie
            </div>
          </div>

          <div className="popup__text">
            <p>
              Цей сайт використовує файли cookie для того, щоб допомогти вам в
              навігації по сайту, а також надати кращий користувальницький
              досвід, аналізувати використання наших продуктів та послуг,
              покращити якість рекламних та маркетингових активностей.
            </p>
            <p>
              Продовжуючи користуватись сайтом, ви даєте згоду на збір, обробку
              та зберігання ваших персональних даних та файлів cookie.
            </p>
            <p>
              Інформацію щодо використання ваших даних ви можете знайти на
              сторінці <NavLink to="/privacy-policy" target="_blank">
                Політика конфіденційності
              </NavLink>
            </p>
          </div>
          <div className="btn-block">
            <div className="row">
              <div className="col">
                <button
                  className="btn btn_dark-blue"
                  onClick={this.coockieConfirm}
                >
                  Прийняти
                </button>
              </div>
            </div>
          </div>
        </Popup>
      </>
    );
  }
}

export default PopupCookie;