import { BurgerBtnIcon } from "@this-assets/icons/ui";
import { useWindowSize } from "@this-core/hooks/hook-window-size";
import { COLOR_DARK_GRAY, COLOR_WHITE } from "@this-basic-styles/colors-const";


const BurgerBtn = ({onClickHandler, darkMode = false}) => {
  const { size } = useWindowSize();

  if (size == "lg" || size == "xl") return null;

  return (
    <div onClick={onClickHandler}>
      <BurgerBtnIcon width={24} height={24} fill={darkMode ? COLOR_WHITE : COLOR_DARK_GRAY} />
    </div>
  );
};

export default BurgerBtn;