import { useEffect } from "react";
import LazyLoad, { forceVisible } from "react-lazyload";
import Skeleton from 'react-loading-skeleton';
import styles from './image-lazy.module.scss';


const ImageLazy = ({url, width, height, alt='#', className='', noPlaceholder }) => {

  useEffect(() => {
    forceVisible();
  }, []);

  return (
    <LazyLoad
      offset={100}
      className={`${className} ${styles.imgBlock}`}
      placeholder={ noPlaceholder ? null : <Skeleton width={width} height={height} circle={true} />}
    >
      <img alt={alt} src={`${url}`} width={width} height={height} />
    </LazyLoad>
  );
};

export default ImageLazy;