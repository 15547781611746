import { expertData } from "@this-core/api-data/expert-data";
import ExpertItem from "../../items/expert-item/expert-item";
import SliderCommon from "../../sliders/slider-common";
import { NavLink } from "react-router-dom";
import {HOME_LINK_INVESTMENT } from "@this-core/constants/page-links";

function ExpertsBlock()
{
  return (
    <div className="people-about-us">
      <div className="title"><NavLink className="title" to={HOME_LINK_INVESTMENT}>ЧОМУ ВАРТО ІНВЕСТУВАТИ В ЗЕМЛЮ</NavLink></div>
      <p className="why-invest">•	Актив з подвійною прибутковістю</p>
      <p className="why-invest">•	Низький поріг входу</p>
      <p className="why-invest">•	Забезпечення вартості в валюті</p>
      <p className="why-invest">•	Потенціал росту вартості</p>
      <p className="why-invest">•	Обмежений ресурс</p>
      {/*<SliderCommon items={expertData} slidesToShow={3} styles='people-about-us__slider'>
        { (item) => <ExpertItem {...item} key={item.id} /> }
      </SliderCommon>*/}
      <div className="why-invest-btn"><NavLink className="btn btn_dark-blue" to={HOME_LINK_INVESTMENT}>ПРО РИНОК ЗЕМЛІ В УКРАЇНІ</NavLink></div>
    </div>
  );
};

export default ExpertsBlock;