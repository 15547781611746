//Компоненты JS из Node Modules
import { render } from "react-dom";
import App from "./app";
import { Provider } from "react-redux";
import store from "@this-store/store";
import '../../front-land-invest-core/basic-styles/_reboot.pure.scss';
import "../scss/main.pure.scss";
import '@this-basic-styles/basic-styles.pure.scss';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";


render(
  <Provider store={store}>
    <GoogleReCaptchaProvider reCaptchaKey={process.env.CAPTCHA_KEY}>
      <App />
    </GoogleReCaptchaProvider>
  </Provider>,
  document.getElementById("app-root")
);
