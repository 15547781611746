import { MapLocationIcon } from '@this-core/public/assets/icons/ui';
import { StarIcon } from '@this-core/public/assets/icons/ui';
import { areaFormater, priceFormater } from '@this-core/helpers/formaters';
import styles from './in-sale-big-item.module.scss';
import SimpleBlueBtn from '../../buttons/simple-blue-btn';

const InSaleBigItem = ({item}) => {
  const {Address, Purpose, AreaGA, AgreementCostEval, OfferedCostEval} = item;
  const pricePerGA = AreaGA ? OfferedCostEval / AreaGA : 0;
  
  return (
    <div className={`${styles.container} d-flex`}>
      <div className={styles.img}>
        <MapLocationIcon />
      </div>
      <div className={`pl-8 d-flex flex-column justify-content-between ${styles.rightBlock}`}>
        <div className={`d-flex justify-content-between`}>
          <div>
            <p className='mt-15 fs-16-20-b'>{Address}</p>
            {Purpose && <p className='mt-5 fs-16-20 fw-600 gray'>{`Призначення: ${Purpose}`}</p>}
            <p className='mt-8 mb-8 fs-21-24-b dark-blue'>{`${priceFormater({price: AgreementCostEval, symbol: '₴'})}`}</p>
          </div>
          <div className='mt-10 mx-15'>
            {/* Пока нет личного кабинета не нужно */}
            {/* <StarIcon width={24} height={24} /> */}
          </div>
        </div>
        <div className={`d-flex justify-content-between`}>
          <div className='mb-10 d-flex flex-column justify-content-end'>
            <p className='mt-5 fs-16-20 fw-600 dark-gray'>{`${priceFormater({price: pricePerGA, symbol: 'грн/га'})}`}</p>
            {AreaGA && <p className='fs-16-20 fw-600 dark-blue'>{`${areaFormater(AreaGA)}`}</p>}
          </div>
          <div className='pb-10 pr-15 d-flex align-items-end'>
            <a href={process.env.PERSONAL_CABINET_REGISTER} rel="noreferrer" target="_blank">
              <SimpleBlueBtn title='КУПИТИ' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InSaleBigItem;