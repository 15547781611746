import NewsItemMini from '../../items/news-item/news-item-mini';
import styles from './top-news-block.module.scss';


const TopNewsBlock = ({list}) => {
  return (
    <>
      <div className="top-news">
        <div className={`pb-25 t-center ${styles.title}`}>Топ новин компанії</div>
        <div className={styles.itemsBlock}>
          {
            list.map(({id, url, imgUrl, title}) => {
              return <div key={id} className={styles.item}>
                <NewsItemMini url={url} imgUrl={imgUrl} title={title}/>
              </div>;
            })
          }
        </div>
      </div>
    </>
  );
};

export default TopNewsBlock;
