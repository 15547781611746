import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);

    let tab = "";
    let classSale = "";
    let classBuy = "";

    if(this.props.children[0].props.classSale !== undefined)
    {
      classSale = this.props.children[0].props.classSale;
      tab = this.props.children[0].props.label;
    }
    if(this.props.children[1].props.classBuy !== undefined)
    {
      classBuy = this.props.children[1].props.classBuy;
      tab = this.props.children[1].props.label;
    }

    this.state = {
      activeTab: tab,
      nameClassSale: classSale,
      nameClassBuy: classBuy
    };
  }

  clickSale = () => {
    this.setState({ activeTab: this.props.children[0].props.label});
    this.setState({ nameClassSale: "active"});
    this.setState({nameClassBuy: ""});
  }
  clickBuy = () => {
    this.setState({ activeTab: this.props.children[1].props.label});
    this.setState({nameClassBuy: "active"});
    this.setState({ nameClassSale: ""});
  }

  render() {
    const {
      clickSale,
      clickBuy,
      props: { children },
      state: { activeTab, nameClassSale, nameClassBuy },
    } = this;


    return (
      <>
        <div className="tabs">
          <ul className="tabs__nav">
            <li>
              <NavLink to="/" aria-current="false" onClick={clickSale} activeClassName={nameClassSale}>Продати </NavLink>
            </li> 
            <li>
              <NavLink to="/investor" aria-current="false" onClick={clickBuy} activeClassName={nameClassBuy}>Купити </NavLink>
            </li> 
          </ul>
          <div className="tabs__content">
            {children.map((child) => {
              if (child.props.label !== activeTab) return undefined;
              return child.props.children;
            })}
          </div>
        </div>
      </>
    );
  }
}

export default Tabs;
