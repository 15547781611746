import { NoteWithPenIcon, PlantIcon, BagWithMoneyIcon } from  '@this-assets/icons/ui';
import { useWindowSize } from "@this-core/hooks/hook-window-size";
import { COLOR_BLUE } from '@this-core/basic-styles/colors-const';
import HowItWorksItem from '../../items/how-it-works-item/how-it-works-item';
import styles from './how-it-works-buy-block.module.scss';

const steps = [
  {
    icon: <NoteWithPenIcon width={98} height={98} />,
    title: 'Реєстрація'
  },
  {
    icon: <PlantIcon width={104} height={90} />,
    title: 'Вибір ділянки'
  },
  {
    icon: <BagWithMoneyIcon width={98} height={98} fill={COLOR_BLUE} />,
    title: 'Оформлення договору'
  },
];

const HowItWorksBuyBlock = () => {
  const { isMobile } = useWindowSize();

  return (
    <div className={`mt-80 ${styles.block}`}>
      {
        steps.map(({icon, title}, index, steps) => {
          return <HowItWorksItem key={`${title}_${index}_`} icon={icon} title={title} steps={steps} index={index} isMobile={isMobile} />;
        })
      }
    </div>
  );
};

export default HowItWorksBuyBlock;
