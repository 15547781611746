import { GET_APPLICATIONSONSALE } from "../types";

const initialState = {
  applicationsOnSale: [],
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_APPLICATIONSONSALE:
      return {
        ...state,
        applicationsOnSale: [action.payload],
        loading: false,
      };
    default:
      return state;
  }
}
