

export const TextOnlyBtn = ({ title, onClickHandler, styles, disabled }) => {

  return (
    <button onClick={onClickHandler}  disabled={disabled}>
      <p className={styles}>{title}</p>
    </button>
  );
};
