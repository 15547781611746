import Header from "../../components/header/header";
import { useMemo } from "react";
import styles from './news-page.module.scss';
import { newsData } from '@this-core/api-data/news-data';
import ImageLazy from "@this-core/components/image/image-lazy";
import TopNewsBlock from "../../components/blocks/top-news-block/top-news-block";
import Footer from '../../components/footer/footer';

const NewsPage = ({id}) => {
  const currentNews = useMemo(() => {
    return newsData.find(item => item.id === id);
  }, [id]);

  return (
    <>
      <Header header_class="header header_dark" darkMode={true} />
      <div className="news-page">
        <div className="container">
          <div className="row">
            <div className="col col_9">
              <div className="news-page__head">
                <div className="title">{currentNews.title}</div>               
              </div>
              <h4>{currentNews.publishedAt}</h4>
              <div className={styles.contentDefault} dangerouslySetInnerHTML={{ __html: currentNews.content }} />
            </div>
            <div className="col col_3">
              <TopNewsBlock list={newsData.filter(item => item.id !== id)} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NewsPage;