import { Component } from "react";
import Popup from "./popup";
import { NavLink } from "react-router-dom";
import { SecurityIcon } from "@this-assets/icons/ui";


class BtnWithPopupSecurity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupActive: false,
    };
  }

  render() {

    return (
      <>
        <div
          className={this.props.btn_class}
          onClick={() => this.setState({ popupActive: true })}
        >
          <SecurityIcon width={32} height={32} />
          <span className='pl-10 fs-21-28-b'>{this.props.btn_name}</span>
        </div>
        <Popup
          popupClass="popup popup_security"
          popupClassActive="popup popup_security active"
          active={this.state.popupActive}
          setActive={() => this.setState({ popupActive: false })}
        >
          <div className="popup__title-block">
            <SecurityIcon width={68} height={80} />
            <div className="pl-15 title title_md-md">
              Застосування Політики конфіденційності
            </div>
          </div>

          <div className="popup__text">
            <p>
              Ця Політика конфіденційності визначає нашу роботу з персональними
              даними, які ми збираємо або отримуємо від третіх осіб, та цілі,
              для яких ми обробляємо ваші персональні дані. Також Політика
              конфіденційності визначає ваші права в процесі обробки нами
              персональних даних.
            </p>
            <p>
              Політика конфіденційності ознайомить вас з персональними даними,
              що збираються нами і можливостями для вас подати нам запит для
              видалення, оновлення, передання та/або надання доступу до цих
              даних.
            </p>
            <p>
              Зверніть увагу, що Політика конфіденційності застосовується лише
              до персональних даних, що збираються на цьому веб-сайті.
            </p>
            <p>
              <NavLink to="/privacy-policy" target="_blank">
                Політика конфіденційності
              </NavLink>
            </p>
          </div>
          <div className="btn-block">
            <div className="row">
              <div className="col">
                <button
                  className="btn btn_dark-blue"
                  onClick={() => this.setState({ popupActive: false })}
                >
                  ДОБРЕ
                </button>
              </div>
            </div>
          </div>
        </Popup>
      </>
    );
  }
}

export default BtnWithPopupSecurity;
