import Header from "../../components/header/header";
import './investment-page.css';
import Footer from '../../components/footer/footer';
import presentation from '@this-core/public/assets/files/Presentation.pdf';
//import Presentation from '../../../front-land-invest-core/public/assets/files/Presentation_A4_(DIgital)';



function InvestmentPage()
{
  const textMessage = `<p><strong>ПРО РИНОК ЗЕМЛІ В УКРАЇНІ</strong></p>
  <div style="padding-left: 25px"> 
  <p>Ринок землі в Україні відкрився 2,5 роки тому після багаторічного мораторію. Це був перший етап реформ, згідно з яким фізичні особи отримали змогу купувати до 100 га «в одні руки». Другий етап реформи дозволив з 01 січня 2024 року купувати землю в розмірі до 10 000 га юридичним особам. </p>           
  <p></br>Весь цей час, за винятком двох місяців після повномасштабного вторгнення, ринок землі розвивається.</p>
  <p>Поки що кількість угод не зрівнялася з довоєнним періодом, але попри ситуацію в країні на ринку землі присутня стабільна активність. І на це є об’єктивні причини. </p>
  </div>
  </br><strong>ЧОМУ <span class="investmentTextColor">ВАРТО ІНВЕСТУВАТИ</span> В ЗЕМЛЮ</strong>
  <div style="padding-left: 25px">
  <p><strong>1.	Земля - актив з подвійною прибутковістю</strong> – ділянки приносять дохід з оренди (в середньому <span class="investmentTextColor"><strong>6-8%</strong></span> річних) та постійно зростають по капіталізації (<span class="investmentTextColor"><strong>15 - 25%</strong></span> на рік).</p>
  <p><strong>2.	Низький поріг входу</strong> – вартість ділянки може складати <span class="investmentTextColor"><strong>$1,400 - $3,500</strong></span></p>
  <p><strong>3.	Забезпечення вартості активу в валюті</strong> – земля продається в гривнях, але вартість зберігається та <span class="investmentTextColor"><strong>підвищується в доларовому еквіваленті.</strong></span></p>
  <p><strong>4.	Земля – обмежений ресурс</strong> – площа землі, яка є привабливим активом з точки зору сільськогосподарської діяльності, <span class="investmentTextColor"><strong>в межах країни обмежена</strong></span> та не може бути нарощена (на відміну, наприклад, від житлової нерухомості).</p>
  <p><strong>5.	Якість української землі</strong> – <span class="investmentTextColor"><strong>український чорнозем</strong></span> відомий далеко за межами нашої країни своєю якістю, що обумовлює сталість агросектору та <span class="investmentTextColor"><strong>високу вартість землі</strong></span> як виробничого активу.</p>
  <p><strong>6.	Потенціал росту вартості</strong> – статус кандидата та майбутній вступ України до ЄС поступово <span class="investmentTextColor"><strong>наближають ціни</strong></span> на землю <span class="investmentTextColor"><strong>до європейських.</strong></span></p>
  <p><strong>7.	Інвестування коштів в Україну</strong> – <span class="investmentTextColor"><strong>кошти</strong></span> інвесторів залишаються <span class="investmentTextColor"><strong>в межах країни</strong></span> та працюють на економіку України.</p>
  <p><strong>8.	Швидке нівелювання ризиків</strong> – на відміну від нерухомості, <span class="investmentTextColor"><strong>відновити</strong></span> пошкоджену <span class="investmentTextColor"><strong>земельну ділянку</strong></span> до придатності к використанню <span class="investmentTextColor"><strong>набагато легше.</strong></span></p>
  </div>
  </br><strong><p>Скачати комерційну пропозицію та дізнатись більше щодо інвестування на ринку землі за посиланням.</strong></p>
  <div class="vn-teal" style="text-align: center;">
    <a target="_blank" href=${presentation} download class="btn-wrap">Завантажити (*pdf, 3,7 MB)</a>
  </div>
  <strong>КОНТАКТИ</strong>
  <p>За додатковою інформацією стосовно співпраці, консультацій та підбору варіантів для покупки можете звертатися до наших менеджерів за телефоном <strong>0504417261</strong>. Також приєднуйтеся до нашого Телеграм каналу <a target="_blank" style="color: #0000ff" href="https://t.me/landinvestua" rel="nofollow noopener">t.me/landinvestua</a> з актуальними пропозиціями по земельних ділянках.</p>`;

  return (
    <>
      <Header header_class="header header_dark" darkMode={true} />
      <div className="investmentContentDefault">
            <div dangerouslySetInnerHTML={{__html:textMessage}} />
            
            {/*<p className={styles.underline} dangerouslySetInnerHTML={{__html:"1.	Земля - актив з подвійною прибутковістю"}}></p>*/}
      </div>
      <Footer />
    </>
  );
};

export default InvestmentPage;