import {GEOCODE_COORDINATE_REQUEST, GEOCODE_COORDINATE_SUCCESS, GEOCODE_COORDINATE_ERROR} from '../types';

const initialState = {
  placeGeocodeArr: [],
  isLoading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GEOCODE_COORDINATE_REQUEST:
      return {...state, isLoading: true};
    case GEOCODE_COORDINATE_SUCCESS:
      return {...state, placeGeocodeArr: [...state.placeGeocodeArr, action.payload], isLoading: false};
    case GEOCODE_COORDINATE_ERROR:
      return {...state, error: action.payload, isLoading: false};
    default:
      return state;
  }
}