
export const findAverageCoordinates = (arrCoordinates) => {
  return arrCoordinates.reduce((total, currentItem, index, arr) => {
    let res = [];
    res[0] = total[0] + currentItem[0];
    res[1] = total[1] + currentItem[1];
    if (index === arr.length - 1) {
        return res.map(item => item / (index + 1));
    }
    return res;
  }, [0, 0]);
};