import {LAND_IN_SALE_REQUEST, LAND_IN_SALE_SUCCESS, LAND_IN_SALE_ERROR} from '../types';

const initialState = {
  landInSaleArr: [],
  isLoading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LAND_IN_SALE_REQUEST:
      return {...state, isLoading: true};
    case LAND_IN_SALE_SUCCESS:
      return {...state, landInSaleArr: action.payload, isLoading: false};
    case LAND_IN_SALE_ERROR:
      return {...state, error: action.payload, isLoading: false};
    default:
      return state;
  }
}