import NewsItem from "../../items/news-item/news-item";
import SliderCommon from "../../sliders/slider-common";
import { newsData } from '@this-core/api-data/news-data';


const NewsBlock = () => {
  return (
    <div className="news-slider">
      <div className="title">Новини</div>
      <SliderCommon items={newsData} styles='news-slider__slider'>
        { ({id, url, title, imgUrl}) => <NewsItem key={id} url={url} width={308} height={264} title={title} imgUrl={imgUrl}  /> }
      </SliderCommon>
    </div>
  );
};

export default NewsBlock;