import Header from '../../components/header/header';
import MapBlock from '../../components/blocks/map-block/map-block';
import { TextOnlyBtn } from '@this-core/components/common-btn/text-only';
import { useEffect, useMemo, useState } from 'react';
import InSaleListBlock from '../../components/blocks/in-sale-list-block/in-sale-list-block';
import { useDispatch, useSelector } from 'react-redux';
import { getLandInSale } from '@this-core/store/actions/land-in-sale-action';
import styles from './buy-page.module.scss';
import { FilterBtnIcon } from '@this-core/public/assets/icons/ui';
import SimpleWhiteBtn from '../../components/buttons/simple-white-btn';
import {oblastList, regionList, purposeList} from "@this-core/api-data/regions-list";
import SimpleSelect from '../../components/buttons/simple-select';
import Footer from '../../components/footer/footer';


const BuyPage = () => {
  const [isMapVisible, setMapVisible] = useState(true);
  const landInSale = useSelector((state) => state.landInSale);
  const {landInSaleArr, isLoading, error} = landInSale;
  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState(0);
  const [selectedOblast, setSelectedOblast] = useState(null);
  // const [selectedRegion, setSelectedRegion] = useState(null);

  useEffect(() => {
    dispatch(getLandInSale(landInSaleArr));
  }, []);

  const landInSaleFilterArr = useMemo(() => {
    if (!landInSaleArr.length) return [];
    return landInSaleArr.filter((poligon) => {
      if (selectedOblast === null || selectedOblast.id === 'hidden') {
        return true;
      } else {
        return poligon.Oblast === selectedOblast.id;
      }
    });
  }, [filterState, landInSale]);

  const __onClickHandler = () => {
    setMapVisible((boolean) => !boolean);
  };
  
  const sellsCountByOblast = useMemo(() => {
    let oblastListMod = oblastList;
    for (let i = 0; i < oblastList.length; i++) {
      const arr  = landInSaleArr.filter((poligon) => poligon.Oblast === oblastListMod[i].id);
      oblastListMod[i].sellsCount = arr.length;
    }
    return oblastListMod;
  }, [landInSale]);

  return (
    <>
      <Header header_class="header header_dark" darkMode={true} />
      <div className='container'>
        <div className='d-flex justify-content-between align-items-center'>
          <h1 className='mt-5 fs-32-64 fw-600 montserrat'>Купити ділянку</h1>
          <div className='d-flex'>
            <TextOnlyBtn title='КАРТА' onClickHandler={__onClickHandler} styles={isMapVisible ? 'fs-14-16-b dark-blue' : 'fs-14-16'} />
            <p className='fs-24-28'> | </p>
            <TextOnlyBtn title='СПИСОК' onClickHandler={__onClickHandler} styles={isMapVisible ? 'fs-14-16' : 'fs-14-16-b dark-blue'} />
          </div>
        </div>
        {/* Filter block unused now */}
        {/* <div className={`${styles.filterBlock} px-25 mb-10 d-flex justify-content-between align-items-center`}>
          <div className='d-flex align-items-center'>
            <div className='mr-10'>
              <FilterBtnIcon width={25} height={27} />
            </div>
            <div className='mr-15'>
              <SimpleSelect
                onClickHandler={setSelectedOblast}
                items={oblastList}
              />
            </div>
            Временно отключено
            <div className='mr-15'>
              <SimpleSelect
                onClickHandler={setSelectedRegion}
                items={regionList}
              />
            </div>
            <div className='mr-15'>
              <SimpleSelect
                onClickHandler={setSelectedOblast}
                items={purposeList}
              />
            </div>
          </div>
          <SimpleWhiteBtn title='ЗАСТОСУВАТИ' onClickHandler={() => setFilterState(state => ++state)} />
        </div> */}
      </div>
      <div className={`mb-30 container ${isMapVisible ? '' : 'none'}`}>
        <MapBlock landInSaleArr={landInSaleFilterArr} sellsCountByOblast={sellsCountByOblast} isLoading={isLoading} error={error} />
      </div>
      <div className={!isMapVisible ? '' : 'none'}>
        <InSaleListBlock landInSaleArr={landInSaleFilterArr} isLoading={isLoading} error={error} />
      </div>
      <Footer />
    </>
  );
};

export default BuyPage;