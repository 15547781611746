import { useHistory } from "react-router-dom";

const FormSubmitBtnBuy = ({disabled, title, styles, onClickHandler}) => {
  const history = useHistory();
  return (
    <button disabled={disabled} type="submit" className={styles} onClick={() => {onClickHandler; history.push('/investor/success')}}>
      <span className='fs-18-24 fw-600'>{title}</span>
    </button>
  );
};

export default FormSubmitBtnBuy;