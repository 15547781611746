import { Component } from "react";
import InputMask from "react-input-mask";
import CheckboxBtn from "../../buttons/checkbox";
import CustomSelect from "../../buttons/custom-select";
import BtnWithPopupSecurity from "../../popups/btn-with-popup-security";
import {withGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {oblastList} from "@this-core/api-data/regions-list";
import FormSubmitBtnBuy from "../../buttons/form-submit-btn-buy";

class FormStage1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
      formValid: false,
      consent: true,
      capchaResponse: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.nameError !== prevProps.nameError ||
      this.props.phoneError !== prevProps.phoneError ||
      this.state.consent !== prevState.consent
    ) {
      if (this.props.nameError || this.props.phoneError || this.state.consent === false) {
        this.setState({ formValid: false });
      } else {
        this.setState({ formValid: true });
      }
    }

    if (this.state.formValid !== prevState.formValid && this.state.formValid) {
      if (process.env.CAPTCHA) {
        this.handleVerifyRecaptcha();
      } else {
        this.setState({capchaResponse: true});
      }
    };
  }

  handleVerifyRecaptcha = async () => {
    const { executeRecaptcha } = this.props.googleReCaptchaProps;
    if (!executeRecaptcha) {
      console.log('Recaptcha has not been loaded');
      return;
    }
    const token = await executeRecaptcha('homepage');
    if (token) {
      this.setState({capchaResponse: true});
    } else {
      this.setState({capchaResponse: false});
    }
  };

  __onClickHandler(e) {
    if (!this.state.formValid) {
      e.preventDefault();

      if (this.props.nameError) {
        this.props.updateData("nameVisit", true);
        this.props.updateData("nameError", "Обов’язково до заповнення");
      }
      if (this.props.phoneError) {
        this.props.updateData("phoneVisit", true);
        this.props.updateData("phoneError", "Обов’язково до заповнення");
      }
      if (this.props.koatuuError) {
        this.props.updateData("koatuuVisit", true);
        this.props.updateData("koatuuError", "Обов’язково до заповнення");
      }
    }
  }

  render() {
    const {
      name,
      nameVisit,
      nameError,
      handleChangeName,
      phone,
      phoneError,
      phoneVisit,
      handleChangePhone,
      blurHandler,
      area,
      investments,
      cost,
      handleChangeArea,
      handleChangeInvestments,
      handleChangeCost,
    } = this.props;

    return (
      <>
        <div className={`main-form__stage-1 main-form__stage-1_buy active`}>
          <div className="row">
            <div className="col col_4">
              <label className={nameError && nameVisit ? "require" : ""}>
                <span>Ім’я та по батькові<em>*</em></span>
                <input
                  type="text"
                  placeholder="Введіть Ваше ім’я"
                  value={name}
                  name="name"
                  onChange={handleChangeName}
                  onBlur={blurHandler}
                ></input>
                <span className={`require__error ${nameError && nameVisit ? 'error' : ''}`}>
                  {nameError}
                </span>
              </label>
            </div>
            <div className="col col_4">
              <label className={phoneError && phoneVisit ? "require" : ""}>
                <span>Номер телефону<em>*</em></span>
                <InputMask
                  type="text"
                  mask="+380999999999"
                  maskChar="X"
                  placeholder="+380XXXXXXXXX"
                  value={phone}
                  name="phone"
                  onChange={handleChangePhone}
                  onBlur={blurHandler}
                />
                <span className={`require__error ${phoneError && phoneVisit ? 'error' : ''}`}>
                  {phoneError}
                </span>
              </label>
            </div>
            <div className="col col_4">
              <label>
                <span>Бажана площа, га</span>
                <input
                  type="text"
                  placeholder="Введіть число"
                  name="area"
                  value={area}
                  onChange={handleChangeArea}
                ></input>
              </label>
            </div>
            <div className="col col_4">
              <label>
                <span>Розмір інвестицій, $</span>
                <input
                  type="text"
                  placeholder="Введіть сумму"
                  name="investments"
                  value={investments}
                  onChange={handleChangeInvestments}
                ></input>
              </label>
            </div>
            <div className="col col_4">
              <label>
                <span>Вартість за га, $</span>
                <input
                  type="text"
                  placeholder="Бажана сума"
                  name="cost"
                  value={cost}
                  onChange={handleChangeCost}
                ></input>
              </label>
            </div>
            <div className="col col_4">
              <label>
                <span>Бажаний регіон</span>
                <CustomSelect
                  name="region"
                  updateData={this.state.updateData}
                  region={this.props.region}
                  items={oblastList}
                />
              </label>
            </div>
            <div className="col col_8"></div>
            <div className="col col_4">
              { (this.state.capchaResponse && this.state.formValid) ? <FormSubmitBtnBuy disabled={false} title='ПІДІБРАТИ ДІЛЯНКУ' styles='btn btn_dark-blue' /> :
                <FormSubmitBtnBuy disabled={true} title='ПІДІБРАТИ ДІЛЯНКУ' onClickHandler={(e) => this.__onClickHandler(e)} styles='btn btn_dark-blue btn_dark-blue_disable' />}
            </div>
            <div className="col col_4">
              <BtnWithPopupSecurity
                btn_class="btn btn_security"
                btn_name="Безпека персональних даних"
              />
            </div>
            <div className="col col_4 desktop-hide"></div>
            <div className="col col_4">
              <CheckboxBtn 
                onClickHandler={() => this.setState({consent: !this.state.consent})}
                isSelect={this.state.consent}
                styles='pl-8 fs-14-19 gray'
                title='Даю згоду на обробку персональних даних'
                blankLink='./agreement_290621.pdf' />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withGoogleReCaptcha(FormStage1);