import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLandInSale } from "../../../../front-land-invest-core/store/actions/land-in-sale-action";
import InSaleItem from "../../items/in-sale-item/in-sale-item";
import { BlueCyrclePreloader } from "@this-assets/preloaders";
import useVisibilitySensor from "@rooks/use-visibility-sensor";
import SliderOnSale from "../../sliders/on-sale-slider";


const InSaleSliderBlock = () => {
  const innerRef = useRef(null);
  const [needLoading, setNeedLoading] = useState(true);
  const landInSale = useSelector((state) => state.landInSale);
  const {landInSaleArr, isLoading, error} = landInSale;
  const dispatch = useDispatch();

  const { isVisible } = useVisibilitySensor(innerRef, {
    partialVisibility: true,
    shouldCheckOnMount: false
  });

  useEffect(() => {
    if (isVisible && needLoading) {
      dispatch(getLandInSale(landInSaleArr));
      setNeedLoading(false);
    }
  }, [isVisible, needLoading]);

  return (
    <div ref={innerRef} className='mt-80' >
      <p className='mb-40 fs-40-49-b t-center montserrat'>ЗАРАЗ У ПРОДАЖУ</p>
      {!!landInSaleArr.length && <SliderOnSale items={landInSaleArr}>
        { (item) => <InSaleItem key={item.ID} item={item}  /> }
      </SliderOnSale>}
      
      {isLoading && <div className='d-flex justify-content-center'>
        <BlueCyrclePreloader width={308} height={264} fill='blue' />
      </div>}
      
      {!!error && <div>
        <p className='my-20 fs-32-36 t-center montserrat'>Помилка загрузки даних</p>
      </div>}
    </div>
  );
};

export default InSaleSliderBlock;