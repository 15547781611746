import { MainPreloaderIcon } from "@this-assets/preloaders";

const SubmitPreloader = (props) => {
  return (
    <div className={`submit-preloader ${props.submitPreloaderActive ? 'active' : ''}`}>
      <div className="submit-preloader__content">
        <MainPreloaderIcon width={160} height={160} />
        <p className='mt-25'>Обробка даних</p>
      </div>
    </div>
  );
};

export default SubmitPreloader;