import { NavLink } from "react-router-dom";
import Header from "../../components/header/header";
import { newsData } from '@this-core/api-data/news-data';
import ImageLazy from "@this-core/components/image/image-lazy";
import styles from './news-list-page.module.scss';
import Footer from '../../components/footer/footer';


const NewsListPage = () => {
  return (
    <>
      <Header header_class="header header_dark" darkMode={true} />
      <div className="news">
        <div className="container">
          <div className="news__items">
            {
              newsData.map(({id, url, title, content, imgUrl, publishedAt}) => {
                return (
                  <div key={id} className="news__item">
                    <div className="row">
                      <div className="col">
                        <NavLink to={url}>
                          <ImageLazy url={imgUrl} width={245} height={210} className={`news__img pointer`} />
                        </NavLink>
                      </div>
                      <div className="col">
                        <div className="news__content">
                          <NavLink to={url} className="news__item-title">
                            {title}
                          </NavLink>
                          <h4>{publishedAt}</h4>
                          <div
                            className={`text-overflow-hide-7 ${styles.contentDefault}`} 
                            dangerouslySetInnerHTML={{ __html: content }} 
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NewsListPage;
