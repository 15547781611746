import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import PopupCookie from "../components/popups/popup-cookie";
import { newsData } from '@this-core/api-data/news-data';
import NewsPage from "../pages/news-page/news-page";
import * as PL from "@this-core/constants/page-links";
import NewsListPage from "../pages/news-list-page/news-list-page";
import HomePage from "../pages/home-page/home-page";
import PrivacyPolicy from "../pages/privacy-policy-page/privacy-policy-page";
import SuccessPage from "../pages/success-page/success-page";
import ScrollToTop from "@this-core/services/scroll-to-top";
import RedirectService from "@this-core/services/redirect-service";
import BuyPage from "../pages/buy-page/buy-page";
import ForInvestorPage from "../pages/for-investor-page/for-investor-page";
import ForFarmerPage from "../pages/for-farmer-page/for-farmer-page";
import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader"
import InvestmentPage from "../pages/investment-page/investment-page";

const WrappedMainFormSale = function(props) {
  return (<HomePage formSale={true} classSale="active"/>);
};
const WrappedMainFormBuy = function(props) {
  return (<HomePage formBuy={true} classBuy="active"/>);
};

const App = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const onPageLoad = () => {
      if (document.readyState === 'complete') {
        PageLoaded();
      } else {
        console.log("else " + document.readyState)
        window.addEventListener('load', onPageLoad, false);
        return () => window.removeEventListener('load', onPageLoad);
      }
    };

    const PageLoaded = () => {
      setLoading(false);
    };

    onPageLoad();
  }, []);

  return (
    <div>
    {loading ? (
      <ContentLoader 
      center
      speed={2}
      width="100%"
      height="50%"
      viewBox="0 0 435 300"
      backgroundColor="#f5f5f5"
      foregroundColor="#dbdbdb"
    >
    <rect x="12" y="35" rx="0" ry="0" width="6" height="246" /> 
    <rect x="14" y="34" rx="0" ry="0" width="408" height="6" /> 
    <rect x="416" y="34" rx="0" ry="0" width="6" height="246" /> 
    <rect x="12" y="276" rx="0" ry="0" width="408" height="6" /> 
    <rect x="150" y="53" rx="6" ry="6" width="127" height="15" /> 
    <rect x="37" y="77" rx="7" ry="7" width="361" height="139" /> 
    <rect x="58" y="225" rx="0" ry="0" width="316" height="8" /> 
    <rect x="86" y="238" rx="0" ry="0" width="267" height="8" /> 
    <rect x="58" y="252" rx="0" ry="0" width="316" height="8" />
    </ContentLoader>
    ):(
      <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route path={PL.HOME_LINK} exact component={WrappedMainFormSale} />
          <Route path={PL.HOME_LINK_SUCCESS} exact component={WrappedMainFormSale} />
          <Route path={PL.HOME_LINK_IVESTOR} exact component={WrappedMainFormBuy} />
          {<Route path={PL.HOME_LINK_IVESTOR_SUCCESS} exact component={WrappedMainFormBuy} />}
          <Route path={PL.NEWS_LINK} exact component={NewsListPage} />
          {newsData && newsData.length > 0 && newsData.map(({ url, id }) =>
            <Route exact path={url} key={`${PL.NEWS_PAGE_LINK}@${id}`}>
              <NewsPage id={id} />
            </Route>
          )}
          <Route path={PL.PRIVACY_POLICY_LINK} component={PrivacyPolicy} />
          <Route path={PL.SUCCESS_LINK} component={SuccessPage} />
          <Route path={PL.BUY_LINK} component={BuyPage} />
          <Route path={PL.FOR_IVESTOR_LINK} component={ForInvestorPage} />
          <Route path={PL.FOR_FARMER_LINK} component={ForFarmerPage} />
          <Route path={PL.HOME_LINK_INVESTMENT} component={InvestmentPage} />
          <Redirect to={PL.HOME_LINK} />
        </Switch>
        <RedirectService />
        <PopupCookie />
      </ScrollToTop>
    </BrowserRouter>)}
    </div>
  );
};

export default App;