import Header from "../../components/header/header";
import AboutUsBlock from "../../components/blocks/about-us-block/about-us-block";
import FaqsBlock from '../../components/blocks/faqs-block/faqs-block';
import NewsBlock from "../../components/blocks/news-block/news-block";
import ExpertsBlock from "../../components/blocks/experts-block/experts-block";
import backgroundImg from '@this-assets/imgs/main-top-block-desktop-bg.png';
import SubmitPreloader from "../../components/preloaders/submit-preloader";
import LoadLandDocumentsWnd from "../../components/forms/main-form-sale/load-land-documents-wnd";
import SuccessWnd from "../../components/forms/main-form-sale/success-wnd";
import ErrorWnd from "../../components/forms/main-form-sale/error-wnd";
import withSuccessPage from "@this-core/HOC/hoc-success-page";
import {WND} from '@this-core/constants/wnd-const';
import { homePageQuestions } from "@this-core/api-data/faqs-data";
import HowItWorksSellBlock from "../../components/blocks/how-it-works-sell/how-it-works-sell-block";
import Footer from '../../components/footer/footer';


const SuccessPage = (props) => {
  const { popupStage, mid, submitPreloaderActive, errorTitle, errorTitle2, formData2, 
    formDataName2, docType, onSubmitForm, onUpdateData} = props;

  return (
    <>
      <Header header_class="header" />
      <div className="home">
        <div className="main-top-block">
          <div className="main-top-block__content container">
            <div className="main-form">
              {popupStage === WND.ST_LOAD_LAND_DOCUMENTS && (
                <form  className="form" onSubmit={onSubmitForm(formData2, formDataName2, docType)}>
                  <LoadLandDocumentsWnd
                    docType={docType}
                    updateData={onUpdateData}
                  />
                </form>
              )}
              
              {popupStage === WND.ST_REQUEST_ADD_SUCCESS && <SuccessWnd mid={mid} /> }
              
              {popupStage === WND.ST_ERROR && <ErrorWnd
                errorTitle={errorTitle}
                errorTitle2={errorTitle2}
                resetState={"home"}
              /> }
              
              <SubmitPreloader submitPreloaderActive={submitPreloaderActive}/>
            </div>
          </div>
          <div className="main-top-block__bg">
            <img src={backgroundImg}></img>
          </div>
        </div>
        <div className="container">
          <HowItWorksSellBlock />
          {/* <NowOnSaleSlider /> */}
          <AboutUsBlock />
          <ExpertsBlock />
          <FaqsBlock listQuestions={homePageQuestions} styles='title' />
          <NewsBlock />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default withSuccessPage(SuccessPage);