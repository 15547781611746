export const HOME_LINK = '/';
export const HOME_LINK_SUCCESS = '/success';
export const HOME_LINK_IVESTOR = '/investor';
export const HOME_LINK_IVESTOR_SUCCESS = '/investor/success';
export const HOME_LINK_INVESTMENT = '/investment';
export const NEWS_LINK = '/news';
export const NEWS_PAGE_LINK = '/news-page';
export const BUY_LINK = "/buy";

export const SUCCESS_LINK = "/success";
export const PRIVACY_POLICY_LINK = "/privacy-policy";

export const FOR_IVESTOR_LINK = "/for-investor";
export const FOR_FARMER_LINK = "/for-farmer";