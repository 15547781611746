import Slider from "react-slick";


const SliderCommon = ({items, children, styles, slidesToShow = 4}) => {
  let settings = {
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: slidesToShow,
    slidesToScroll: 2,
    arrows: false,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: slidesToShow - 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: slidesToShow - 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return <Slider className={styles} {...settings}>
        {
          items.map((item) => {
            return children(item);
          })
        }
      </Slider>;
};

export default SliderCommon;
