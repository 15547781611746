import { useState, useEffect } from 'react';
import { useKoatuuFindLocation } from '@this-core/hooks/hook-koatuu-find-location';
import { useDispatch, useSelector } from 'react-redux';
import { getGeocodeCoordinate } from '@this-store/actions/geocode-coordinate-action';


const zoom = 12;
function withMapSearchBlock(C) {

  const hoc = ({map}) => {
    const [inputValue, setInputValue] = useState('');
    const [latestAddress, setLatestAddress] = useState('');
    const { suggestions } = useKoatuuFindLocation(inputValue);
    const placeGeocodeData = useSelector((state) => state.placeGeocodeData);
    const dispatch = useDispatch();

    const findCoordinateInStore = (latestAddress) => {
      const placeGeocodeItem = placeGeocodeData?.placeGeocodeArr.find(({place}) => latestAddress === place);
      return placeGeocodeItem?.results?.[0]?.geometry?.location;
    };
    
    const onGetCoordinate = (koatuuItem) => {
      const { nameOfTheObject } = koatuuItem;
      const coordinate = findCoordinateInStore(nameOfTheObject);
      if (coordinate) {
        map.setView([coordinate.lat, coordinate.lng], zoom);
      } else {
        dispatch(getGeocodeCoordinate(nameOfTheObject));
      }
      setInputValue('');
      setLatestAddress(nameOfTheObject);
    };

    useEffect(() => {
      const coordinate = findCoordinateInStore(latestAddress);
      if (!coordinate) return;
      map.setView([coordinate.lat, coordinate.lng], zoom);
    }, [placeGeocodeData]);

    return <C inputValue={inputValue} suggestions={suggestions} setInputValue={setInputValue} 
              onGetCoordinate={onGetCoordinate} />;
  };

  return hoc;
}

export default withMapSearchBlock;