import React, { Component, createRef, Suspense, lazy } from "react";
import { BackArrowIcon, KeyIcon, PlusBtnIcon } from "@this-assets/icons/ui";
import CheckboxBtn from "../../buttons/checkbox";
import UploadImg from "../../buttons/upload-img";
import { BlueCyrclePreloader } from '@this-assets/preloaders';
import styles from './forms.module.scss';

const SelfyWithCardPassportIcon = lazy(() => import('@this-assets/icons/docs/passport/selfy-with-book-passport.svg'));
const SelfyWithBookPassportIcon = lazy(() => import('@this-assets/icons/docs/passport/selfy-with-card-passport.svg'));
const FrontScanCardPassportIcon = lazy(() => import('@this-assets/icons/docs/passport/front-scan-card-passport.svg'));
const BackScanCardPassportIcon = lazy(() => import('@this-assets/icons/docs/passport/back-scan-card-passport.svg'));
const FrontScanBookPassportIcon = lazy(() => import('@this-assets/icons/docs/passport/front-scan-book-passport.svg'));
const BackScanBookPassportIcon = lazy(() => import('@this-assets/icons/docs/passport/back-scan-book-passport.svg'));

class LoadPassportCopyWnd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
      formData: [],
      formDataName: [],
      formValid: false,
      fileState: false,
      fileStateError: "",
      consent: true,
    };

    // this.extractFormData = this.extractFormData.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);

    this.imgPreviewImag = createRef();
  }

  componentDidMount() {
    const getRedirectUri =
      location.protocol + "//" + location.host + location.pathname;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.fileState !== prevState.fileState ||
      this.state.consent !== prevState.consent
    ) {

      if (this.state.fileState === true && this.state.consent) {
        this.setState({
          formValid: true,
        });
      } else {
        this.setState({
          formValid: false,
        });
      }

      if (this.state.fileState === true) {
        this.setState({
          fileStateError: "",
        });
      } else if (this.state.fileState === "error") {
        this.setState({
          fileStateError:
            "Будь ласка, додайте не менше двох фото Ваших документів (як вказано на прикладі)",
        });
      } else {
        this.setState({
          fileStateError:
            "Невірний формат. Будь ласка, завантажте файл у форматі *.jpg, *.png",
        });
      }
    }
  }

  // extractFormData = function (form) {
  //   const formData = new FormData(document.querySelector(form));
  //   let values = {};

  //   for (var pair of formData.entries()) {
  //     if (values[pair[0]]) {
  //       if (!(values[pair[0]] instanceof Array)) {
  //         values[pair[0]] = new Array(values[pair[0]]);
  //       }
  //       values[pair[0]].push(pair[1]);
  //     } else {
  //       values[pair[0]] = pair[1];
  //     }
  //   }
  //   return values;
  // };

  generatePreviewData = (file) => {
    const fr = new FileReader();
    // const games = [];

    return new Promise((resolve, reject) => {
      fr.addEventListener("load", (e) => {
        const div = document.createElement("div");
        const item = document.createElement("div");
        const img = document.createElement("img");
        const del = document.createElement("i");
        img.src = fr.result;
        div.setAttribute("class", "col col_4");
        item.setAttribute("class", "img-upload__item");
        del.setAttribute("class", "img-upload__del");
        // img.setAttribute("class", "border rounded img-preview");
        div.appendChild(item);
        item.appendChild(img);
        item.appendChild(del);
        resolve(div);

        // this.setState({
        //   // formData: fr.map((e) => e.result),
        // });
        // this.props.updateData(this.state.formData);
      });
      fr.addEventListener("error", (e) => {
        reject();
      });
      fr.readAsDataURL(file);
    });
  };

  removeAllChildren = (el) => {
    while (el.childElementCount) {
      el.removeChild(el.children[0]);
    }
  };

  renderCollection = (collection, container) => {
    this.removeAllChildren(container);
    let readerCollection = [];
    let fileNamesCollection = [];

    Promise.all(collection.map(this.generatePreviewData)).then((items) => {
      items.map((item, i) => {
        item.setAttribute("index", i);
        item
          .querySelector(".img-upload__del")
          .addEventListener("click", (e) => {
            collection.splice(i, 1);
            // e.parentElement.removeChild(e);
            this.renderCollection(collection, container);
          });
        container.appendChild(item);

        const reader = new FileReader();
        reader.readAsDataURL(collection[i]);

        reader.addEventListener("load", (e) => {
          readerCollection.push(reader.result.split(",")[1]);
          fileNamesCollection.push(collection[i].name);
        });
      });

      if (items.length > 1) {
        this.setState({
          fileState: true,
        });
      } else if (items.length === 1 && items.length) {
        this.removeAllChildren(container);
        this.setState({
          fileState: "error",
        });
      } else {
        this.setState({
          fileState: false,
        });
      }

      this.setState({
        formData: readerCollection,
        formDataName: fileNamesCollection,
      });
      this.props.updateData("formData", this.state.formData);
      this.props.updateData("formDataName", this.state.formDataName);
    });
  };

  onChangeFile(e) {
    let fileCollection = [];

    while (fileCollection.length) {
      fileCollection.pop();
    }

    Array.from(e.target.files).map((f) => fileCollection.push(f));
    this.renderCollection(fileCollection, this.imgPreviewImag.current);
  }

  cadNumberCheck(cadNumber, nextStep) {
    if (cadNumber && this.state.formValid) {
      return (
        <button type="submit" className="btn btn_dark-blue">
          <span>ПРОДОВЖИТИ</span>
        </button>
      );
    } else if (!cadNumber && this.state.formValid) {
      return (
        <button
          className="btn btn_dark-blue"
          onClick={(e) => {
            e.preventDefault();
            nextStep();
            if (!this.state.fileState) {
              this.setState({
                fileStateError:
                  "Невірний формат. Будь ласка, завантажте файл у форматі *.jpg, *.png",
                selectVisit: true,
              });
            } else if (this.state.fileState === "error") {
              this.setState({
                fileStateError:
                  "Будь ласка, додайте не менше двох фото Ваших документів (як вказано на прикладі)",
                selectVisit: true,
              });
            }
          }}
        >
          <span>ПРОДОВЖИТИ</span>
        </button>
      );
    } else {
      return (
        <button
          className="btn btn_dark-blue btn_dark-blue_disable"
          onClick={(e) => {
            e.preventDefault();
            if (!this.state.fileState) {
              this.setState({
                fileStateError:
                  "Невірний формат. Будь ласка, завантажте файл у форматі *.jpg, *.png",
                selectVisit: true,
              });
            } else if (this.state.fileState === "error") {
              this.setState({
                fileStateError:
                  "Будь ласка, додайте не менше двох фото Ваших документів (як вказано на прикладі)",
                selectVisit: true,
              });
            }
          }}
        >
          <span>ПРОДОВЖИТИ</span>
        </button>
      );
    }
  }

  govHref(mid, totalPrice) {
    localStorage.setItem("mid", mid);
    localStorage.setItem("price", totalPrice);
  }

  render() {
    const { fileStateError } = this.state;
    const { cadNumber, nextStep, mid, totalPrice, prevStep } =
      this.props;

    const govState =
      "state=CfDJ8MxrCNIJ7D1Hm8Q86uOI_kJOqxe-t5CMgDwXzwxBnpZx7QLPpQZFJ94DPaW0PD99PxsKwVrzET4AbL8BvuNdB5F04AXjRTvgtHGNI8bpmKx96lewwXJkYPF39TeFc6j8FpbvJtzjGN8Y7geGpVsnusc8W790ljWvgZkCL6-x8dZ3cUTPc1KoyTlaY1jIVP33je8e6gSXEp5WpaF8kAepZbGottwPEaxpB6wOq9-M5sd3PhF2Fq6YOWIPQqx1Vv9Z6SS-Wv2IXisjPllimvw94v8";

    const getRedirectUri = location.protocol + "//" + location.host;
    const redirect = "redirect_uri=" + getRedirectUri + "/success";
    const type = "response_type=code";

    return (
      <>
        <div className="main-form__stage-4 active">
          <div className="main-form__title-block">
            <div onClick={(e) => {
                e.preventDefault();
                prevStep();
              }}>
              <BackArrowIcon width={20} height={36} />
            </div>
            <div className="title title_md">
              Запит на оцінку земельної ділянки
            </div>
          </div>

          <div className="main-form__content">
            <div className="title title_md-md">
              Будь ласка, підтвердіть свою особу
            </div>
          </div>

          <div className="btn-block btn-block_center">
            <div className="row">
              <div className="col col_6">
                <a
                  href={
                    "https://id.gov.ua/?" +
                    type +
                    `&client_id=${process.env.GOV_UA_CLIENT_ID}&scope=&` +
                    type +
                    "&" +
                    redirect +
                    "&" +
                    govState
                  }
                  onClick={this.govHref(mid, totalPrice)}
                  className="btn btn_dark-blue"
                >
                  <KeyIcon width={32} height={32} />
                  <span className='pl-15'>BankID/Mobile ID/КЕП</span>
                </a>
              </div>
              <div className="col col_6">
                <button
                  className="btn btn_white"
                  onClick={(e) => e.preventDefault()}
                >
                  <span>ВНЕСТИ ДАНІ ВРУЧНУ</span>
                </button>
              </div>
            </div>
          </div>

          <div className="img-upload">
            <div className="row">
              <div className="col col_6">
                <div
                  className={
                    fileStateError
                      ? "img-upload__triger-wrapper require"
                      : "img-upload__triger-wrapper"
                  }
                >
                  <UploadImg onClickHandler={this.onChangeFile} acceptType='image/*' title='Завантажте фото документів, як показано на прикладі' />
                  <span
                    className={
                      fileStateError ? "require__error error" : "require__error"
                    }
                  >
                    {fileStateError}
                  </span>
                </div>
                <div className="img-upload__items">
                  <div ref={this.imgPreviewImag} className="row" />
                </div>
              </div>
              <div className="col col_6">
                <div className="img-upload__info-items">
                  <div className="row">
                    <div className="col col_6">
                      <Suspense fallback={<BlueCyrclePreloader width={160} height={123} />}>
                        <SelfyWithCardPassportIcon width={160} height={123} />
                      </Suspense>
                      <div className='mt-20 d-flex'>
                        <div className='mr-15'>
                          <Suspense fallback={<BlueCyrclePreloader width={75} height={51} />}>
                            <FrontScanCardPassportIcon width={75} height={51} />
                          </Suspense>
                        </div>
                        <Suspense fallback={<BlueCyrclePreloader width={75} height={51} />}>
                          <BackScanCardPassportIcon width={75} height={51} />
                        </Suspense>
                      </div>
                      <div className="img-upload__info-item-content">
                        <p>
                          <strong>ID паспорт:</strong> Селфі з карткою та 2
                          фото з обох сторін картки.
                        </p>
                      </div>
                      <div className={styles.orPosition}>
                        <div className='d-flex justify-content-end'>
                          <p className='fs-18-24-b'>АБО</p>
                        </div>
                      </div>
                    </div>
                    <div className="col col_6">
                      <Suspense fallback={<BlueCyrclePreloader width={160} height={123} />}>
                        <SelfyWithBookPassportIcon width={160} height={123} />
                      </Suspense>
                      <div className='mt-20 d-flex'>
                        <div className='mr-15'>
                          <Suspense fallback={<BlueCyrclePreloader width={93} height={67} />}>
                            <FrontScanBookPassportIcon width={93} height={67} />
                          </Suspense>
                        </div>
                        <Suspense fallback={<BlueCyrclePreloader width={93} height={67} />}>
                          <BackScanBookPassportIcon width={93} height={67} />
                        </Suspense>
                      </div>
                      <div className="img-upload__info-item-content">
                        <p>
                          <strong>Паперовий паспорт:</strong> Селфі з
                          паспортом в розгорнутому вигляді та фото першої та
                          другої сторінки.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn-block">
                  <div className="row">
                    <div className="col">
                      {this.cadNumberCheck(cadNumber, nextStep)}
                      <CheckboxBtn
                        onClickHandler={() => this.setState({consent: !this.state.consent})} 
                        isSelect={this.state.consent}
                        styles='pl-8 fs-14-19 gray'
                        title='Даю згоду на обробку персональних даних'
                        blankLink='./agreement_290621.pdf' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LoadPassportCopyWnd;