import { Component } from "react";
import { ENDPOINTS } from "@this-core/api/endpoints";
import { getRequest, postRequest } from "@this-core/api";
import findErrorContent from "@this-core/helpers/find-error-content";
import {WND} from '@this-core/constants/wnd-const';

function withSuccessPage(C) {

  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        ...this.props,
        popupStage: null,
        submitPreloaderActive: false,
        mid: "",
        errorTitle: "",
        errorTitle2: "",
        formData2: [],
        formDataName2: [],
        docType: "",
      };
    }
  
    componentDidMount() {
      function sendFile(index) {
        let stop = false;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
  
        const getRedirectUri =
          location.protocol + "//" + location.host + location.pathname;
        const getCode = urlParams.get("code");
        const getMid = localStorage.getItem("mid");
        const getPrice = localStorage.getItem("price");
        if (stop) return;
  
        getRequest({restUrl: `/api/landpropget?mid=${getMid}&channel=site`})
          .then((response) => {
            if (response.data.status === "ok") {
              stop = true;
  
              if (response.data.cn === null) {
                this.setState({
                  popupStage: WND.ST_LOAD_LAND_DOCUMENTS,
                });
              } else {
                this.setState({
                  mid: getMid,
                });
  
                var bodyFormData = JSON.stringify({
                  mid: getMid,
                  channel: "site",
                  code: getCode,
                  redirectUri: getRedirectUri,
                });
  
                postRequest({
                  bodyFormData: bodyFormData,
                  restUrl: ENDPOINTS.USER_AUTH,
                })
                  .then((response) => {
  
                    localStorage.removeItem("mid");
                    localStorage.removeItem("price");
                  })
                  .catch((error) => {
                    console.log(error);
                  });
  
                var bodyFormData2 = JSON.stringify({
                  mid: getMid,
                  channel: "site",
                  data: {
                    result: "yes",
                    price: getPrice,
                    reason: "",
                  },
                });
  
                postRequest({
                  bodyFormData: bodyFormData2,
                  restUrl: ENDPOINTS.LAND_PROP_COMPLETE,
                })
                  .then(() => {
                    this.setState({
                      popupStage: WND.ST_REQUEST_ADD_SUCCESS,
                    });
                    localStorage.removeItem("mid");
                    localStorage.removeItem("price");
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            } else if (response.data.status === "error") {
              stop = true;
              this.setState({
                popupStage: WND.ST_ERROR,
                ...findErrorContent(response.data)
              });
            } else {
              sendFile.call(this, index + 1);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      sendFile.call(this, 0);
    }
  
    updateData = (stateName, value) => {
      this.setState({ [stateName]: value });
    };
  
    submitForm = (formData2, formDataName2, docType) => (e) => {
      e.preventDefault();
      this.setState({
        submitPreloaderActive: true,
      });
  
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const getRedirectUri =
        location.protocol + "//" + location.host + location.pathname;
      const getCode = urlParams.get("code");
      const getMid = localStorage.getItem("mid");
      const getPrice = localStorage.getItem("price");
  
      this.setState({
        mid: getMid,
      });
  
      var bodyFormData = JSON.stringify({
        mid: getMid,
        channel: "site",
        code: getCode,
        redirectUri: getRedirectUri,
      });
  
      postRequest({
        bodyFormData: bodyFormData,
        restUrl: ENDPOINTS.USER_AUTH,
      })
        .then((response) => {
          localStorage.removeItem("mid");
          localStorage.removeItem("price");
        })
        .catch((error) => {
          console.log(error);
        });
  
      let data2 = formData2.map((el, i) => {
        return {
          ftype: "Інший",
          fdescription: docType,
          fname: formDataName2[i],
          fbase64: formData2[i],
        };
      });
  
      var bodyFormData2 = JSON.stringify({
        mid: getMid,
        channel: "site",
        data: {
          result: "yes",
          price: getPrice,
          reason: "",
          documents: data2,
        },
      });
  
      postRequest({
        bodyFormData: bodyFormData2,
        restUrl: ENDPOINTS.LAND_PROP_COMPLETE,
      })
        .then(() => {
          this.setState({
            popupStage: WND.ST_REQUEST_ADD_SUCCESS,
          });
          localStorage.removeItem("mid");
          localStorage.removeItem("price");
        })
        .catch((error) => {
          console.log(error);
        });
    };
    
    render() {
      return <C {...this.state}
          onSubmitForm={this.submitForm}
          onUpdateData={this.updateData}
        />;
    }
  };
}

export default withSuccessPage;