export const COLOR_WHITE = 'white';
export const COLOR_BLACK = 'black';
export const COLOR_CLEAR = '#ffffff00';

export const COLOR_PURPLE = '#665CAC';

export const COLOR_ORANGE = '#ff7c1d';
export const COLOR_GRAY = '#7E96AE';
export const COLOR_DARK_GRAY = '#3F4E5D';
export const COLOR_LIGHT_GRAY = '#CEDDE6';
export const COLOR_BLUE = '#529ED0';
export const COLOR_LIGHT_BLUE = '#ECF4F8';
export const COLOR_DARK_BLUE = '#3867B1';

export const COLOR_SKELETON_LIGHT = '#e3e3e3';
export const COLOR_SKELETON_COMMON = '#d9d9d9';