import { useHistory } from "react-router-dom";

const FormSubmitBtnSell = ({title, styles, onClickHandler}) => {
  const history = useHistory();
  return (
    <button type="submit" className={styles} onClick={() => {onClickHandler; /*history.push('/success')*/}}>
      <span className='fs-18-24 fw-600'>{title}</span>
    </button>
  );
};

export default FormSubmitBtnSell;