import { Component } from "react";

function withRejectionReasonWnd(C) {

  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        tooltipsActive: [false, false, false],
        InsufficientAmount: "",
        remind: "",
        date: "",
        consent: true
      };
    }
  
    componentDidMount() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        ("0" + (today.getMonth() + 2)).slice(-2) +
        "-" +
        ("0" + today.getDate()).slice(-2);
      this.setState({
        date: date,
      });
    }
  
    tooltipChecked = (index) => (e) => {
      let tooltipItems = this.state.tooltipsActive.map(() => {
        return false;
      }); //клон mass array
      let item = tooltipItems[index]; // mass[item] кликнутый итем
      item = true; //меняем текушее значение на 1
      tooltipItems[index] = item; //обновляем клон
  
      this.setState({
        tooltipsActive: this.state.tooltipsActive.map((c, i) => {
          return tooltipItems[i];
        }),
        reason: e.target.value,
        reason2: "",
      });
      this.props.updateData("reason", e.target.value);
  
      if (index === 1) {
        this.setState({
          reason2: this.state.date,
        });
        this.props.updateData("reason2", this.state.date);
      } else {
        this.setState({
          reason2: "",
        });
      }
    };
  
    handleChange = (e) => {
      this.setState({
        reason2: e.target.value,
      });
      this.props.updateData("reason2", e.target.value);
    };
  
    tooltipItem = (index) => {
      return this.state.tooltipsActive[index];
    };
    
    render() {
      return <C {...this.state} {...this.props}
          onSetState={(arg) => this.setState(arg)}
          onTooltipChecked={this.tooltipChecked}
          onTooltipItem={this.tooltipItem}
          handleChange={this.handleChange}
        />;
    }
  };
}

export default withRejectionReasonWnd;