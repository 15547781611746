import { COLOR_DARK_BLUE } from '@this-core/basic-styles/colors-const';
import styles from './btn-style.module.scss';


const SimpleLargeBtn = ({title, width, backgroundColor = COLOR_DARK_BLUE, onClickHandler, isDisable}) => {

  const buttonWidth = {
    width: `${width}px`,
    background: backgroundColor,
  };

  return (
    <button className={`${styles.simpleLargeBtn}`} style={buttonWidth} onClick={onClickHandler} disabled={isDisable}>
      <span className='fs-24-24 fw-600 white'>{title}</span>
    </button>
  );
};

export default SimpleLargeBtn;