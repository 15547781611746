import ImageLazy from "@this-core/components/image/image-lazy";
import { NavLink } from "react-router-dom";
import styles from './news-item-mini.module.scss';


const NewsItem = ({url, imgUrl, title}) => {
  return (
    <NavLink to={url} className="news-slider__item">
      <div>
        <ImageLazy url={imgUrl} width={308} height={264} className={`news-slider__img`} />
      </div>
      <div className={`mt-5 news-slider__title text-overflow-hide-3 ${styles.overflowHide}`}>
        {title}
      </div>
    </NavLink>
  );
};

export default NewsItem;