import { LAND_IN_SALE_ERROR, LAND_IN_SALE_REQUEST, LAND_IN_SALE_SUCCESS } from "../types";

export const getLandInSale = (saleLandInStore) => async (dispatch) => {
  if (saleLandInStore?.length > 0) return;
  dispatch({ type: LAND_IN_SALE_REQUEST});

  fetch(`${process.env.K2_API_URL}/api/getapplicationsonsale`)
    .then(res => res.json())
    .then(({data}) => {
      // Изменяем порядок координат в масиве
      let reverseCoordinate = data.map((poligon) => {
        const poligonCoordinate = poligon.GeoJson.geometry.coordinates;
        if (poligonCoordinate.length === 0) return poligon;
        const poligonCoordinateMod = poligonCoordinate[0].map(arr => {
          // delete unused parameter
          if (arr.length === 3) arr.pop();
          return arr.reverse();
        });
        const poligonMod = {
          ...poligon, 
          GeoJson: {
            geometry: {
              coordinates: [ poligonCoordinateMod ],
            }
          }
        };
        return poligonMod;
      });
      return reverseCoordinate; 
    })
    .then(data => {
      // Добавляем область для фильтра
      const poligonsAddOblast = data.map(item => {
        return { ...item, Oblast: item.Address.split(',', 1)[0]};
      });
      dispatch({
        type: LAND_IN_SALE_SUCCESS,
        payload: poligonsAddOblast,
      });
    })
    .catch(err => {
      console.log(err);
      dispatch({
        type: LAND_IN_SALE_ERROR,
        payload: err,
      });
    });
};