import { MapLocationIcon } from '@this-core/public/assets/icons/ui';
import { StarIcon } from '@this-core/public/assets/icons/ui';
import { areaFormater, dateFormater, priceFormater } from '@this-core/helpers/formaters';
import styles from './in-sale-item.module.scss';


const InSaleItem = ({item}) => {
  const {CadastralNumber, Address, Purpose, AreaGA, ApplicationDate, AgreementCostEval, OfferedCostEval} = item;
  const pricePerGA = AreaGA ? OfferedCostEval / AreaGA : 0;
  
  return (
    <div className={styles.container}>
      <div className='d-flex'>
        <div className={styles.img}>
          <MapLocationIcon />
        </div>
        <div className={`px-8 d-flex flex-column justify-content-between ${styles.rightBlock}`}>
          <div>
            <a href={process.env.PERSONAL_CABINET} rel="noreferrer" target="_blank">
              <span className='mt-15 fs-14-18 fw-600 bottom-dot-bold-pro-black'>{Address}</span>
            </a>
            {Purpose && <p className='mt-5 fs-14-18 fw-600 gray'>{`Призначення: ${Purpose}`}</p>}
            {AreaGA && <p className='mt-5 fs-14-18 fw-600 gray'>{`Площа: ${areaFormater(AreaGA)}`}</p>}
          </div>
          <div className={`d-flex justify-content-between`}>
            <div>
              <p className='mt-15 mb-8 fs-21-24-b dark-blue'>{`${priceFormater({price: (AgreementCostEval), symbol: 'грн'})}`}</p>
              <p className='mb-10 fs-14-18 fw-600 gray'>{`${priceFormater({price: pricePerGA, symbol: 'грн/га'})}`}</p>
            </div>
            <div className='pb-10 pr-8 d-flex align-items-end'>
              {/* Пока нет личного кабинета не нужно */}
              {/* <StarIcon width={24} height={24} /> */}
            </div>
          </div>
        </div>
      </div>
      <div className={`px-15 d-flex justify-content-between align-items-center ${styles.buttomBlock}`}>
        <p className={`fs-14-18 ${styles.buttomText}`}>{`# ${CadastralNumber}`}</p>
        <p className={`fs-14-18 ${styles.buttomText}`}>{dateFormater(ApplicationDate)}</p>
      </div>
    </div>
  );
};

export default InSaleItem;